import React, { useState } from "react";
import styles from "../CreateTejPoints/createTejPoints.module.css";
import { useLocation, useNavigate } from "react-router-dom";

function ViewTejPoints() {
  const navigate = useNavigate();
  const location = useLocation();
  const tejPoint = location.state && location.state.tejPoint;
  const initialFormData = {
    country: tejPoint?.country,
    state: tejPoint?.state,
    loginPoint: tejPoint?.loginPoint,
    templePoint: tejPoint?.templePoint,
    referPoint: tejPoint?.referPoint,
    utsavPoint: tejPoint?.utsavPoint,
    bookingPoint: tejPoint?.bookingPoint,
    completeKycPoint: tejPoint?.completeKycPoint,
  };

  const [formData, setFormData] = useState(initialFormData);

 

  return (
    <div className={styles.main}>
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderLeft}>
          <p>
            <span onClick={() => navigate("/svarnamanagement")}>
              Svarna Management
            </span>{" "}
            <span className={styles.activePageStyle}>
              &gt; View Tej Points
            </span>
          </p>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Country</p>
            </div>
            <div className={styles.fieldInput}>
              <input type="text" value={formData.country} disabled />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>State</p>
            </div>
            <div className={styles.fieldInput}>
              <input type="text" value={formData.state} disabled />
            </div>
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Login Point</p>
            </div>
            <div className={styles.fieldInput}>
              <input type="text" value={formData.loginPoint} disabled />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Add Temple Point</p>
            </div>
            <div className={styles.fieldInput}>
              <input type="text" value={formData.templePoint} disabled />
            </div>
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Refer Point</p>
            </div>
            <div className={styles.fieldInput}>
              <input type="text" value={formData.referPoint} disabled />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Create Utsav Point</p>
            </div>
            <div className={styles.fieldInput}>
              <input type="text" value={formData.utsavPoint} disabled />
            </div>
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Booking Point</p>
            </div>
            <div className={styles.fieldInput}>
              <input type="text" value={formData.bookingPoint} disabled />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Complete KYC Point</p>
            </div>
            <div className={styles.fieldInput}>
              <input type="text" value={formData.completeKycPoint} disabled />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewTejPoints;
