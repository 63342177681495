import React, { useEffect, useState } from "react";
import styles from "./ViewKYCDetails.module.css";
import { useNavigate, useParams } from "react-router-dom";
import PanditPhoto from "../PanditPhoto/PanditPhoto";
import SchoolingDetails from "../SchoolingDetails/SchoolingDetails";
import Address from "../Address/Address";
import ReferredPandit from "../ReferredPandit/ReferredPandit";
import LiveImageVideo from "../PanditPhotoVideo/LiveImageVideo";
import { userRequest } from "../../../../requestMethod";
import { toast } from "sonner";
import { Spin } from "antd";

function ViewKYCDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [panditsData, setPanditsData] = useState(null);
  const [panditDetailsStatus, setPanditDetailsStatus] = useState("");
  const [panditLivePhotoStatus, setPanditLivePhotoStatus] = useState("");
  const [basicVideoStatus, setBasicVideoStatus] = useState("");
  const [professionalVideoStatus, setProfessionalVideoStatus] = useState("");
  const [selectedPhotoReason, setSelectedPhotoReason] = useState("");
  const [selectedLivePhotoReason, setSelectedLivePhotoReason] = useState("");
  const [selectedProfessionalReason, setSelectedProfessionalReason] = useState("");
  const [selectedBasicVideoReason, setSelectedBasicVideoReason] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  const handleSave = async () => {
    const data = {
      id: id,
      panditDetailsStatus: {
        panditStatus: panditDetailsStatus,
        reason: selectedPhotoReason,
      },
      panditLivePhotoStatus: {
        panditPhotoStatus: panditLivePhotoStatus,
        reason: selectedLivePhotoReason,
      },
      basicVideoStatus: {
        basicStatus: basicVideoStatus,
        reason: selectedBasicVideoReason,
      },
      professionalVideoStatus: {
        professionalStatus: professionalVideoStatus,
        reason: selectedProfessionalReason,
      },
    };

    try {
      // Send a request to update the status
      setIsLoading(true);
      await userRequest.patch(
        "/admin/adminPanditKycRoutes/updateStatusInKyc",
        data
      );
      // Handle success or navigate to a different page
      navigate("/panditkycmanagement"); // Redirect to the management page after saving
      toast.success("Updated Successfully");
    } catch (error) {
      console.error("Error updating status:", error);
      navigate("/panditkycmanagement"); 
      toast.error("Error updating status/Sending Notification");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = async () => {
    try {
      const response = await userRequest.get(
        `/admin/adminPanditKycRoutes/viewDetailsInKyc?id=${id}`
      );
      setPanditsData(response.data.data);
      setPanditDetailsStatus(
        response.data.data[0].panditDetailsStatus?.panditStatus
      );
      setSelectedPhotoReason(
        response.data.data[0].panditDetailsStatus?.reason
      )
      setPanditLivePhotoStatus(
        response.data.data[0].panditLivePhotoStatus?.panditPhotoStatus
      );
      setSelectedLivePhotoReason(response.data.data[0].panditLivePhotoStatus?.reason)
      setBasicVideoStatus(response.data.data[0].basicVideoStatus?.basicStatus);
      setSelectedBasicVideoReason(response.data.data[0].basicVideoStatus?.reason)
      setProfessionalVideoStatus(
        response.data.data[0].professionalVideoStatus?.professionalStatus
      );
      setSelectedProfessionalReason( response.data.data[0].professionalVideoStatus?.reason)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderLeft}>
          <p>
            <span onClick={() => navigate("/panditkycmanagement")}>
              Pandit KYC Management
            </span>{" "}
            <span className={styles.activePageStyle}>
              &gt; Profile's KYC Details
            </span>
          </p>
        </div>
        <div className={styles.pageHeaderRight}>
          <button 
          onClick={handleSave}
          // disabled={(panditDetailsStatus && panditLivePhotoStatus && basicVideoStatus && professionalVideoStatus ) ? true :false }
          >Save</button>
        </div>
      </div>
    <Spin spinning={isLoading} size="small">
      <div className={styles.detailMainContainer}>
        {panditsData && (
          <>
            {/* <PanditPhoto data={panditsData} /> */}
            <PanditPhoto
              data={panditsData}
              panditDetailsStatus={panditDetailsStatus}
              setPanditDetailsStatus={setPanditDetailsStatus}
              selectedPhotoReason={selectedPhotoReason}
              setSelectedPhotoReason={setSelectedPhotoReason}
            />
            <SchoolingDetails data={panditsData} />
            <Address data={panditsData} />
            {/* <LiveImageVideo data={panditsData} /> */}
            <LiveImageVideo
              data={panditsData}
              panditLivePhotoStatus={panditLivePhotoStatus}
              setPanditLivePhotoStatus={setPanditLivePhotoStatus}
              basicVideoStatus={basicVideoStatus}
              setBasicVideoStatus={setBasicVideoStatus}
              professionalVideoStatus={professionalVideoStatus}
              setProfessionalVideoStatus={setProfessionalVideoStatus}
              selectedLivePhotoReason={selectedLivePhotoReason}
              setSelectedLivePhotoReason={setSelectedLivePhotoReason}
              selectedProfessionalReason={selectedProfessionalReason}
              setSelectedProfessionalReason={setSelectedProfessionalReason}
              selectedBasicVideoReason={selectedBasicVideoReason}
              setSelectedBasicVideoReason={setSelectedBasicVideoReason}
            />
            <ReferredPandit data={panditsData} />
          </>
        )}
      </div>
      </Spin>
    </div>
  );
}

export default ViewKYCDetails;
