import React, { useState } from 'react'
import styles from './devalayaManagement.module.css'
import { useNavigate } from 'react-router-dom';
import DosDont from './DosDonts/DosDonts';
import LocalGuide from './LocalGuide/LocalGuide';
import Facilities from './Facilities/Facilities';
import KainKaryam from './KainKaryam/KainKaryam';
import Rules from './Rules/Rules';
import IshtDhaiva from './IshtDhaiva/IshtDhaiva';
import AddDevalaya from './AddDevalaya/AddDevalaya';
// import User from './User/User';



function UserManagement() {

    const menuItems = [
        // { label: "Devalaya Type", state: "Devalaya Type" },
        // { label: "Schedule Time", state: "Schedule Time" },
        { label: "Istha Dhaiva", state: "IsthaDhaiva" },
        { label: "Do`s Don`ts", state: "Dos Dont" },
        { label: "KainKaryam", state: "KainKaryam" },
        { label: "Facilities", state: "Facilities" },
        { label: "Rules", state: "Rules" },
        { label: "Local Guide", state: "Local Guide" },
      ];


    const navigate = useNavigate()
    const [activeMenuItem, setActiveMenuItem] = useState("IsthaDhaiva");

    const handlePoliciesMenu = (menu) => {
      setActiveMenuItem(menu);
    };
    return (
        <div className={styles.main}>
          <div className={styles.pageHeader}>
            <div className={styles.pageHeaderLeft}>
              <p>
                <span onClick={() => navigate("/devalayamanagement")}>Master  Data <span className={styles.devalayaHeadingColor}>{">"} Devalaya</span></span>
              </p>
            </div>
         
            <div className={styles.pageHeaderRight}>
          
            {/* <button className={styles.addNewBtn}
              onClick={() => navigate("/usermanagement/createroles")}
            >
              Add New
            </button> */}

            <AddDevalaya/>
        </div>
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.menuContainer}>
              {menuItems.map((item) => (
                <div
                  key={item.state}
                  className={
                    activeMenuItem === item.state
                      ? `${styles.singleMenu} ${styles.activeMenu}`
                      : styles.singleMenu
                  }
                  onClick={() => handlePoliciesMenu(item.state)}
                >
                  <p>{item.label}</p>
                </div>
              ))}
            </div>
            <div className={styles.activePage}>
              {/* {activeMenuItem === "Devalaya Type" }
              {activeMenuItem === "Schedule Time" } */}
              {activeMenuItem === "IsthaDhaiva"  &&  <IshtDhaiva/> }
              {activeMenuItem === "Dos Dont"  &&  <DosDont/> }
              {activeMenuItem === "Local Guide"  &&  <LocalGuide/> }
              {activeMenuItem === "Facilities"  &&  <Facilities/> }
              {activeMenuItem === "KainKaryam"  &&  <KainKaryam/> }
              {activeMenuItem === "Rules" && <Rules/> }

            </div>
          </div>
        </div>
      );
}

export default UserManagement
