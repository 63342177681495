import React, { useEffect, useState } from "react";
import styles from "./roles.module.css";
import { useNavigate } from "react-router-dom";
import { BASE_URL, userRequest } from "../../../requestMethod";
import editIcon from "../../../assets/images/edit.png";
import deleteIcon from "../../../assets/images/delete.png";

import { Spin, Switch } from "antd";
import axios from "axios";
import useDebounce from "../../../components/UseDebounce";
function Roles() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true)

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    fetchData();
  }, [debouncedSearchQuery,page]);

  const fetchData = async () => {
    try {
    
        const response = await userRequest.get(`/roles/getAllRoles?search=${debouncedSearchQuery}&page=${page}`);
        // const response = await axios.get(`http://localhost:8080/api/roles/getAllRoles?search=${searchQuery}&page=${page}`);

      setData(response?.data?.data);
      setTotalPages(response?.data?.pagination?.totalPages);
      setCurrentPage(response?.data?.pagination?.page);
    } catch (error) {
      console.log("Error fetching data:", error);
    }finally{
      setLoading(false)
    }
  };

  const handleDelete = async (id) => {
    try {
      console.log("id",id)
    
      await userRequest.delete(
        `/roles/deleteRoles/${id}`,
      )
      fetchData()
    } catch (error) {
      console.error("Error updating user status:", error);
    }
  };

  const handleChange = async (email, activeStatus) => {
    try {
      console.log("email",email)
      const newActiveStatus = !activeStatus;
    
      await userRequest.patch(
        `/roles/updateRolesAndPermissionsStatus`,
        {
          email:email,
          activeStatus: newActiveStatus
        }
      )
      
      // await axios.patch(
      //   `http://localhost:8080/api/roles/updateRolesAndPermissionsStatus`,
      //   {
      //    email:email,
      //     activeStatus: newActiveStatus
      //   }
      // )
      fetchData()
    } catch (error) {
      console.error("Error updating user status:", error);
    }
  };
  return (
    <Spin spinning={loading} size="small">
    <div className={styles.tableMain}>
      <div className={styles.filterExport}>
        {/* <p>All</p> */}
        <div className={styles.search}>
          <input type="text" onChange={(e) => setSearchQuery(e.target.value)} />
        </div>
        <div className={styles.export}>
          <p><a href={`${BASE_URL}/roles/exportRolesAndPermissionsToExcel?search=${searchQuery}&page=${page}`} target="_parent">Export as CSV, PDF, Excel doc</a></p>
       
        </div>
        {/* <div className={styles.filterDiv}>
          <p>Filter</p>
        </div> */}
      </div>
      <div className={styles.rolesTableMain}>
      <table>
        <thead>
          <tr>
          <th>
          S.No.
          {/* <div className={styles.SNoCheckBox}>
            <input type="checkbox" />
            

          </div> */}
          </th>

            <th>ID.</th>
            <th>User Name</th>
            <th>User Type</th>
            <th>Email</th>
            <th>Permission</th>
            
            <th>Details</th>
            <th>Action & Status</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((item,index) => (
                
              <tr key={item._id}>
                <td>    
              {index+1}
                    {/* <div className={styles.checkbox}>
            
              <input type="checkbox" />
              

            </div> */}
            </td>

                <td>{item?._id}</td>
                <td>{item?.name}</td>
                <td>{item?.userType}</td>
                <td>{item?.email}</td>
                <td>
                   {item?.permissions?.map((per)=>(
                    <>
                    {per?.permissionsId?.permissionName}...
                     </>
                   ))}
                </td>
           
                <td
                  className={styles.viewDetails}
                  onClick={() =>
                    navigate(`/rolesandpermissions/viewroles/${item._id}`, { state: { item } })
                  }
                >
                  View
                </td>

                <td className={styles.actionTd}>
              
                  <img
                    src={editIcon}
                    alt=""
                    onClick={() =>
                      navigate(`/rolesandpermissions/editroles/${item._id}`, { state: { item }  })
                    }
                  />
                    <img
                    src={deleteIcon}
                    alt=""
                    onClick={() =>
                     handleDelete(item._id)
                    }
                  />
               {/* <Switch size="small" checked={item.activeStatus} onChange={() => handleChange(item?.mobile, item.activeStatus)}/> */}
               {/* <Switch size="small" /> */}
               <Switch size="small" checked={item?.activeStatus} onChange={() => handleChange(item?.email, item.activeStatus)}/>

                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="11">No data found</td>
            </tr>
          )}
        </tbody>
      </table>
      </div>
      {data.length > 0 ? (
          <div className={styles.footer}>
          <div
            className={styles.action}
            style={{ visibility: totalPages === 1 ? "hidden" : "visible" }}
          >
            <button
              className={`${styles.actionButton} ${
                page === 1 ? styles.disabled : styles.activeButton
              }`}
              onClick={handlePrevious}
              disabled={page === 1}
            >
              Previous
            </button>
            <button
              className={`${styles.actionButton} ${
                page === totalPages ? styles.disabled : styles.activeButton
              }`}
              onClick={handleNext}
              disabled={page === totalPages}
            >
              Next
            </button>
          </div>
          <div className={styles.page}>
            <span>{currentPage}</span> <span>of</span>{" "}
            <span>{totalPages}</span>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
    </Spin>
  );
}

export default Roles;
