// import React, { useState } from "react";
// import { Modal } from "antd";
// import styles from "./EditIsthaDhaiva.module.css";
// import { toast } from "sonner";
// import FileUpload from "../../../../../components/FileUpload/FileUpload";

// const EditUtsavFacilities = ({ isModalOpenForEdit, handleOkForEdit, handleCancelForEdit }) => {
//   const [formData, setFormData] = useState({
//     name: "",
//     uploadImage: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleFilter = () => {
//     handleOkForEdit(formData); // Pass formData back to parent component
//   };

//   return (
//     <Modal
//       visible={isModalOpenForEdit}
//       onCancel={handleCancelForEdit}
//       onOk={handleFilter}
//       width={"29vw"}
//       height={"100dvh"}
//       className={styles.modal}
//     >
//       <div className={styles.modalTitle}>
//         <p>Edit Utsav Facility</p>
//       </div>
//       <div className={styles.contentMain}>
//         <div className={styles.fieldContainer}>
//           <div className={styles.fieldLabel}>
//             <p>Name</p>
//           </div>
//           <div className={styles.fieldInput}>
//             <input
//               type="text"
//               name="name"
//               value={formData.startDate}
//               onChange={handleChange}
//             />
//           </div>
//         </div>
//         <div className={styles.fieldContainer}>
//             <div className={styles.fieldLabel}>
//               <p>Upload Media</p>
//             </div>
//             <div className={styles.fieldInput}>
//             <FileUpload
//             label="Upload Media"
//             // onChange={(fieldName, url) => setUploadMedia(url)}
//             fieldName="uploadMedia"
//           />
//             </div>
//           </div>
//         <div className={styles.modalButton}>
//           <button onClick={handleCancelForEdit}>Cancel</button>
//           <button onClick={handleFilter}>Filter</button>
//         </div>
//       </div>
//     </Modal>
//   );
// };

// export default EditUtsavFacilities;


import React, { useState } from "react";
import { Modal } from "antd";
import styles from "./EditIsthaDhaiva.module.css";
import { toast } from "sonner";
import FileUpload from "../../../../../components/FileUpload/FileUpload";
import editIcon from "../../../../../assets/images/edit.png";
import { userRequest } from "../../../../../requestMethod";

const EditUtsavFacilities = ({fetchData,task}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: task?.name,
    icon: task?.icon,
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleOk = async () => {
    setIsModalOpen(false);

    try {
      await userRequest.patch(`/master/editAdminMasterUtsavFacilities/${task?._id}`, formData);
      
      toast.success("Utsav updated successfully");
      await fetchData()
    } catch (error) {
      console.log("Error updating Utsav:", error);
      // Show error message
      toast.error("Failed to update Utsav");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <img src={editIcon} alt="" onClick={showModal} />

      <Modal
        visible={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
       
        className={styles.modal}
      >
        <div className={styles.modalTitle}>
          <p>Edit Karyakramam</p>
        </div>
        <div className={styles.contentMain}>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Name</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Upload Media</p>
            </div>
            <div className={styles.fieldInput}>
              <FileUpload
                label="Upload Media"
                onChange={(fieldName, file) =>
                  setFormData({ ...formData, icon: file })
                }
                fieldName="uploadMedia"
                name={formData.icon}
              />
            </div>
          </div>
          <div className={styles.modalButton}>
            <button onClick={handleCancel}>Cancel</button>
            <button onClick={handleOk}>Update</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditUtsavFacilities;

