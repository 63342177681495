import React, { useEffect, useState } from "react";
import styles from "./createnotification.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import SelectComponent from "../../../components/SelectComponent";
import { userRequest } from "../../../requestMethod";
import { UploadOutlined } from "@ant-design/icons";
import FileUpload from "../../../components/FileUpload/FileUpload";
import { Select } from "antd";
import { Option } from "antd/es/mentions";
import axios from "axios";

export const userOptions = [
  { value: "Devotee", label: "Devotee" },
  { value: "Pandit", label: "Pandit" },
];

function CreateNotification() {
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState("Devotee");
  const [userData, setUserData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sendTo, setSendTo] = useState("AllUser");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [uploadMedia, setUploadMedia] = useState("");
  const [selectedUsersIds, setSelectedUsersIds] = useState([]);

  const handleUserSelectChange = (value) => {
    setSelectedUser(value.value);
  };

  const handleSendToChange = (value) => {
    setSendTo(value);
  };

  useEffect(() => {
    fetchData();
  }, [selectedUser,searchQuery]);

  const fetchData = async () => {
    try {
      const response = await userRequest.get(
        `/userManagement/getUserManagementList?userType=${selectedUser}&search=${searchQuery}`
      );
      setUserData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCheckboxChange = (userId) => {
    const index = selectedUsersIds.indexOf(userId);
    if (index === -1) {
      setSelectedUsersIds([...selectedUsersIds, userId]);
    } else {
      const updatedSelectedUsersIds = [...selectedUsersIds];
      updatedSelectedUsersIds.splice(index, 1);
      setSelectedUsersIds(updatedSelectedUsersIds);
    }
  };

  const handleSave = async () => {
    try {
      const response = await userRequest.post("/pushNotification/createNotification", {
        sendTo: sendTo,
        title: title,
        message: message,
        uploadMedia: uploadMedia,
        userId:selectedUsersIds
      });
      // const response = await axios.post("http://localhost:8080/api/pushNotification/createNotification", {
      //   sendTo: sendTo,
      //   title: title,
      //   message: message,
      //   uploadMedia: uploadMedia,
      //   userId:selectedUsersIds
      // });
      console.log("Notification created:", response.data);
      // Show success message or navigate to another page upon successful creation
      toast.success("Notification created successfully");
      navigate("/notificationmanagement");
    } catch (error) {
      console.error("Error creating notification:", error);
      // Show error message
      toast.error("Failed to create notification");
    }
  };


  return (
    <div className={styles.main}>
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderLeft}>
          <p className={styles.createNotiPara}>
            <span onClick={() => navigate("/notificationmanagement")}>
              Notification Management
            </span>
            <span className={styles.activePageStyle}>
              &gt; Create Notification
            </span>
          </p>
        </div>
        <div className={styles.pageHeaderRight}>
          <button className={styles.btnSaveDiscard} onClick={() => navigate("/notificationmanagement")}>
            Discard
          </button>
          <button className={styles.btnSaveDiscard}  onClick={handleSave}>Save</button>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.leftContainer}>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Add Title</p>
            </div>
            <div className={styles.fieldInput}>
              <input type="text" onChange={(e)=>setTitle(e.target.value)}/>
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Write Message</p>
            </div>
            <div className={styles.fieldInput}>
              <textarea onChange={(e)=>setMessage(e.target.value)}/>
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Upload Media</p>
            </div>
            <div className={styles.fieldInput}>
            <FileUpload
            label="Upload Media"
            onChange={(fieldName, url) => setUploadMedia(url)}
            fieldName="uploadMedia"
          />
            </div>
          </div>
          
       
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Send To</p>
            </div>
            <div className={styles.fieldRadioInput}>
              <input
                type="radio"
                id="AllUser"
                checked={sendTo === "AllUser"}
                onChange={() => handleSendToChange("AllUser")}
              />
              <label htmlFor="AllUser">All User</label>
            </div>
            <div className={styles.fieldRadioInput}>
              <input
                type="radio"
                id="AllPandit"
                checked={sendTo === "AllPandit"}
                onChange={() => handleSendToChange("AllPandit")}
              />
              <label htmlFor="AllPandit">All Pandit</label>
            </div>
            <div className={styles.fieldRadioInput}>
              <input
                type="radio"
                id="AllDevotee"
                checked={sendTo === "AllDevotee"}
                onChange={() => handleSendToChange("AllDevotee")}
              />
              <label htmlFor="AllDevotee">All Devotee</label>
            </div>
            <div className={styles.fieldRadioInput}>
              <input
                type="radio"
                id="Individual"
                checked={sendTo === "Individual"}
                onChange={() => handleSendToChange("Individual")}
              />
              <label htmlFor="Individual">Individual</label>
            </div>
          </div>
          {sendTo === "Individual" && (
            <div className={styles.fieldUserContainer}>
              <SelectComponent
                options={userOptions}
                onSelect={handleUserSelectChange}
                //  initialSelectedOption={formData?.taxType}
              />
              <div className={styles.userList}>
                <input type="text"  onChange={(e)=>setSearchQuery(e.target.value)}/>
                <div className={styles.notificationTable}>
                  <table>
                    <tbody>
                      {userData && userData.length > 0  ? (
                        userData.map((user, index) => (
                          <tr key={user._id}>
                            <td><input type="checkbox" onChange={() => handleCheckboxChange(user._id)}/></td>
                            <td>{user.role}</td>
                            <td>{user.gender}</td>
                            <td>{user.city}</td>
                            <td>
                              {user.firstName} {user.lastName}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="8">No data found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>

      </div>
    </div>
  );
}

export default CreateNotification;
