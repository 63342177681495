import React, { useState, useEffect } from "react";
import Select from "react-select";
import dropdown from "../assets/images/dropdown.png";

function MultiSelectComponent({ options, white, onSelect, initialSelectedOptions }) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (initialSelectedOptions && initialSelectedOptions.length > 0) {
      setSelectedOptions(
        initialSelectedOptions.map(option => ({ value: option, label: option }))
      );
    }
  }, [initialSelectedOptions]);

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    onSelect(selectedOptions);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      minHeight: "3rem",
      maxHeight:"6rem",
      borderRadius: "8px",
      border: "none",
      boxShadow: "none",
      fontSize: "0.8rem",
      background: white ? "white" : "#FFF9F1",
      fontWeight: "500",
      paddingLeft: "14px",
    }),
    menu: (provided) => ({
      ...provided,
      width: "100%",
      margin: "0",
      backgroundColor: "white",
      color: "black",
    }),
    option: (provided, state) => ({
      ...provided,
      height: "2rem",
      minHeight: "2rem",
      maxHeight:"6rem",
      backgroundColor: "white",
      color: "black",
      cursor: "pointer",
      borderBottom: "1px solid white",
      textAlign: "left",
      fontSize: "1rem",
      fontWeight: "500",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
  };

  return (
    <Select
      value={selectedOptions}
      onChange={handleSelectChange}
      options={options}
      styles={customStyles}
      isMulti
      isSearchable={false}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () => (
          <img
            src={dropdown}
            alt="Custom Dropdown Indicator"
            style={{ width: "10px", height: "8px", marginRight: "1.5vw" }}
          />
        ),
      }}
    />
  );
}

export default MultiSelectComponent;
