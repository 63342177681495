import React, { useEffect, useState } from "react";
import styles from "./EditRoles.module.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { userRequest } from "../../../../requestMethod";
import { toast } from "sonner";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";


function EditRoles() {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [permissions, setPermissions] = useState([]);

 
  useEffect(() => {
    async function fetchPermissions() {
      try {
        const response = await userRequest.get(
          "/permissions/getAllPermissions"
        );
        // const response = await axios.get(
        //   "http://localhost:8080/api/permissions/getAllPermissions"
        // );
        setPermissions(response?.data?.data);
      } catch (error) {
        console.error("Error fetching permissions:", error.message);
        alert("Failed to fetch permissions. Please try again later.");
      }
    }
    fetchPermissions();
  }, []);

  console.log("permissions",permissions)

  useEffect(() => {
    
      fetchData(location?.state?.item?._id)
     
    
  }, [location.state?.item?._id  , permissions]);


  const fetchData = async (id) => {
    try {
    
        const response = await userRequest.get(`/roles/getParticularRoles/${id}`);
        console.log("response$$",response)
        const updatedData = {
          ...response?.data?.data,
          permissions: response?.data?.data.permissions.map(permission => ({
            ...permission,
            checked: true // Assuming all permissions are checked by default
          }))
        };

        
   const permissionArr = response?.data?.data.permissions
      console.log("permissionArr",permissionArr)
      const modifiedP2 = permissions.map(objP2 => {

        return {
            ...objP2,
            isSelected: permissionArr.some(permission => {
             return permission.permissionsId.permissionName == objP2.permissionName
            })
        };
    });
    console.log("modifiedP2",modifiedP2)
        // permissions?.map((item)=>({
        //   if(response?.data?.data?.permissions)
        // }))
      setData({...response?.data?.data , permissions: modifiedP2});
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  console.log("data%%" , data)

  const handlePermissionChange = (permissionId) => {
    const updatedPermissions = data?.permissions?.map(permission => {
      if (permission._id === permissionId) {
        return {
          ...permission,
          isSelected: !permission.isSelected // Toggle the checked state
        };
      }
      return permission;
    });
    // setData(updatedPermissions)
    setData(prevData => ({
      ...prevData,
      permissions: updatedPermissions
    }));
  };



  const saveData = async () => {
    try {

      // let mobileNumber = data.mobile;
      // if (!mobileNumber.startsWith("+91")) {
      //   mobileNumber = "+91" + mobileNumber;
      // }
      // Filter out permissions that are not checked
      const selectedPermissions = data.permissions.filter(permission => permission.isSelected);
      console.log("selectedPermissions",selectedPermissions)
   
      // Map the selected permissions array to extract permissionsId
      const permissionsData = selectedPermissions.map(permission => ({
        permissionsId: permission?._id
      }));
      console.log("permissionsData",permissionsData)
  
      // Create the payload with the selected permissions data
      const payload = {
        ...data,
        permissions: permissionsData
      };

      console.log("payload",payload)
  
      let response = await userRequest.put(`/roles/updateRoles/${data?._id}`, payload);
      // let response = await axios.put(`http://localhost:8080/api/roles/updateRoles/${data?._id}`, payload);

      // setData(response?.data?.data)
      await fetchData(location.state?.item?._id)
      
      toast.success("Saved successfully!");
      navigate("/rolesandpermissions")
      // Handle response as needed
    } catch (error) {
      console.log("Error saving data:", error);
      toast.error( error);

      // toast.error( error?.response?.data?.message);
      // console.log("Error saving data:", error.message);
      // alert("Failed to save data. Please try again later.");
    }
  };
  
  


  return (
    <div className={styles.main}>
      {/* <div>
        {JSON.stringify(data)}
        </div> */}
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderLeft}>
          <p  className={styles.createRolesPara}>
            <span onClick={() => navigate("/rolesandpermissions")}>
              Roles and Permissions Management
            </span>{" "} 
            <span className={styles.activePageStyle}>
              &gt; Edit
            </span>
          </p>
        </div>
        <div className={styles.pageHeaderRight}>
          <button className={styles.btnSaveDiscard} onClick={() => navigate("/rolesandpermissions")}>Discard</button>
          <button className={styles.btnSaveDiscard} onClick={saveData}>Save</button>
        </div>
      </div>
      <div className={styles.contentContainer}>
      <h4>Basics Details</h4>

        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Name</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={data.name}
                onChange={(e) =>
                  setData({ ...data, name: e.target.value })
                }
                placeholder="Enter"
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Email Id</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={data.email}
                onChange={(e) =>
                  setData({ ...data, email: e.target.value })
                }
                placeholder="Enter"
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Password</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={data.password}
                onChange={(e) =>
                  setData({ ...data, password: e.target.value })
                }
                placeholder="Enter"
              />
            </div>
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>UserType</p>
            </div>
            <div className={styles.fieldInput}>
              {/* <input
                type="text"
                value={data.userType}
                onChange={(e) =>
                  setData({ ...data, userType: e.target.value })
                }
                placeholder="Enter"
              /> */}
                <select
                value={data.userType}
                onChange={(e) =>
                  setData({ ...data, userType: e.target.value })
                }
              >
                {/* Define dropdown options */}
                <option value="SUB-ADMIN">SUB-ADMIN</option>
                {/* <option value="PANDIT">PANDIT</option> */}
                {/* Add more options if needed */}
              </select>
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Designation</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={data.designation}
                onChange={(e) =>
                  setData({ ...data, designation: e.target.value })
                }
                placeholder="Enter"
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Confirm Password</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={data.confirmPassword}
                onChange={(e) =>
                  setData({ ...data, confirmPassword: e.target.value })
                }
                placeholder="Enter"
              />
            </div>
          </div>
        </div>
        <div className={styles.permissionDiv}>
          <h5>Pemissions</h5>
          <div className={styles.rolesTable}>
            <table>
              <tbody>
              {data?.permissions?.map((permission) => (
                
                  <tr className={styles.firstRow} key={permission._id}>
                    <td>
                      <div className={styles.permissionName}>
                        <input
                          type="checkbox"
                          checked={permission.isSelected}
                          onChange={() => handlePermissionChange(permission._id)}
                        />
                        <p className={styles.permissionNamePara}>{permission?.permissionName}</p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
} 


export default EditRoles;




