import React, { useEffect, useState } from "react";
import styles from "./payoutMgmt.module.css";
import cardIcon from "../../assets/images/cardIcon.png";
import { useNavigate } from "react-router-dom";
import SelectComponent from "../../components/SelectComponent";
import Calendar from "../../components/calender/Calender";
import { BASE_URL, userRequest } from "../../requestMethod";
import { Button } from "react-bootstrap";
import { toast } from "sonner";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { Spin } from "antd";
import useDebounce from "../../components/UseDebounce";

function PayoutManagement() {
  const [selectedOption, setSelectedOption] = useState("");
  const navigate = useNavigate();
  const [payoutData, setPayOutData] = useState([]);
  const [cardData, setCardData] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [page, setPage] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [isFilterVisible, setFilterVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  const toggleFilterVisibility = () => {
    setFilterVisible(!isFilterVisible);
  };

  const statusOption = [
    { label: "REQUESTED", value: "REQUESTED" },
    { label: "SUCCESS", value: "SUCCESS" },
    { label: "PENDING", value: "PENDING" },
  ];

  const handleStatusChange = (selectedStatus) => {
    setSelectedStatus(selectedStatus.value);
  };

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [
    debouncedSearchQuery,
    page,
    selectedCountry,
    selectedRegion,
    selectedStatus,
    selectedDate,
  ]);

  const fetchData = async () => {
    try {
      const response = await userRequest.get(
        `/payout/getAllPayoutData?search=${debouncedSearchQuery}&country=${selectedCountry}&state=${selectedRegion}&status=${selectedStatus}&date=${selectedDate}&page=${page}`
      );
      const cardResponse = await userRequest.get(
        `/payout/getAdminPayoutCardData`
      );
      setPayOutData(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
      setPage(response.data.pagination.page);
      setCardData(cardResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // const handlePayClick = async (payId, payoutAmt, LevelID, campaignId) => {
  //   let payData = {
  //     transfer_amount: payoutAmt,
  //     beneficiary_details: {
  //       beneficiary_id: payId,
  //     },
  //   };
  //   try {
  //     const createTransferResponse = await userRequest.post(
  //       "/payout/createSvarnaPaymentTransfer",
  //       payData
  //     );
  //     const transferId = createTransferResponse.data.data.transfer_id;
  //     toast.success(
  //       "Payment transfer initiated. Checking status in 5 seconds..."
  //     );

  //     // Wait for 5 seconds before checking the status
  //     setTimeout(async () => {
  //       try {
  //         const getTransferStatusResponse = await userRequest.get(
  //           `/payout/getSvarnaPaymentTransferStatus/${transferId}?payId=${payId}&LevelID=${LevelID}&campaignId=${campaignId}`
  //         );
  //         toast.success(getTransferStatusResponse.data.message);
  //         fetchData(); // Refresh data after getting status
  //       } catch (error) {
  //         toast.error("Error checking payment status");
  //       }
  //     }, 5000);
  //   } catch (error) {
  //     toast.error("Error processing payment");
  //   }
  // };

  const handleDetail = (item) => {
    navigate(`/payoutmanagement/payoutdetails/${item._id}`, {
      state: { item },
    });
  };

  // const handleExport = async() => {
  //   console.log(page)
  //   const exportUrl = await userRequest.get(
  //     `/payout/exportPayoutDataToExcel?search=${searchQuery}&page=${page}&country=${selectedCountry}&state=${selectedRegion}&status=${selectedStatus}&date=${selectedDate}`
  //   );
  //   console.log(exportUrl)
  //   // window.open(exportUrl, "_parent");
  // };


  const handleExport = async () => {
    try {
      const exportResponse = await userRequest.get(
        `/payout/exportPayoutDataToExcel?search=${searchQuery}&page=${page}&country=${selectedCountry}&state=${selectedRegion}&status=${selectedStatus}&date=${selectedDate}`,
        {
          responseType: 'blob',         }
      );

      // Create a Blob object from the response data
      const blob = new Blob([exportResponse.data], { type: 'application/octet-stream' });

      // Create a URL for the blob and initiate download
      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = 'exported_data.xlsx'; // Set the file name here
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error exporting data:", error);
      toast.error("Error exporting data. Please try again later.");
    }
  }

  return (
    <div className={styles.main}>
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderLeft}>
          <p>Payout Management</p>
        </div>
        {/* <div className={styles.pageHeaderRight}>
          <div className={styles.pageHeaderMonth}>
            <p>March 2023</p>
          </div>
          <div className={styles.filterButton}>
            <button>Daily</button>
            <button>Weekly</button>
            <button>Monthly</button>
          </div>
        </div> */}
        {/* <button onClick={() => navigate("/panditkycmanagement/viewkycprice")}>
          View KYC Price
        </button> */}
      </div>
      <div className={styles.cardMain}>
        <div className={styles.cardLeftMain}>
          <div className={styles.singleCardLeft}>
            <div className={styles.cardIcon}>
              <img src={cardIcon} alt="" />
            </div>
            <div className={styles.cardContent}>
              <p>{cardData?.totalTransferAmt}</p>
              <p>Total Transfer</p>
              <p>Amount</p>
            </div>
          </div>
          <div className={styles.singleCardLeft}>
            <div className={styles.cardIcon}>
              <img src={cardIcon} alt="" />
            </div>
            <div className={styles.cardContent}>
              <p>{cardData?.totalTransferCount}</p>
              <p>Total Transfer</p>
              <p>Count</p>
            </div>
          </div>
          <div className={styles.singleCardLeft}>
            <div className={styles.cardIcon}>
              <img src={cardIcon} alt="" />
            </div>
            <div className={styles.cardContent}>
              <p>{cardData?.totalPayout}</p>
              <p>Total</p>
              <p>Your Payout</p>
            </div>
          </div>
        </div>
        <div className={styles.cardRightMain}>
          <div className={styles.cardRightBox}>
            <div className={styles.cardRightContainer}>
              <Calendar setSelectedDate={setSelectedDate} />
            </div>
          </div>
        </div>
      </div>
      <Spin spinning={loading} size="small">
        <div className={styles.tableContainer}>
          <div
            className={styles.selectContainer}
            style={{ display: isFilterVisible ? "flex" : "none" }}
          >
            <div className={styles.singleSelect}>
              <p>Country</p>
              {/* <SelectComponent /> */}
              <CountryDropdown
                value={selectedCountry}
                onChange={(val) => setSelectedCountry(val)}
              />
            </div>
            <div className={styles.singleSelect}>
              <p>State</p>
              {/* <SelectComponent /> */}
              <RegionDropdown
                country={selectedCountry}
                value={selectedRegion}
                onChange={(val) => setSelectedRegion(val)}
              />
            </div>
            <div className={styles.singleSelect}>
              <p>Status</p>
              <SelectComponent
                options={statusOption}
                onSelect={handleStatusChange}
              />
            </div>
            <div className={styles.inputDate}>
              <p>Dates</p>
              <input
                type="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.filterExport}>
            {/* <p>All</p> */}
            <div className={styles.search}>
              <input
                type="text"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className={styles.export}>
              <p>
                {/* <a
                  href={`${BASE_URL}/payout/exportPayoutDataToExcel?search=${searchQuery}&page=${page}`}
                  target="_parent"
                > */}
                  <p
              onClick={handleExport}
            >
                  Export as CSV, PDF, Excel doc
                </p>
              </p>
            </div>
            <div className={styles.filterDiv}>
              <p onClick={toggleFilterVisibility}>Filter</p>
            </div>
          </div>
          <div className={styles.tableSection}>
          <table>
            <thead>
              <tr>
                <th>Payout ID</th>
                <th>Recipient Name</th>
                <th>Role</th>
                <th>Campaign ID</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Details</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {payoutData.length > 0 ? (
                payoutData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.payId}</td>
                    <td>{item.receiptName}</td>
                    <td>{item.role}</td>
                    <td>{item.campaignId}</td>
                    <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                    <td>{item.payoutAmt}</td>
                    <td>{item.status}</td>
                    <td
                      className={styles.kycViewDetails}
                      // onClick={() =>
                      //   navigate(`/payoutmanagement/payoutdetails/${item._id}`)
                      // }
                      onClick={() => handleDetail(item)}
                    >
                      View
                    </td>
                    {/* <td>
                      <button
                        className={
                          item.status === "SUCCESS"
                            ? styles.disabledPayButton
                            : styles.payButton
                        }
                        onClick={() =>
                          handlePayClick(
                            item.payId,
                            item.payoutAmt,
                            item.LevelID,
                            item.campaignId
                          )
                        }
                        disabled={
                          item.status === "SUCCESS" || item.status === "PENDING"
                        }
                      >
                        {item.status === "SUCCESS" ? "Paid" : "Pay"}
                      </button>
                    </td> */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10">No data found</td>
                </tr>
              )}
            </tbody>
          </table>
          </div>
          {payoutData.length > 0 ? (
            <div className={styles.footer}>
              <div
                className={styles.action}
                style={{ visibility: totalPages === 1 ? "hidden" : "visible" }}
              >
                <button
                  className={`${styles.actionButton} ${
                    page === 1 ? styles.disabled : styles.activeButton
                  }`}
                  onClick={handlePrevious}
                  disabled={page === 1}
                >
                  Previous
                </button>
                <button
                  className={`${styles.actionButton} ${
                    page === totalPages ? styles.disabled : styles.activeButton
                  }`}
                  onClick={handleNext}
                  disabled={page === totalPages}
                >
                  Next
                </button>
              </div>
              <div className={styles.page}>
                <span>{page}</span> <span>of</span>{" "}
                <span>{totalPages}</span>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </Spin>
    </div>
  );
}

export default PayoutManagement;
