import { Navigate, useLocation } from "react-router-dom";

function PrivateRoute({ children }) {
  const token = localStorage.getItem("token");
  const location = useLocation();

  // Store the requested URL if the user is not authenticated
  if (!token) {
    localStorage.setItem("redirectPath", location.pathname);
    return <Navigate to="/login" />;
    
  }

  // If the user is authenticated, clear the stored redirect URL
  const redirectPath = localStorage.getItem("redirectPath");
  if (redirectPath) {
    localStorage.removeItem("redirectPath");
    return <Navigate to={redirectPath} />;
  }

  

  return children;
}

export default PrivateRoute;