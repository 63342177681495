import React, { useState } from "react";
import styles from "./setKYC.module.css";
import { useNavigate, useParams } from "react-router-dom";
import KYCPrice from "./KYCPrice";
import SetCoupon from "./SetCoupon";
import { userRequest } from "../../../../requestMethod";
import { toast } from "sonner";

function SetKYCPrice() {
  const [editKYC,setEditKYC] = useState(false)
  const menuItems = [
    { label: "KYC Price", state: "KYC Price" },
    { label: "Coupon", state: "Coupon" },
  ];

  const navigate = useNavigate();
  const [activeMenuItem, setActiveMenuItem] = useState("KYC Price");
  const {id} = useParams()
  const initialFormData = {
    country: "India",
    state: "",
    kycPrice:"",
    DiscountType:"",
    finalAmount:"",
    discountAmount:"",
    taxType:"",
    taxPercentage:"",
    finalTaxAmount:"",
    startDate:"",
    expiryDate:""
  };

  const [formData, setFormData] = useState(initialFormData);

  const handlePoliciesMenu = (menu) => {
    setActiveMenuItem(menu);
  };

  const handleSaveClick = async () => {
    if (activeMenuItem === "KYC Price" && editKYC ===false) {
      if (!formData.country || !formData.state || !formData.kycPrice || !formData.DiscountType || !formData.discountAmount || !formData.finalAmount || !formData.taxType || !formData.taxPercentage || !formData.finalTaxAmount || !formData.startDate || !formData.expiryDate) {
        // If any field is empty, show an error message and return
        toast.error("Please fill in all fields.");
        return;
      }
    
      try {
        await userRequest.post("/admin/adminPanditKycRoutes/createKycPrice", formData);
        setFormData(initialFormData); 
        toast.success("Saved successfully!");
      } catch (error) {
        console.error("Error saving data:", error.message);
        alert("Failed to save data. Please try again later.");
      }
    } else if (activeMenuItem === "KYC Price" && editKYC ===true){
      try {
        const response = await userRequest.put(
          `/admin/adminPanditKycRoutes/updateKycPrice/${id}`,
           formData 
        );
        console.log("KYC Updted:", response.data);
        //   campaignDetails(initialCampaignDetails);
        toast.success("Updated successfully!");
        navigate("/panditkycmanagement/viewkycprice");
      } 
      
      catch (error) {
        console.error("Error saving campaign:", error);
      }
    }else{
      console.log("HIiii")
    }
  };
  
  return (
    <div className={styles.main}>
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderLeft}>
          <p>
            <span onClick={() => navigate("/panditkycmanagement")}>Pandit KYC Management</span>{" "}
            <span className={styles.activePageStyle}>&gt; Set Price</span>
          </p>
        </div>
        <div className={styles.pageHeaderRight}>
          <button onClick={() => navigate("/panditkycmanagement/viewkycprice")}>
            Discard
          </button>
          <button onClick={handleSaveClick}>
            Save
          </button>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.menuContainer}>
          {menuItems.map((item) => (
            <div
              key={item.state}
              className={
                activeMenuItem === item.state
                  ? `${styles.singleMenu} ${styles.activeMenu}`
                  : styles.singleMenu
              }
              onClick={() => handlePoliciesMenu(item.state)}
            >
              <p>{item.label}</p>
            </div>
          ))}
        </div>
        <div className={styles.activePage}>
          {activeMenuItem === "KYC Price" && <KYCPrice formData={formData} setFormData={setFormData} setEditKYC={setEditKYC}/>}
          {activeMenuItem === "Coupon" && <SetCoupon />}
        </div>
      </div>
    </div>
  );
}

export default SetKYCPrice;
