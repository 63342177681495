import React, { useEffect, useState } from "react";
import styles from "./viewSamhita.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { userRequest } from "../../../requestMethod";
import FileViewer from "./FileViewer";
;

function ViewSamhita() {
  const location = useLocation();
  const samhita = location.state && location.state.samhita;
  console.log(samhita);
  const navigate = useNavigate();
  return (
    <div className={styles.main}>
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderLeft}>
          <p>
            <span onClick={() => navigate("/samhitamanagement")}>Samhita</span>{" "}
            <span className={styles.activePageStyle}>&gt; View Samhita</span>
          </p>
        </div>
        <div className={styles.pageHeaderRight}>
          <button onClick={() => navigate("/samhitamanagement")}>Back</button>
        </div>
      </div>
      <div className={styles.contentContainer}>
        {samhita?.uploadPdf ? (
          <FileViewer
            fileType={samhita?.samhitaType}
            fileUrl={samhita?.uploadPdf}
          />
        ) : //  <FileViewer samhitatype={samhita?.samhitaType} fileUrl={samhita?.uploadPdf}/>
        null}
      </div>
    </div>
  );
}

export default ViewSamhita;
