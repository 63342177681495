import React, { useEffect, useState } from "react";
import styles from "./campaign.module.css";
import { useNavigate } from "react-router-dom";
import { BASE_URL, userRequest } from "../../../requestMethod";
import editIcon from "../../../assets/images/edit.png";
import { Spin, Switch } from "antd";
import CampaignFilter from "./CampaignFilter";
import useDebounce from "../../../components/UseDebounce";
function Campaign() {
  const navigate = useNavigate();
  const [campaignData, setCampaignData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [loading, setLoading] = useState(true)

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [debouncedSearchQuery, page, filterData]);

  const fetchData = async () => {
    try {
      const filterQuery = filterData
      ? `&country=${filterData?.country}&state=${filterData.state}&NoOfLevels=${filterData?.NoOfLevels}`
      : "";
      const response = await userRequest.get(
        `/admin/svarna/getAdminCampaign?search=${debouncedSearchQuery}${filterQuery}&page=${page}`
      );
      setCampaignData(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
      setCurrentPage(response.data.pagination.page);
    } catch (error) {
      console.error("Error fetching data:", error);
    }finally{
      setLoading(false)
    }
  };

  const handleChange = async (id, activeStatus) => {
    try {
      const newActiveStatus = !activeStatus;
      await userRequest.patch(
        `/admin/svarna/updateCampaignStatus`,
        {
          campaignId: id,
          activeStatus: newActiveStatus
        }
      )
      fetchData()
    } catch (error) {
      console.error("Error updating user status:", error);
    }
  };
 const handleFilterClick = () => {
    setIsModalOpen(true);
  };

  const handleModalOk = (data) => {
    setFilterData(data);
    setIsModalOpen(false);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };
  console.log("campaign list",campaignData)
  return (
    <div className={styles.tableMain}>
      <div className={styles.filterExport}>
        {/* <p>All</p> */}
        <div className={styles.search}>
          <input type="text" onChange={(e) => setSearchQuery(e.target.value)} />
        </div>
        <div className={styles.export}>
          {/* <p><a href={`${BASE_URL}/campaign/exportPayoutDataToExcel?campaignId=${selectedOption}&receiptName=${searchQuery}&page=${page}`} target="_parent">Export as CSV, PDF, Excel doc</a></p> */}
          <p>
            <a
              href={`${BASE_URL}/campaign/exportCampaignDataToExcel?search=${searchQuery}&page=${page}`}
              target="_parent"
            >
              Export as CSV, PDF, Excel doc
            </a>
          </p>
        </div>
        <div className={styles.filterDiv}>
              <p onClick={handleFilterClick}>Filter</p>
            </div>
      </div>
      <Spin spinning={loading} size="small">
      <div className={styles.campaignTableMain}>
      <table>
        <thead>
          <tr>
            <th>ID.</th>
            <th>Campaign name</th>
            <th>Country</th>
            <th>State</th>
            <th>End Date</th>
            <th>No of Level</th>
            <th>Total Task</th>
            <th>Max Tej Coins</th>
            <th>Max Lakshmi Coins</th>
            <th>Completed By</th>
            <th>Details</th>
            <th>Action & Status</th>
          </tr>
        </thead>
        <tbody>
          {campaignData.length > 0 ? (
            campaignData.map((campaign) => (
              <tr key={campaign._id}>
                <td>{campaign._id}</td>
                <td>{campaign.campaignTitle}</td>
                <td>{campaign.country}</td>
                <td>{campaign.state}</td>
                <td>
                  {new Date(campaign?.Duration?.endDate).toLocaleDateString()}
                </td>
                <td>{campaign.NoOfLevels}</td>
                <td>{campaign.totalTasks}</td>
                <td>{campaign.maxTejCoins}</td>
                <td>{campaign.maxLakshmiCoins}</td>
                <td>{campaign.userCount}</td>
                <td
                  className={styles.viewDetails}
                  onClick={() =>
                    navigate(`/svarnamanagement/viewcampaign/${campaign._id}`)
                  }
                >
                  View
                </td>
                <td className={styles.actionTd}>
                  <img
                    src={editIcon}
                    alt=""
                    onClick={() =>
                      navigate(`/svarnamanagement/editcampaign/${campaign?._id}`)
                    }
                  />
               <Switch size="small" checked={campaign.activeStatus} onChange={() => handleChange(campaign._id, campaign.activeStatus)}/>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="11">No data found</td>
            </tr>
          )}
        </tbody>
      </table>
      </div>

      {campaignData.length > 0 ? (
          <div className={styles.footer}>
          <div
            className={styles.action}
            style={{ visibility: totalPages === 1 ? "hidden" : "visible" }}
          >
            <button
              className={`${styles.actionButton} ${
                page === 1 ? styles.disabled : styles.activeButton
              }`}
              onClick={handlePrevious}
              disabled={page === 1}
            >
              Previous
            </button>
            <button
              className={`${styles.actionButton} ${
                page === totalPages ? styles.disabled : styles.activeButton
              }`}
              onClick={handleNext}
              disabled={page === totalPages}
            >
              Next
            </button>
          </div>
          <div className={styles.page}>
            <span>{currentPage}</span> <span>of</span>{" "}
            <span>{totalPages}</span>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      </Spin>
       <CampaignFilter isModalOpen={isModalOpen} handleOk={handleModalOk} handleCancel={handleModalCancel} />
    </div>
  );
}

export default Campaign;
