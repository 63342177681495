import {createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'
import {BASE_URL} from '../../requestMethod.js'


export const adminLogin = createAsyncThunk("/admin/adminLogin/" , 
async({payload} , {rejectWithValue})=>{
try{
const {data} = await axios.post(`${BASE_URL}/admin/login` ,payload)

localStorage.setItem("token" , data?.token)
let token =  data?.token

return data
}
catch(err){
    console.log("error**"  , err?.response?.data?.message)
    return rejectWithValue(err?.response?.data?.message)
}
})

export const adminForgotPassword = createAsyncThunk("/admin/adminForgotPassword/" , 
async({payload} , {rejectWithValue})=>{
try{
    console.log("payload", payload)
const {data} = await axios.post(`${BASE_URL}/admin/forgotPassword` ,payload)
console.log("data" , data)

// localStorage.setItem("token" , data?.token)
return data
}
catch(err){
    console.log("error**"  , err?.response?.data?.message)
    return rejectWithValue(err?.response?.data?.message)
}
})

export const adminResetPassword = createAsyncThunk("/admin/adminResetPassword/" , 
async({payload , token} , {rejectWithValue})=>{
try{
    // console.log("payload", payload)
    
    // console.log("token##", token)
    const config ={
        headers :{
            Authorization:`Bearer ${token}`,
            "Content-Type":"application/json"
        }
    }
    

const {data} = await axios.post(`${BASE_URL}/admin/resetPassword` ,payload , config)
console.log("data" , data)


return data
}
catch(err){
    console.log("error**"  , err?.response?.data?.message)
    return rejectWithValue(err?.response?.data?.message)
}
})