import React, { useEffect, useState } from "react";
import styles from "./bannerManagement.module.css";
import { useNavigate } from "react-router-dom";
import { BASE_URL, userRequest } from "../../requestMethod";
import BannerFilter from "./BannerFilter";
import { Spin } from "antd";
import useDebounce from "../../components/UseDebounce";



function BannerManagement() {
  const [bannerData, setBannerData] = useState([]);
  const [selectedBanner, setSelectedBanner] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({ startDate: "", endDate: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [exportUrl, setExportUrl] = useState("");
  const [loading, setLoading] = useState(true)

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [debouncedSearchQuery, page, filters]);

  const fetchData = async () => {
    try {
      const { startDate, endDate } = filters;
      const response = await userRequest.get(
        `/master/getMasterBannerAdmin?search=${debouncedSearchQuery}&startDate=${startDate}&endDate=${endDate}`
      );      
      setBannerData(response.data.banners);
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.currentPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    }finally{
      setLoading(false)
    }
  };

  const handleViewClick = (banner) => {
    setSelectedBanner(banner);
    navigate(`/bannermanagement/view`, { state: { banner } });
  };

  const handleEditClick = (banner) => {
    setSelectedBanner(banner);
    navigate(`/bannermanagement/edit`, { state: { banner } });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = (filterData) => {
    setFilters(filterData);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const updateExportUrl = () => {
    const { startDate, endDate } = filters;
    const url = `${BASE_URL}/master/exportBannerDataToExcel?search=${searchQuery}&startDate=${startDate}&endDate=${endDate}`;
    setExportUrl(url);
  };
  
  useEffect(() => {
    updateExportUrl();
  }, [searchQuery, filters]);

  return (
    <div className={styles.main}>
      <div className={styles.pageHeader}>
        <p className={styles.bannerHeader}>
          <span onClick={() => navigate("/bannermanagement")}>
            Banner Management
          </span>
        </p>
        <button className={styles.addNewBtn} onClick={() => navigate("/bannermanagement/create")}>
          Create New Banner
        </button>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.activePage}>
          <div className={styles.filterExport}>
            <div className={styles.search}>
              <input
                type="text"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className={styles.export}>
              <p>
                <a
                  href={exportUrl} 
                  target="_parent"
                >
                  Export as CSV, PDF, Excel doc
                </a>
              </p>
            </div>
            <div className={styles.filterDiv}>
              <span>
                <p onClick={showModal}>
                  Filter
                </p>
              </span>
            </div>
          </div>
          <Spin spinning={loading} size="small">
          <div className={styles.tableContainer}>
            <table>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Languages</th>
                  <th>Geographical Scope</th>
                  <th>Target Audience</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {bannerData.length > 0 ? (
                  bannerData.map((banner) => (
                    <tr key={banner?._id}>
                      <td>{banner?.title}</td>
                      <td>{formatDate(banner.startDate)}</td>
                      <td>{formatDate(banner?.endDate)}</td>
                      <td>{banner?.language}</td>
                      <td>{banner?.geographicalScope}</td>
                      <td>{banner?.targetAudience}</td>


                      <td>
                        <span
                          className={styles.viewDetails}
                          onClick={() => handleViewClick(banner)}
                        >
                          View
                        </span>
                        <span
                          className={styles.editDetails}
                          onClick={() => handleEditClick(banner)}
                        >
                          Edit
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8">No data found</td>
                  </tr>
                )}
              </tbody>
            </table>
            {bannerData.length > 0 && (
              <div className={styles.footer}>
                <div
                  className={styles.action}
                  style={{
                    visibility: totalPages === 1 ? "hidden" : "visible",
                  }}
                >
                  <button
                    className={`${styles.actionButton} ${
                      page === 1 ? styles.disabled : styles.activeButton
                    }`}
                    onClick={handlePrevious}
                    disabled={page === 1}
                  >
                    Previous
                  </button>
                  <button
                    className={`${styles.actionButton} ${
                      page === totalPages
                        ? styles.disabled
                        : styles.activeButton
                    }`}
                    onClick={handleNext}
                    disabled={page === totalPages}
                  >
                    Next
                  </button>
                </div>
                <div className={styles.page}>
                  <span>{currentPage}</span> <span>of</span>{" "}
                  <span>{totalPages}</span>
                </div>
              </div>
            )}
          </div>
          </Spin>
        </div>
      </div>
      <BannerFilter 
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </div>
  );
}

export default BannerManagement;

function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
}
