import React, { useEffect, useState } from "react";
import styles from "./ViewRoles.module.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { userRequest } from "../../../../requestMethod";
import { toast } from "sonner";
import { useLocation, useNavigate } from "react-router-dom";


function ViewRoles() {
  const navigate = useNavigate();
  const location = useLocation();
  const [item, setItem] = useState([]);
  



  useEffect(() => {
    // Fetch item data from location state when component mounts
    if (location.state && location.state.item) {
      setItem(location.state.item);
    }
  }, [location.state]);



  

  return (
    <div className={styles.main}>
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderLeft}>
          <p className={styles.createRolesPara}>
            <span onClick={() => navigate("/rolesandpermissions")}>
              Roles and Permissions Management
            </span>{" "}
            <span className={styles.activePageStyle}>
              &gt; details
            </span>
          </p>
        </div>
        <div className={styles.pageHeaderRight}>
          <button className={styles.btnSaveDiscard} onClick={() => navigate("/rolesandpermissions")}>Back</button>
          <button className={styles.btnSaveDiscard} onClick={()=>navigate(`/rolesandpermissions/editroles/${location?.state?.item?._id}` ,{ state: { item } })}>Edit</button>
          {/* <button >Edit</button> */}

        </div>
      </div>
      <div className={styles.rolesContainer}>
        <h4>Basics Details</h4>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Name</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={item.name}
               
                placeholder="Enter"
                disabled
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Email Id</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={item.email}
            
                placeholder="Enter"
                disabled
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Password</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={item.password}
              
                placeholder="Enter"
                disabled
              />
            </div>
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>UserType</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={item.userType}
              
                placeholder="Enter"
                disabled
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Designation</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={item.designation}
                
                placeholder="Enter"
                disabled
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Confirm Password</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={item.confirmPassword}
               
                placeholder="Enter"
                disabled
              />
            </div>
          </div>
        </div>
        <div className={styles.permissionDiv}>
          <h5>Pemissions</h5>
          <div className={styles.rolesTable}>
            <table>
              <tbody>
              {item?.permissions?.map((permission) => (
                <>
                  <tr className={styles.firstRow}>
      <td>
      <div className={styles.permissionName}>

        <input
          type="checkbox"
          checked={permission?._id}
        />
        <p className={styles.permissionNamePara}>{permission?.permissionsId?.permissionName}</p>
        </div>
      </td>
      {/* <td>
      <div className={styles.editDiv}>
        <input
          type="checkbox"
          checked={permission?._id && permission?.edit}
        />
        <p className={styles.permissionNamePara}>Edit</p>
        </div>
      </td>
      <td>
      <div className={styles.editDiv}>
        <input
          type="checkbox"
          checked={permission?._id && permission?.view}
        />
        <p className={styles.permissionNamePara}>Only View</p>
        </div>
      </td> */}
    </tr>
                </>
              ))}
             
  
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewRoles;
