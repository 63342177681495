import React, { useEffect, useState } from "react";
import styles from "./CreateCampaign.module.css";
import { useNavigate } from "react-router-dom";
import SelectComponent from "../../../../components/SelectComponent";
import RichTextEditor from "../../../../components/RichTextEditor/RichTextEditor";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { userRequest } from "../../../../requestMethod";
import { toast } from "sonner";
import Stepper from "awesome-react-stepper";
import { Spin } from "antd";

function CreateCampaign() {
  const navigate = useNavigate();
  const [rules, setRules] = useState([""]);
  const [taskTypes, setTaskTypes] = useState([]);
  const [levelAdded, setLevelAdded] = useState(false);
  const [taskAdded, setTaskAdded] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [numberOfLevel, setNumberOfLevel] = useState(0);
  const [numberOfTask, setNumberOfTask] = useState(0);
  const initialCampaignDetails = {
    campaignTitle: "",
    NoOfLevels: numberOfLevel,
    Duration: {
      startDate: "",
      endDate: "",
    },
    role: "",
    country: "",
    state: "",
    rule: [""],
    termsAndConditions: "",
    status: "Draft",
    levelsData: [],
  };
  const [campaignDetails, setCampaignDetails] = useState(
    initialCampaignDetails
  );

  useEffect(() => {
    // Fetch task types from the API
    const fetchTaskTypes = async () => {
      try {
        const response = await userRequest.get("/master/getMasterTaskType");
        setTaskTypes(response?.data?.data);
      } catch (error) {
        console.error("Error fetching task types:", error);
      }
    };
    fetchTaskTypes();
  }, []);

  const handleAddRule = () => {
    setRules([...rules, ""]);
    setCampaignDetails((prevDetails) => ({
      ...prevDetails,
      rule: [...prevDetails.rule, ""],
    }));
  };

  const handleRemoveRule = (index) => {
    const newRules = [...rules];
    newRules.splice(index, 1);
    setRules(newRules);
    setCampaignDetails((prevDetails) => {
      const newRuleList = [...prevDetails.rule];
      newRuleList.splice(index, 1);
      return {
        ...prevDetails,
        rule: newRuleList,
      };
    });
  };

  const handleRuleChange = (index, event) => {
    const newRules = [...rules];
    newRules[index] = event.target.value;
    setRules(newRules);
    setCampaignDetails((prevDetails) => {
      const newRuleList = [...prevDetails.rule];
      newRuleList[index] = event.target.value;
      return {
        ...prevDetails,
        rule: newRuleList,
      };
    });
  };

  const handleAddLevel = () => {
    setNumberOfLevel((prev) => prev + 1);
    setLevelAdded(true);
    setCampaignDetails((prevState) => ({
      ...prevState,
      levelsData: [
        ...prevState.levelsData,
        {
          levelName: "",
          levelDescription: "",
          totalTasks: numberOfTask,
          maxCoins: 0,
          coins: "",
          tasksData: [],
        },
      ],
    }));
    setTimeout(() => {
      setLevelAdded(false);
    }, 1000);
  };

  const handleRemoveLevel = (index) => {
    setNumberOfLevel((prev) => prev - 1);
    setCampaignDetails((prevState) => ({
      ...prevState,
      levelsData: prevState.levelsData.filter((_, i) => i !== index),
    }));
  };

  const handleAddTask = (levelIndex) => {
    setTaskAdded(true);
    setNumberOfTask((prev) => prev + 1);
    setCampaignDetails((prevState) => {
      const newLevelsData = [...prevState.levelsData];
      newLevelsData[levelIndex].tasksData.push({
        taskName: "",
        taskDescription: "",
        coinValue: 0,
        taskCount: 1,
        operationId: "",
        taskType: "",
        numberOfDays: 0,
      });
      return {
        ...prevState,
        levelsData: newLevelsData,
      };
    });
    setTimeout(() => {
      setTaskAdded(false);
    }, 1000);
  };

  const handleRemoveTask = (levelIndex, taskIndex) => {
    setNumberOfTask((prev) => prev - 1);
    setCampaignDetails((prevState) => {
      const newLevelsData = [...prevState.levelsData];
      newLevelsData[levelIndex].tasksData.splice(taskIndex, 1);
      return {
        ...prevState,
        levelsData: newLevelsData,
      };
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const regex = /^[a-zA-Z\s]*$/;

    if (name === "campaignTitle") {
      if (!regex.test(value)) {
        toast.error("Campaign Title should contain only letters");
        return;
      }

    }
    setCampaignDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDurationChange = (event) => {
    const { name, value } = event.target;
    setCampaignDetails((prevState) => ({
      ...prevState,
      Duration: {
        ...prevState.Duration,
        [name]: value,
      },
    }));
  };

  const roleOptions = [
    { value: "PANDIT", label: "Pandit" },
    { value: "USER", label: "User" },
  ];
  const coinOptions = [
    { value: "Tej", label: "Tej" },
    { value: "Lakshmi", label: "Lakshmi" },
  ];

  const handleRoleSelect = (option) => {
    setCampaignDetails((prevState) => ({
      ...prevState,
      role: option.value,
    }));
  };

  const handleCoinSelect = (option, levelIndex) => {
    setCampaignDetails((prevState) => {
      console.log("Prev state:", prevState);
      const newState = {
        ...prevState,
        levelsData: prevState.levelsData.map((levelDetail, idx) => {
          if (idx === levelIndex) {
            return {
              ...levelDetail,
              coins: option.value,
            };
          }
          return levelDetail;
        }),
      };
      return newState;
    });
  };

  const selectCountry = (val) => {
    setCampaignDetails((prevState) => ({
      ...prevState,
      country: val,
      state: "", // Reset state when country changes
    }));
  };

  const selectState = (val) => {
    setCampaignDetails((prevState) => ({
      ...prevState,
      state: val,
    }));
  };

  const handleTermsChange = (value) => {
    setTermsAndConditions(value);
    setCampaignDetails((prevDetails) => ({
      ...prevDetails,
      termsAndConditions: value,
    }));
  };

  const handleSave = async () => {
    const {
      campaignTitle,
      NoOfLevels,
      Duration,
      role,
      country,
      state,
      rule,
      termsAndConditions,
      levelsData,
    } = campaignDetails;

    if (
      !campaignTitle ||
      !NoOfLevels ||
      !Duration.startDate ||
      !Duration.endDate ||
      !role ||
      !country ||
      !state ||
      !rule ||
      !termsAndConditions ||
      levelsData.some(
        (level) =>
          !level.levelName ||
          level.tasksData.some((task) => !task.taskName || !task.coinValue)
      )
    ) {
      toast.error("Please fill in all required fields");
      return; // Exit the function without saving
    }

    const { startDate, endDate } = campaignDetails.Duration;
    if (new Date(endDate) <= new Date(startDate)) {
      toast.error("End date must be greater than start date");
      return;
    }
    try {
      let response = await userRequest.post(
        "/admin/svarna/addCampaign",
        campaignDetails
      );
      if (response?.status === 201) {
        navigate("/svarnamanagement");
        toast.success("Saved successfully!");
        setCampaignDetails(initialCampaignDetails);
      }
    } catch (error) {
      console.log("Error saving campaign:", error);
      console.log("err", error?.response?.data?.error);
      let err = error?.response?.data?.error;
      toast.error(err);
    }
  };

  const handleLevelInputChange = (index, field, value) => {
    const regex = /^[a-zA-Z\s]*$/;
    if (field === "levelName") {
      if (!regex.test(value)) {
        toast.error("level Name  should contain only letters");
        return;
      }

    }
    setCampaignDetails((prevState) => {
      const newLevel = [...prevState.levelsData];
      if (newLevel[index]) {
        if (field === "levelName") {
          newLevel[index].levelName = value;
          newLevel[index].levelNumber = index + 1;
          newLevel[index].levelDescription = value;
        } else if (field === "totalTasks") {
          newLevel[index].totalTasks = parseInt(value) || 0;
        } else if (field === "maxCoins") {
          newLevel[index].maxCoins = parseInt(value) || 0;
        } else if (field === "coins") {
          newLevel[index].coins = value;
        }
        return {
          ...prevState,
          levelsData: newLevel,
        };
      } else {
        // If newLevel[index] is undefined, return prevState as it is
        return prevState;
      }
    });
  };

  const handleTaskInputChange = (levelIndex, taskIndex, field, option) => {
    console.log(option);
    setCampaignDetails((prevState) => {
      const newLevelDetails = [...prevState.levelsData];
      const taskDetails =
        newLevelDetails[levelIndex].tasksData[taskIndex] || {};
      if (field === "taskName") {
        taskDetails.taskName = option.label;
        taskDetails.taskDescription = option.value;
        taskDetails.operationId = option.operationId;
        taskDetails.taskType = option.taskType;
      } else if (field === "coinValue") {
        taskDetails.coinValue = option;
      } else if (field === "taskDescription") {
        taskDetails.taskDescription = option.value;
      } else if (field === "taskCount") {
        taskDetails.taskCount = option;
      } else if (field === "numberOfDays") {
        taskDetails.numberOfDays = option;
      }
      newLevelDetails[levelIndex].tasksData[taskIndex] = taskDetails;
      return {
        ...prevState,
        levelsData: newLevelDetails,
      };
    });
  };

  const activeStep = campaignDetails?.levelsData?.length;

  return (
    <div className={styles.main}>
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderLeft}>
          <p>
            <span onClick={() => navigate("/svarnamanagement")}>
              Svarna Management
            </span>{" "}
            <span className={styles.activePageStyle}>&gt; Create Campaign</span>
          </p>
        </div>
        <div className={styles.pageHeaderRight}>
          <button onClick={() => navigate("/svarnamanagement")}>Discard</button>
          <button onClick={handleSave}>Save</button>
        </div>
      </div>

      <div className={styles.contentContainer}>
        <div className={styles.section}>
          <p>Campaign Details</p>
          {/* </div> */}
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Campaign Title</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="campaignTitle"
                  value={campaignDetails.campaignTitle}
                  onChange={handleInputChange}
                  placeholder="Enter"
                />
              </div>
            </div>
            {/* <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Number of level</p>
              </div>
              <div className={styles.fieldInput}>
                <SelectComponent
                  options={levels}
                  onSelect={handleLevelSelect}
                />
              </div>
            </div> */}
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Duration</p>
              </div>

              <div className={styles.fieldInputTwo}>
                <input
                  type="date"
                  name="startDate"
                  onChange={handleDurationChange}
                  placeholder="Enter"
                />
                <input
                  type="date"
                  name="endDate"
                  onChange={handleDurationChange}
                  placeholder="Enter"
                />
              </div>
            </div>

            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Role</p>
              </div>
              <div className={styles.fieldInput}>
                <SelectComponent
                  options={roleOptions}
                  onSelect={handleRoleSelect}
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Country</p>
              </div>
              <div className={styles.fieldInput}>
                {/* <SelectComponent /> */}
                <CountryDropdown
                  value={campaignDetails.country}
                  onChange={(val) => selectCountry(val)}
                />
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>State</p>
              </div>
              <div className={styles.fieldInput}>
                {/* <SelectComponent /> */}
                <RegionDropdown
                  country={campaignDetails.country}
                  value={campaignDetails.state}
                  onChange={(val) => selectState(val)}
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Max Lakshmi Coins</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="maxLakshmiCoins"
                  value={campaignDetails.maxLakshmiCoins}
                  onChange={handleInputChange}
                  placeholder="Enter"
                />
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Max Tej Coins</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="maxTejCoins"
                  value={campaignDetails.maxTejCoins}
                  onChange={handleInputChange}
                  placeholder="Enter"
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Add Rule </p>
              </div>
              <div className={`${styles.fieldInput} ${styles.fieldInputRule}`}>
                {rules.map((rule, index) => (
                  <div key={index}>
                    <div className={styles.rule}>
                      <textarea
                        value={rule}
                        onChange={(e) => handleRuleChange(index, e)}
                      />
                      {rules.length > 1 && (
                        <button
                          onClick={() => handleRemoveRule(index)}
                        ></button>
                      )}
                    </div>
                    {index === rules.length - 1 && (
                      <button onClick={handleAddRule}>Add More</button>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Add Terms & Conditions</p>
              </div>
              <div className={styles.fieldInput}>
                <RichTextEditor
                  value={termsAndConditions}
                  onChange={handleTermsChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.horizontalRow}></div>
        <div className={styles.levelDetailsMain}>
          <p className={styles.levelDetailsHeading}>Level Details</p>
          <button onClick={handleAddLevel}>Add More</button>
        </div>
        <Spin spinning={levelAdded} size="small">
          <Stepper
            defaultActiveStep={activeStep}
            strokeColor="#f68b21"
            fillStroke="#f68b21"
            activeColor="#f68b21"
            showProgressBar="true"
            activeProgressBorder="2px solid #f68b21"
            submitBtn={false}
            continueBtn={<button className={styles.stepperBtn}>Next</button>}
            backBtn={<button className={styles.stepperBtn}>Back</button>}
            // onSubmit={(step) => alert(`Thank you!!! Final Step -> ${step}`)}
          >
            {campaignDetails.levelsData.map((level, levelIndex) => (
              <div key={levelIndex} className={styles.stepperSubDiv}>
                <button
                  className={styles.deleteItem}
                  onClick={() => handleRemoveLevel(levelIndex)}
                ></button>
                <div className={styles.section}>
                  <div className={styles.fieldContainer}>
                    <div className={styles.feildLeft}>
                      <div className={styles.fieldLabel}>
                        <p>Level {levelIndex + 1}</p>
                      </div>
                    </div>
                  </div>
                  <div className={styles.fieldContainer}>
                    <div className={styles.feildLeft}>
                      <div className={styles.fieldLabel}>
                        <p>Name of Level</p>
                      </div>
                      <div className={styles.fieldInput}>
                        <input
                          type="text"
                          value={
                            campaignDetails.levelsData[levelIndex]?.levelName
                          }
                          onChange={(e) =>
                            handleLevelInputChange(
                              levelIndex,
                              "levelName",
                              e.target.value
                            )
                          }
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    {/* <div className={styles.feildRight}>
                      <div className={styles.fieldLabel}>
                        <p>Number of Task</p>
                      </div>
                      <div className={styles.fieldInput}>
                        <input
                          type="text"
                          value={levelDetails[levelIndex].numberOfTasks}
                          onChange={(e) =>
                            handleNumberOfTasksChange(levelIndex, e)
                          }
                          placeholder="Enter"
                        />
                      </div>
                    </div> */}
                  </div>
                  <div className={styles.fieldContainer}>
                    <div className={styles.feildLeft}>
                      <div className={styles.fieldLabel}>
                        <p>Max Coins</p>
                      </div>
                      <div className={styles.fieldInput}>
                        <input
                          type="text"
                          value={
                            campaignDetails.levelsData[levelIndex]?.maxCoins
                          }
                          onChange={(e) =>
                            handleLevelInputChange(
                              levelIndex,
                              "maxCoins",
                              e.target.value
                            )
                          }
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    <div className={styles.feildRight}>
                      <div className={styles.fieldLabel}>
                        <p>Select Coins</p>
                      </div>
                      <div className={styles.fieldInput}>
                        <SelectComponent
                          options={coinOptions}
                          onSelect={(option) =>
                            handleCoinSelect(option, levelIndex)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.section}>
                  <div className={styles.taskDetailsMain}>
                    <p>Task Details</p>
                    <button
                      className={styles.taskAdd}
                      onClick={() => handleAddTask(levelIndex)}
                      disabled={taskAdded}
                    >
                      Add Task
                    </button>
                  </div>

                  {level.tasksData.map((task, taskIndex) => (
                    <div
                      className={`${styles.fieldContainer} ${styles.fieldContainerTwo}`}
                    >
                      <div className={styles.deleteDiv}>
                        <button
                          className={styles.deleteItemTwo}
                          onClick={() =>
                            handleRemoveTask(levelIndex, taskIndex)
                          }
                        ></button>
                      </div>
                      <div className={styles.feildLeft}>
                        <div className={styles.fieldLabel}>
                          <p>Coin Value</p>
                        </div>
                        <div className={styles.fieldInput}>
                          <input
                            // style={{ background: "white" }}
                            type="text"
                            value={
                              campaignDetails.levelsData[levelIndex]?.tasksData[
                                taskIndex
                              ]?.coinValue
                            }
                            onChange={(e) =>
                              handleTaskInputChange(
                                levelIndex,
                                taskIndex,
                                "coinValue",
                                e.target.value
                              )
                            }
                            placeholder="Enter"
                          />
                        </div>

                        <div className={styles.fieldLabel}>
                          {/* <p>Task {taskIndex + 1}</p> */}
                          <p>Task Description</p>
                        </div>
                        <div className={styles.fieldInput}>
                          <textarea
                            // style={{ background: "white" }}
                            value={
                              campaignDetails.levelsData[levelIndex]?.tasksData[
                                taskIndex
                              ]?.taskDescription
                            }
                            onChange={(e) =>
                              handleTaskInputChange(
                                levelIndex,
                                taskIndex,
                                "taskDescription",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className={styles.feildRight}>
                        <div className={styles.fieldLabel}>
                          <p>Task Type</p>
                        </div>
                        <div className={styles.fieldInput}>
                          <SelectComponent
                            // options={taskPointOptions}
                            options={taskTypes.map((task) => ({
                              label: task.title,
                              value: task.description,
                              operationId: task._id,
                              taskType: task.taskType,
                            }))}
                            onSelect={(option) =>
                              handleTaskInputChange(
                                levelIndex,
                                taskIndex,
                                "taskName",
                                option
                              )
                            }
                            // white
                          />
                        </div>

                        {campaignDetails?.levelsData[levelIndex]?.tasksData[
                          taskIndex
                        ]?.taskType === "Continuous" && (
                          <>
                            <div className={styles.fieldLabel}>
                              <p>Number Of Days</p>
                            </div>
                            <div className={styles.fieldInput}>
                              <input
                                // style={{ background: "white" }}
                                type="text"
                                value={
                                  campaignDetails.levelsData[levelIndex]
                                    ?.tasksData[taskIndex]?.numberOfDays
                                }
                                onChange={(e) =>
                                  handleTaskInputChange(
                                    levelIndex,
                                    taskIndex,
                                    "numberOfDays",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter"
                              />
                            </div>
                          </>
                        )}
                        {campaignDetails?.levelsData[levelIndex]?.tasksData[
                          taskIndex
                        ]?.taskType === "Multiple" && (
                          <>
                            <div className={styles.fieldLabel}>
                              <p>Task Count</p>
                            </div>
                            <div className={styles.fieldInput}>
                              <input
                                // style={{ background: "white" }}
                                type="text"
                                value={
                                  campaignDetails.levelsData[levelIndex]
                                    ?.tasksData[taskIndex]?.taskCount
                                }
                                onChange={(e) =>
                                  handleTaskInputChange(
                                    levelIndex,
                                    taskIndex,
                                    "taskCount",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter"
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </Stepper>
        </Spin>
      </div>
    </div>
  );
}

export default CreateCampaign;
