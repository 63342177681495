import React, { useState } from "react";
import { Modal } from "antd";
import styles from "./addDevalaya.module.css";
import { toast } from "sonner";
import { userRequest } from "../../../../requestMethod";
import SelectComponent from "../../../../components/SelectComponent";
import FileUpload from "../../../../components/FileUpload/FileUpload";

const AddDevalaya = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resetKey, setResetKey] = useState(0); // Key to force component remount

  const initialFormData = {
    templeName: "",
    description: "",
    devalayaType: "",
    profileImage: "",
    gopuramPhoto: "",
    geolocation: {
      type: "Point",
      coordinates: [],
    },
    address: {
      address1: "",
      address2: "",
      pincode: "",
      city: "",
      state: "",
      country: "",
    },
  };

  const [formData, setFormData] = useState(initialFormData);

  const showModal = () => {
    setIsModalOpen(true);
  };


  const handleChange = (e) => {
    const { name, value } = e.target;

    const regex = /^[a-zA-Z\s]*$/;

    if (name === "templeName") {
      if (!regex.test(value)) {
        toast.error("Devalaya name should contain only letters");
        return;
      }
      if (value.length > 75) {
        toast.error("Devalaya name cannot exceed 75 characters.");
        return;
      }
    }
    if (name === "lat" || name === "long") {
      const coordinates = [...formData.geolocation.coordinates];
      if (name === "lat") {
        coordinates[0] = parseFloat(value);
      } else {
        coordinates[1] = parseFloat(value);
      }

      setFormData({
        ...formData,
        geolocation: {
          ...formData.geolocation,
          coordinates,
        },
      });
    } else if (name in formData.address) {
      setFormData({
        ...formData,
        address: {
          ...formData.address,
          [name]: value,
        },
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  

  const handleOk = async () => {
    if (
      !formData.templeName ||
      !formData.description ||
      !formData.devalayaType ||
      !formData.profileImage ||
      !formData.gopuramPhoto ||
      formData.geolocation.coordinates.length !== 2 ||
      !formData.address.address1 ||
      !formData.address.address2 ||
      !formData.address.pincode ||
      !formData.address.city ||
      !formData.address.state ||
      !formData.address.country
    ) {
      toast.error("Please fill in all fields.");
      return;
    }
    setIsModalOpen(false);
    try {
      await userRequest.post(`/master/createDevalaya`, formData);
      toast.success("Devalaya created successfully");
      resetForm();
    } catch (error) {
      console.log("Error creating Devalaya:", error);
      toast.error("Failed to create Devalaya");
    }
  };

  const handleCancel = () => {
    resetForm();
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setIsModalOpen(false);
    setResetKey((prevKey) => prevKey + 1);
  };

  const option = [
    { value: "Devalaya", label: "Devalaya" },
    { value: "Statue", label: "Statue" },
    { value: "Demolished", label: "Demolished" },
    { value: "Unesco World Heritage Site", label: "Unesco World Heritage Site" },
  ];

  const handleDevalyaTypeSelect = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      devalayaType: selectedOption.value,
    }));
  };

  return (
    <>
      <button className={styles.addNewBtn} onClick={showModal}>
        Add Devalaya
      </button>
      <Modal
        visible={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        className={styles.modal}
      >
        <div className={styles.modalTitle}>
          <p>Add Devalaya</p>
        </div>
        <div className={styles.contentMain}>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Devalaya Name</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="templeName"
                value={formData.templeName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Description</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Devalaya Type</p>
            </div>
            <div className={styles.fieldInput}>
              <SelectComponent
                key={`devalayaType-${resetKey}`}
                options={option}
                onSelect={handleDevalyaTypeSelect}
              />
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Devalaya Profile Image</p>
            </div>
            <div className={styles.fieldInput}>
              <FileUpload
                key={`profileImage-${resetKey}`}
                label="Upload Media"
                onChange={(fieldName, file) =>
                  setFormData({ ...formData, profileImage: file })
                }
                fieldName="profileImage"
              />
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Gopuram Photo</p>
            </div>
            <div className={styles.fieldInput}>
              <FileUpload
                key={`gopuramPhoto-${resetKey}`}
                label="Upload Media"
                onChange={(fieldName, file) =>
                  setFormData({ ...formData, gopuramPhoto: file })
                }
                fieldName="gopuramPhoto"
              />
            </div>
          </div>
          <div className={styles.fieldContainerLat}>
            <div className={styles.itemLeft}>
              <div className={styles.fieldLabel}>
                <p>Address 1</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="address1"
                  value={formData.address.address1 || ""}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={styles.itemRight}>
              <div className={styles.fieldLabel}>
                <p>Address 2</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="address2"
                  value={formData.address.address2 || ""}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldContainerLat}>
            <div className={styles.itemLeft}>
              <div className={styles.fieldLabel}>
                <p>Pincode</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="pincode"
                  value={formData.address.pincode || ""}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={styles.itemRight}>
              <div className={styles.fieldLabel}>
                <p>City</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="city"
                  value={formData.address.city || ""}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldContainerLat}>
            <div className={styles.itemLeft}>
              <div className={styles.fieldLabel}>
                <p>State</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="state"
                  value={formData.address.state || ""}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={styles.itemRight}>
              <div className={styles.fieldLabel}>
                <p>Country</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="country"
                  value={formData.address.country || ""}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldContainerLat}>
            <div className={styles.itemLeft}>
              <div className={styles.fieldLabel}>
                <p>Latitude</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="number"
                  name="lat"
                  value={formData.geolocation.coordinates[0] || ""}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={styles.itemRight}>
              <div className={styles.fieldLabel}>
                <p>Longitude</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="number"
                  name="long"
                  value={formData.geolocation.coordinates[1] || ""}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className={styles.modalButton}>
            <button onClick={handleCancel}>Cancel</button>
            <button onClick={handleOk}>Add</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddDevalaya;
