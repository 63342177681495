import React, { useState } from 'react'
import styles from './utsavManagement.module.css'
import { useNavigate } from 'react-router-dom';
import UtsavFacilities from './UtsavFacilities/UtsavFacilities';
import KaryakramamType from './KaryakramamType/KaryakramamType';




function UtsavManagement() {

    const menuItems = [
        // { label: "Utsav Type", state: "Utsav Type" },
        { label: "Utsav Facilities", state: "Utsav Facilities" },
        // { label: "Utsav Attractions", state: "Utsav Attractions" },
        { label: "Karyakramam Type", state: "Karyakramam Type" },

      ];


    const navigate = useNavigate()
    const [activeMenuItem, setActiveMenuItem] = useState("Utsav Facilities");

    const handlePoliciesMenu = (menu) => {
      setActiveMenuItem(menu);
    };
    return (
        <div className={styles.main}>
          <div className={styles.pageHeader}>
            <div className={styles.pageHeaderLeft}>
              <p>
                <span onClick={() => navigate("/devalayamanagement")}>Master  Data <span className={styles.devalayaHeadingColor}>{">"} Utsav</span></span>
              </p>
            </div>
         
            <div className={styles.pageHeaderRight}>
          
            {/* <button className={styles.addNewBtn}
              onClick={() => navigate("/usermanagement/createroles")}
            >
              Add New
            </button> */}
        </div>
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.menuContainer}>
              {menuItems.map((item) => (
                <div
                  key={item.state}
                  className={
                    activeMenuItem === item.state
                      ? `${styles.singleMenu} ${styles.activeMenu}`
                      : styles.singleMenu
                  }
                  onClick={() => handlePoliciesMenu(item.state)}
                >
                  <p>{item.label}</p>
                </div>
              ))}
            </div>
            <div className={styles.activePage}>
              {activeMenuItem === "Utsav Facilities" &&  <UtsavFacilities/>  }
              {activeMenuItem === "Karyakramam Type" && <KaryakramamType/>}

            </div>
          </div>
        </div>
      );
}

export default UtsavManagement
