import React, { useState } from "react";
import { Modal } from "antd";
import styles from "./invitePandit.module.css";
import { toast } from "sonner";
import SelectComponent from "../../../components/SelectComponent";
import MultiSelectComponent from "../../../components/MultiSelectComponent";
import { decodedToken, userRequest } from "../../../requestMethod";

const InvitePandit = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const invitedBy = decodedToken?.user;
  const initialFormData = {
    mobile: "",
    educationType: "",
    invitedBy,
    guruName: "",
    gurukulName: "",
    languagesKnown: [],
  };

  const [formData, setFormData] = useState(initialFormData);
  const [resetKey, setResetKey] = useState(0); // Key to force component remount

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Allow only numbers and +, and + should be at the start
    if (name === "mobile") {
      // Regular expression to allow only digits and one leading +
      const regex = /^(\+)?\d*$/;
      if (!regex.test(value)) {
        return; // Ignore input if it doesn't match the allowed pattern
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOk = async () => {
    if (
      !formData.mobile ||
      !formData.educationType ||
      !formData.guruName ||
      // !formData.gurukulName,
      formData.languagesKnown.length === 0
    ) {
      toast.error("Please fill in all fields.");
      return;
    }
    try {
      await userRequest.post(`/userManagement/invitePanditFromAdmin`, formData);
      toast.success("Pandit Invited successfully");
      resetForm(); // Reset form data after successful invite
    } catch (error) {
      console.log("Error inviting Pandit:", error);
      toast.error("Failed to invite Pandit");
    }
  };

  const handleCancel = () => {
    resetForm(); // Reset form data when modal is closed
  };

  const resetForm = () => {
    setFormData(initialFormData); // Reset the form data
    setIsModalOpen(false); // Close the modal
    setResetKey((prevKey) => prevKey + 1); // Change key to force re-render
  };

  const educationOptions = [
    { value: "Home School", label: "Home School" },
    { value: "Gurukul", label: "Gurukul" },
  ];
  const languageOptions = [
    { value: "Assamese", label: "Assamese" },
    { value: "Bengali", label: "Bengali" },
    { value: "Bodo", label: "Bodo" },
    { value: "Dogri", label: "Dogri" },
    { value: "English", label: "English" },
    { value: "Gujarati", label: "Gujarati" },
    { value: "Hindi", label: "Hindi" },
    { value: "Kannada", label: "Kannada" },
    { value: "Kashmiri", label: "Kashmiri" },
    { value: "Konkani", label: "Konkani" },
    { value: "Maithili", label: "Maithili" },
    { value: "Malayalam", label: "Malayalam" },
    { value: "Manipuri", label: "Manipuri" },
    { value: "Marathi", label: "Marathi" },
    { value: "Nepali", label: "Nepali" },
    { value: "Odia", label: "Odia" },
    { value: "Punjabi", label: "Punjabi" },
    { value: "Sanskrit", label: "Sanskrit" },
    { value: "Santali", label: "Santali" },
    { value: "Sindhi", label: "Sindhi" },
    { value: "Tamil", label: "Tamil" },
    { value: "Telugu", label: "Telugu" },
  ];

  return (
    <>
      <button className={styles.addNewBtn} onClick={showModal}>
        Invite Pandit
      </button>
      <Modal
        visible={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        className={styles.modal}
      >
        <div className={styles.modalTitle}>
          <p>Invite Pandit</p>
        </div>
        <div className={styles.contentMain}>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Mobile</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Education Type</p>
            </div>
            <div className={styles.fieldInput}>
              <SelectComponent
                key={resetKey} // Use key to force re-render
                options={educationOptions}
                onSelect={(selectedOption) =>
                  setFormData({
                    ...formData,
                    educationType: selectedOption.value,
                  })
                }
                initialSelectedOption={formData?.educationType}
              />
            </div>
          </div>
          {formData?.educationType === "Gurukul" && (
            <div className={styles.fieldContainer}>
              <div className={styles.fieldLabel}>
                <p>Gurukul Name</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="gurukulName"
                  value={formData.gurukulName}
                  onChange={handleChange}
                />
              </div>
            </div>
          )}

          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Guru Name</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="guruName"
                value={formData.guruName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Languages Known</p>
            </div>
            <div className={styles.fieldInputMulti}>
              <MultiSelectComponent
                key={resetKey} // Use key to force re-render
                options={languageOptions}
                onSelect={(selectedOptions) =>
                  setFormData({
                    ...formData,
                    languagesKnown: selectedOptions.map(
                      (option) => option.value
                    ),
                  })
                }
                initialSelectedOptions={formData.languagesKnown}
              />
            </div>
          </div>
          <div className={styles.modalButton}>
            <button onClick={handleCancel}>Cancel</button>
            <button onClick={handleOk}>Invite</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default InvitePandit;

