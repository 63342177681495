import React, { useEffect, useState } from "react";
import styles from "./taskManagement.module.css";
import { useNavigate } from "react-router-dom";
import { BASE_URL, userRequest } from "../../../requestMethod.js";
import editIcon from "../../../assets/images/edit.png";
import deleteIcon from "../../../assets/images/deleteIcon.png";
import AddTask from "./AddTask/AddTask.jsx";
import EditTask from "./EditTask/EditTask.jsx";
import { toast } from "sonner";
import { Spin } from "antd";

function TaskManagement() {
  const [taskData, setTaskData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await userRequest.get(`/master/getMasterTaskType`);
      setTaskData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }finally{
      setLoading(false)
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.pageHeader}>
        <p className={styles.taskHeader}>
          <span>Task Management</span>
        </p>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.activePage}>
        <Spin spinning={loading} size="small">
          <div className={styles.tableContainerTask}>
            <table>
              <thead>
                <tr>
                  <th>Task</th>
                  <th>Description</th>
                  <th>Task Type</th>
                  <th>API Endpoint</th>
                  <th>Method</th>
                  <th>Screen Flow</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {taskData.length > 0 ? (
                  taskData.map((task, index) => (
                    <tr key={task?._id}>
                      <td>{task?.title}</td>
                      <td>{task?.description}</td>
                      <td>{task?.taskType}</td>
                      <td>{task?.apiEndpoint}</td>
                      <td>{task?.method}</td>
                      <td>{task?.screenFlow}</td>
                      <td className={styles.actionTd}>
                        <EditTask fetchData={fetchData} task={task} />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8">No data found</td>
                  </tr>
                )}
              </tbody>
            </table>
            {taskData.length > 0 && (
              <div className={styles.footer}>
                <div
                  className={styles.action}
                  style={{
                    visibility: totalPages === 1 ? "hidden" : "visible",
                  }}
                >
                  <button
                    className={`${styles.actionButton} ${
                      page === 1 ? styles.disabled : styles.activeButton
                    }`}
                    onClick={handlePrevious}
                    disabled={page === 1}
                  >
                    Previous
                  </button>
                  <button
                    className={`${styles.actionButton} ${
                      page === totalPages
                        ? styles.disabled
                        : styles.activeButton
                    }`}
                    onClick={handleNext}
                    disabled={page === totalPages}
                  >
                    Next
                  </button>
                </div>
                <div className={styles.page}>
                  <span>{currentPage}</span> <span>of</span>{" "}
                  <span>{totalPages}</span>
                </div>
              </div>
            )}
          </div>
          </Spin>
        </div>
      </div>
    </div>
  );
}

export default TaskManagement;

// function formatDate(dateString) {
//   const date = new Date(dateString);
//   const formattedDate = date.toLocaleDateString("en-GB", {
//     day: "2-digit",
//     month: "short",
//     year: "numeric",
//   });

//   return formattedDate;
// }
