import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import styles from "./style.module.css";
import { fileRequest } from "../../requestMethod";

const FileUpload = ({ label, onChange, fieldName,name }) => {
  const [fileName, setFileName] = useState("");

  // const handleFileChange = async (e) => {
  //   try {
  //     const file = e.target.files[0];
  //     const data = new FormData();
  //     data.append("file", file);
  //     const response = await fileRequest.post("/util/uploadFile", data);
  //     onChange(fieldName, response.data.url);
  //     setFileName(file.name);
  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //   }
  // };

  const handleFileChange = async (e) => {
    try {
      const file = e.target.files[0];
      const data = new FormData();
      data.append("file", file); // Include file name
  
      // Check if the uploaded file is a PDF
      if (file.type === "application/pdf") {
        data.append("Content-Type", "application/pdf"); // Set content type to application/pdf
      }
  
      const response = await fileRequest.post("/util/uploadFile", data);
      onChange(fieldName, response.data.url);
      setFileName(file.name);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  

  return (
    <div className={styles.fileInput}>
      <span className={styles.fileName}>{name? name:fileName}</span>
      <input
        type="file"
        id={`upload-${fieldName}-input`} // Use dynamic id for multiple file uploads
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <label htmlFor={`upload-${fieldName}-input`} className={styles.uploadIcon}>
        <UploadOutlined
          style={{ fontSize: "1.3rem", color: "#f68b21", cursor: "pointer" }}
        />
      </label>
    </div>
  );
};


export default FileUpload;
