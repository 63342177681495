import React from "react";
import gallery from "../../assets/images/Path1.jpg";
import "../../pages/auth/login/Login.css";
function CarouselComponent() {
  return (
    <div className="carouselDiv ">
      <div
        id="carouselExample"
        className="carousel carousel-dark slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active ">
            <img
              src={gallery}
              className="bg-secondary caroselImage"
              alt="one"
            />
          </div>
          <div className="carousel-item  ">
            <img
              src={gallery}
              className="bg-secondary caroselImage"
              alt="two"
            />
          </div>
          <div className="carousel-item ">
            <img
              src={gallery}
              className="bg-secondary caroselImage"
              alt="three"
            />
          </div>
        </div>
        <div
          className="text-center "
          style={{ color: "rgb(255, 153, 20)", marginTop: "7%" }}
        >
          <h3>
            <strong>Welcome to Tej</strong>
          </h3>
          <p className="p1">
            {" "}
            Lorem Ipsum is simply dummy text of the industry. Lorem Ipsum has
            been the industry's ever since the 1500s, when an unknown type and
            scrambled it to make a type specimen book. survived
          </p>
        </div>
        <ol className="carousel-indicators">
          <li
            data-bs-target="#carouselExample"
            data-bs-slide-to="0"
            className="active"
          ></li>
          <li data-bs-target="#carouselExample" data-bs-slide-to="1"></li>
          <li data-bs-target="#carouselExample" data-bs-slide-to="2"></li>
        </ol>
      </div>
    </div>
  );
}

export default CarouselComponent;
