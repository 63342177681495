import React, { useEffect, useState } from "react";
import styles from "./Details.module.css";
import { useNavigate, useParams } from "react-router-dom";
import image from "../../../assets/images/logo.png";
import emailIcon from "../../../assets/images/email.png";
import phoneIcon from "../../../assets/images/phone.png";
import { userRequest } from "../../../requestMethod";
import verifiedIcon from "../../../assets/images/verified.png"


function UserDetails() {
  const navigate = useNavigate();
  const { userId, userType } = useParams();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await userRequest.get(
          `/userManagement/getSingleUserManagement?userType=${userType}&id=${userId}`
        );
        setUserData(response.data.data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, [userId, userType]);

  return (
    <div className={styles.main}>
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderLeft}>
          <p>
            <span onClick={() => navigate("/samhitamanagement")}>
              User Management
            </span>{" "}
            <span className={styles.activePageStyle}>&gt; Profile Details</span>
          </p>
        </div>
        <div className={styles.pageHeaderRight}>
          <button>Verified</button>
        </div>
      </div>
      {userData && userData.basicInfoObj && (
        <div className={styles.contentContainer}>
          <div className={styles.basicInfo}>
            <div className={styles.basicInfoSectionLeft}>
              <div className={styles.profilePic}>
                <p>Basic Info</p>
                <img src={image} alt="" />
              </div>
              <div className={styles.leftBasicInfo}>
                <div className={styles.singleBasicInfo}>
                  <p>Full Name</p>
                  <p>
                    {userData?.basicInfoObj?.firstName || "NA"}{" "}
                    {userData?.basicInfoObj?.lastName || "NA"}
                  </p>
                </div>
                <div className={styles.singleBasicInfo}>
                  <p>Join Date</p>
                  <p>
                    {new Date(
                      userData.basicInfoObj.createdAt
                    ).toLocaleDateString() || "NA"}{" "}
                  </p>
                </div>
                <div className={styles.singleBasicInfo}>
                  <p>Nominated By</p>
                  <p>Tej Verma</p>
                </div>
                <div className={styles.singleBasicInfo}>
                  <p>Lived In</p>
                  <p>{userData.basicInfoObj.livedIn || "NA"}</p>
                </div>
                <div className={styles.singleBasicInfo}>
                  <div className={styles.email}>
                    <img src={emailIcon} alt="" />
                    <p>{userData?.basicInfoObj?.email  || "No email available"}</p> 
                    <img style={{"width":"7%"}} src={userData?.basicInfoObj?.emailVerified===true ? verifiedIcon:""} alt="" />
                  </div>
                  <div className={styles.phone}>
                    <img src={phoneIcon} alt="" />
                    <p>{userData.basicInfoObj.mobile || "NA"}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.basicInfoSectionRight}>
              <p>Personal Information</p>
              <div className={styles.singleBasicInfo}>
                <p>Date of Birth</p>
                <p>
                  {new Date(
                    userData.personalInfoObj.dob
                  ).toLocaleDateString() || "NA"}
                </p>
              </div>
              <div className={styles.singleBasicInfo}>
                <p>Birth Time</p>
                <p>{userData.personalInfoObj.birthTime || "NA"}</p>
              </div>
              <div className={styles.singleBasicInfo}>
                <p>Raasi</p>
                <p>{userData.personalInfoObj.raasi || "NA"}</p>
              </div>
              <div className={styles.singleBasicInfo}>
                <p>Nakshatram</p>
                <p>{userData.personalInfoObj.nakshatram || "NA"}</p>
              </div>
              <div className={styles.singleBasicInfo}>
                <p>Gothram</p>
                <p>{userData.personalInfoObj.gothram || "NA"}</p>
              </div>
              <div className={styles.singleBasicInfo}>
                <p>Relationship Status</p>
                <p>{userData.personalInfoObj.relationshipStatus || "NA"}</p>
              </div>
            </div>
          </div>
          <div className={styles.bioContent}>
            <p>Bio</p>
            <p>{userData.bio || "NA"}</p>
          </div>
          <div className={styles.personalDetails}>
            <div className={styles.abhiruchi}>
              <p>Abhiruchi</p>
              <div className={styles.abhiruchiRow}>
                {userData.abhiruchi.length > 0 ? (
                  userData.abhiruchi.map((interest, index) => (
                    <p key={index}>{interest}</p>
                  ))
                ) : (
                  <p>NA</p>
                )}
              </div>
            </div>

            <div className={styles.placesLived}>
              <p>Places Lived</p>
              <div className={styles.placesLivedSingle}>
                <p>Current City</p>
                <p>{userData.placesLived.currentCity || "NA"}</p>
              </div>
              <div className={styles.placesLivedSingle}>
                <p>Home Town</p>
                <p>{userData.placesLived.homeTown || "NA"}</p>
              </div>
              <div className={styles.placesLivedSingle}>
                <p>Other Cities</p>
                {userData.placesLived &&
                userData.placesLived.otherCity.length > 0 ? (
                  userData.placesLived.otherCity.map((city, index) => (
                    <p key={index}>{city}</p>
                  ))
                ) : (
                  <p>NA</p>
                )}
              </div>
            </div>
            <div className={styles.familyMembers}>
              <p>Family Members</p>
              {userData.familyMemberObj &&
              userData.familyMemberObj.length > 0 ? (
                userData.familyMemberObj.map((member, index) => (
                  <div className={styles.singleMember} key={index}>
                    <p>{member.name}</p>
                    <p>{member.relationship}</p>
                  </div>
                ))
              ) : (
                <span>NA</span>
              )}
            </div>

            <div className={styles.education}>
              <p>Education</p>
              {userData.educationObj.length > 0 ? (
              userData.educationObj.map((education, index) => (
                <div className={styles.educationRow} key={index}>
                  <p>{education.degree}</p>
                  <p>{education.specialization}</p>
                  <p>
                    {new Date(education.startDate).toLocaleDateString()} to{" "}
                    {new Date(education.endDate).toLocaleDateString()}
                  </p>
                </div>
              ))) : <span>NA</span>}
            </div>
          </div>
          <div className={styles.personalDetails}>
            <div className={styles.work}>
              <p>Work</p>
              {userData.workObj.length > 0 ? (
              userData.workObj.map((work, index) => (
                <div className={styles.workRow} key={index}>
                  <p>{work.workingSector}</p>
                  <p>{work.jobTitle}</p>
                  <p>{new Date(work.startDate).toLocaleDateString()}</p>
                  {/* {work.endDate && <p>End Date: {new Date(work.endDate).toLocaleDateString()}</p>} */}
                </div>
              )) ) : <span>NA</span>}
            </div>
            <div className={styles.daiva}>
              <div className={styles.singleDaivaRow} >

                <p>Ishta Daiva</p>
               <div className={styles.singleDaivaContainer}> 
                {userData.ishtaDaivaArr.length > 0 ? (
                userData.ishtaDaivaArr.map((daiva, index) => (
                  <div className={styles.singleDaiva} key={index}>
                    <img src={daiva.daivaPic} alt="" />
                    <p>{daiva.daivaName}</p>
                  </div>
                )) ) : <span>NA</span>}
                </div>
              </div>
              <div className={styles.singleDaivaRow}>
                <p>Grama Devata</p>
                <div className={styles.singleDaivaContainer}> 
                {userData.gramaDevataArr.length > 0 ? (
                userData.gramaDevataArr.map((daiva, index) => (
                  <div className={styles.singleDaiva} key={index}>
                    <img src={daiva.profileImage} alt="" />
                    <p>{daiva.deityName}</p>
                  </div>
                )) ) : <span>NA</span>}
                </div>
              </div>
              <div className={styles.singleDaivaRow}>
                <p>Kula Dhaivam</p>
                <div className={styles.singleDaivaContainer}> 
                {userData.kulaDhaivamArr.length > 0 ? (
                userData.kulaDhaivamArr.map((daiva, index) => (
                  <div className={styles.singleDaiva} key={index}>
                    <img src={daiva.profileImage} alt="" />
                    <p>{daiva.deityName}</p>
                  </div>
                )) ) : <span>NA</span>}
                </div>
              </div>
            </div>
            <div className={styles.assistedBy}>
              <p>Assisted by</p>
              <div className={styles.assistedByRow}>
                <div className={styles.assistedByProfile}>
                  <img src={image} alt="" />
                </div>
                <div className={styles.assistedByDetails}>
                  <p>{userData?.assistedByObj[0]?.firstName || "NA"}</p>
                  <p>{userData?.assistedByObj[0]?.mobile || "NA"}</p>
                </div>
              </div>
              <div className={styles.assistedByRowTwo}>
                <div className={styles.assistedByDetailsTwo}>
                  <img src={emailIcon} alt="" />
                  <p>{userData.assistedByObj[0]?.email || "NA"}</p>
                </div>
                <div className={styles.assistedByDetailsTwo}>
                  <img src={phoneIcon} alt="" />
                  <p>{userData.assistedByObj[0]?.mobile || "NA"}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserDetails;
