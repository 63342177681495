import React, { useState } from "react";
import styles from "../CreateTejPoints/createTejPoints.module.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { userRequest } from "../../../../requestMethod";
import { toast } from "sonner";
import { useLocation, useNavigate } from "react-router-dom";

function EditTejPoints() {
  const navigate = useNavigate();
  const location = useLocation();
  const tejPoint = location.state && location.state.tejPoint;
  const initialFormData = {
    country: tejPoint?.country,
    state: tejPoint?.state,
    loginPoint: tejPoint?.loginPoint,
    templePoint: tejPoint?.templePoint,
    referPoint: tejPoint?.referPoint,
    utsavPoint: tejPoint?.utsavPoint,
    bookingPoint: tejPoint?.bookingPoint,
    completeKycPoint: tejPoint?.completeKycPoint,
  };

  const [formData, setFormData] = useState(initialFormData);

  const selectCountry = (val) => {
    setFormData({
      ...formData,
      country: val,
      state: "", // Reset state when country changes
    });
  };

  const selectState = (val) => {
    setFormData({
      ...formData,
      state: val,
    });
  };

  const saveData = async (id) => {
    try {
      await userRequest.put(`/tejPoints/updateTejPoints/${id}`, formData);
      setFormData(initialFormData);
      navigate('/svarnamanagement')
      toast.success("Updated successfully!");
    } catch (error) {
      console.error("Error saving data:", error.message);
      alert("Failed to save data. Please try again later.");
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderLeft}>
          <p>
            <span onClick={() => navigate("/svarnamanagement")}>
              Svarna Management
            </span>{" "}
            <span className={styles.activePageStyle}>&gt; Edit Tej Points</span>
          </p>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Country</p>
            </div>
            <div className={styles.fieldInput}>
              <CountryDropdown
                value={formData.country}
                onChange={(val) => selectCountry(val)}
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>State</p>
            </div>
            <div className={styles.fieldInput}>
              <RegionDropdown
                country={formData.country}
                value={formData.state}
                onChange={(val) => selectState(val)}
              />
            </div>
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Login Point</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={formData.loginPoint}
                onChange={(e) =>
                  setFormData({ ...formData, loginPoint: e.target.value })
                }
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Add Temple Point</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={formData.templePoint}
                onChange={(e) =>
                  setFormData({ ...formData, templePoint: e.target.value })
                }
              />
            </div>
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Refer Point</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={formData.referPoint}
                onChange={(e) =>
                  setFormData({ ...formData, referPoint: e.target.value })
                }
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Create Utsav Point</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={formData.utsavPoint}
                onChange={(e) =>
                  setFormData({ ...formData, utsavPoint: e.target.value })
                }
              />
            </div>
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Booking Point</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={formData.bookingPoint}
                onChange={(e) =>
                  setFormData({ ...formData, bookingPoint: e.target.value })
                }
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Complete KYC Point</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={formData.completeKycPoint}
                onChange={(e) =>
                  setFormData({ ...formData, completeKycPoint: e.target.value })
                }
              />
            </div>
          </div>
        </div>

        <div className={styles.footer}>
          <button>Discard</button>
          <button onClick={() => saveData(tejPoint?._id)}>Save</button>
        </div>
      </div>
    </div>
  );
}

export default EditTejPoints;
