import React, { useState } from "react";
import styles from "./RolesAndPermissions.module.css";
import { useNavigate } from "react-router-dom";
import Roles from "../RolesAndPermissionsManagement/Roles/Roles";

function RolesAndPermissionsManagement() {
  const menuItems = [
    { label: "All", state: "All" },
  ];

  const navigate = useNavigate();
  const [activeMenuItem, setActiveMenuItem] = useState("All");

  const handlePoliciesMenu = (menu) => {
    setActiveMenuItem(menu);
  };
  return (
    <div className={styles.main}>
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderLeft}>
          <p className={styles.campaignHeader}>
            <span onClick={() => navigate("/rolesandpermissions")}>
              Roles And Permissions Management
            </span>
          </p>
        </div>
        <div className={styles.pageHeaderRight}>
          {activeMenuItem === "All" && (
            <button className={styles.addNewBtn}
              onClick={() => navigate("/rolesandpermissions/createroles")}
            >
              Add New
            </button>
          ) }
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.menuContainer}>
          {menuItems.map((item) => (
            <div
              key={item.state}
              className={
                activeMenuItem === item.state
                  ? `${styles.singleMenu} ${styles.activeMenu}`
                  : styles.singleMenu
              }
              onClick={() => handlePoliciesMenu(item.state)}
            >
              <p>{item.label}</p>
            </div>
          ))}
        </div>
        <div className={styles.activePage}>
          {activeMenuItem === "All" && <Roles />}
        </div>
      </div>
    </div>
  );
}

export default RolesAndPermissionsManagement;
