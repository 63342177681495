import React from "react";
import styles from "./viewKYCPrice.module.css";

function Coupon() {
  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableMain}>
        <table>
          <thead>
            <tr>
              <th>S.no.</th>
              <th>Country</th>
              <th>State</th>
              <th>Coupon Name</th>
              <th>Coupon Type</th>
              <th>Coupon Amount</th>
              <th>Final Amount</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Details</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>ABC</td>
              <td>ABC</td>
              <td>ABC</td>
              <td>ABC</td>
              <td>ABC</td>
              <td>ABC</td>
              <td>ABC</td>
              <td>ABC</td>
              <td>ABC</td>
              <td>ABC</td>
              <td>ABC</td>
            </tr>
            <tr>
              <td>ABC</td>
              <td>ABC</td>
              <td>ABC</td>
              <td>ABC</td>
              <td>ABC</td>
              <td>ABC</td>
              <td>ABC</td>
              <td>ABC</td>
              <td>ABC</td>
              <td>ABC</td>
              <td>ABC</td>
            </tr>
            <tr>
              <td>ABC</td>
              <td>ABC</td>
              <td>ABC</td>
              <td>ABC</td>
              <td>ABC</td>
              <td>ABC</td>
              <td>ABC</td>
              <td>ABC</td>
              <td>ABC</td>
              <td>ABC</td>
              <td>ABC</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={styles.footer}>
        <div className={styles.action}>
          <div className={`${styles.actionButton} ${styles.activeButton}`}>
            Previous
          </div>
          <div className={styles.actionButton}>Next</div>
        </div>
        <div className={styles.page}>
          <span>1</span> <span>of</span> <span>20</span>
        </div>
      </div>
    </div>
  );
}

export default Coupon;
