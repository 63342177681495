import React, { useEffect, useState } from "react";
import styles from "./userActivity.module.css";
import { useNavigate } from "react-router-dom";
import { userRequest } from "../../requestMethod";
import { Spin } from "antd";
import UserActivityFilter from "./UserActivityFilter";
import axios from "axios";

function UserActivityManagement() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({ firstName: ""});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = (filterData) => {
    setFilters(filterData);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    fetchData();
  }, [searchQuery,filters]);

  const fetchData = async () => {
    try {
      const { firstName } = filters;
      const response = await userRequest.get(
        `/log/getAllUserActivityLog?page=${page}&firstName=${firstName}`
      );
      // const response = await axios.get(
      //   `http://localhost:8080/api/log/getAllUserActivityLog?page=${page}&firstName=${firstName}`
      // );
      setUserData(response.data.activityLogs);
      setTotalPages(response.data.pagination.totalPages);
      setCurrentPage(response.data.pagination.page);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getStatusText = (statusCode) => {
    switch (statusCode) {
      case 100:
        return "Continue";
      case 101:
        return "Switching Protocols";
      case 102:
        return "Processing";
      case 200:
        return "Success";
      case 201:
        return "Created";
      case 202:
        return "Accepted";
      case 203:
        return "Non-Authoritative Information";
      case 204:
        return "No Content";
      case 205:
        return "Reset Content";
      case 206:
        return "Partial Content";
      case 207:
        return "Multi-Status";
      case 208:
        return "Already Reported";
      case 226:
        return "IM Used";
      case 300:
        return "Multiple Choices";
      case 301:
        return "Moved Permanently";
      case 302:
        return "Found";
      case 303:
        return "See Other";
      case 304:
        return "Success";
      case 305:
        return "Use Proxy";
      case 307:
        return "Temporary Redirect";
      case 308:
        return "Permanent Redirect";
      case 400:
        return "Bad Request";
      case 401:
        return "Unauthorized";
      case 402:
        return "Payment Required";
      case 403:
        return "Forbidden";
      case 404:
        return "Not Found";
      case 405:
        return "Method Not Allowed";
      case 406:
        return "Not Acceptable";
      case 407:
        return "Proxy Authentication Required";
      case 408:
        return "Request Timeout";
      case 409:
        return "Conflict";
      case 410:
        return "Gone";
      case 411:
        return "Length Required";
      case 412:
        return "Precondition Failed";
      case 413:
        return "Payload Too Large";
      case 414:
        return "URI Too Long";
      case 415:
        return "Unsupported Media Type";
      case 416:
        return "Range Not Satisfiable";
      case 417:
        return "Expectation Failed";
      case 418:
        return "I'm a teapot";
      case 421:
        return "Misdirected Request";
      case 422:
        return "Unprocessable Entity";
      case 423:
        return "Locked";
      case 424:
        return "Failed Dependency";
      case 425:
        return "Too Early";
      case 426:
        return "Upgrade Required";
      case 428:
        return "Precondition Required";
      case 429:
        return "Too Many Requests";
      case 431:
        return "Request Header Fields Too Large";
      case 451:
        return "Unavailable For Legal Reasons";
      case 500:
        return "Internal Server Error";
      case 501:
        return "Not Implemented";
      case 502:
        return "Bad Gateway";
      case 503:
        return "Service Unavailable";
      case 504:
        return "Gateway Timeout";
      case 505:
        return "HTTP Version Not Supported";
      case 506:
        return "Variant Also Negotiates";
      case 507:
        return "Insufficient Storage";
      case 508:
        return "Loop Detected";
      case 510:
        return "Not Extended";
      case 511:
        return "Network Authentication Required";
      default:
        return "Unknown Status";
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderLeft}>
          <p className={styles.userActivityHeader}>
            <span onClick={() => navigate("/useractivitylog")}>
              User Activity Log
            </span>
          </p>
        </div>
        <div className={styles.pageHeaderRight}></div>
      </div>
      <Spin spinning={loading} size="small">
      <div className={styles.contentContainer}>
        <div className={styles.userActivityTable}>
        <div className={styles.filterExport}>
         
           
            <div className={styles.filterDiv}>
              <span>
                <p onClick={showModal} >
                  Filter
                </p>
              </span>
            </div>
          </div>

          <table>
            <thead>
              <tr>
                <th>S. No.</th>
                <th>User Name</th>
                <th>End Point</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {userData.length > 0 ? (
                userData.map((user, index) => (
                  <tr key={user._id}>
                    <td>{(page - 1) * 10 + index + 1}</td>
                    <td>{user.userId != null ? user.userName : "Admin"}</td>

                    <td>{user.url}</td>
                    <td>{getStatusText(user.status)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No data found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {userData.length > 0 && (
          <div className={styles.footer}>
            <div
              className={styles.action}
              style={{ visibility: totalPages === 1 ? "hidden" : "visible" }}
            >
              <button
                className={`${styles.actionButton} ${
                  page === 1 ? styles.disabled : styles.activeButton
                }`}
                onClick={handlePrevious}
                disabled={page === 1}
              >
                Previous
              </button>
              <button
                className={`${styles.actionButton} ${
                  page === totalPages ? styles.disabled : styles.activeButton
                }`}
                onClick={handleNext}
                disabled={page === totalPages}
              >
                Next
              </button>
            </div>
            <div className={styles.page}>
              <span>{currentPage}</span> <span>of</span>{" "}
              <span>{totalPages}</span>
            </div>
          </div>
        )}
      </div>
      </Spin>
      <UserActivityFilter
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </div>
  );
}

export default UserActivityManagement;
