import React, { useEffect, useState } from "react";
import styles from "./viewKYCPrice.module.css";
import { userRequest } from "../../../../requestMethod";
import { useNavigate } from "react-router-dom";

function KYCAmount({ kycData, setKycData, country, state }) {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [country, state, page]);

  const fetchData = async () => {
    try {
      const response = await userRequest.get(
        `admin/adminPanditKycRoutes/getAdminKycPrice?country=${country}&state=${state}&page=${page}`
      );
      setKycData(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
      setCurrentPage(response.data.pagination.page);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableMain}>
      <table>
        <thead>
          <tr>
            <th>S.no.</th>
            <th>Country</th>
            <th>State</th>
            <th>KYC Price</th>
            <th>Discount Type</th>
            <th>Discount Amount</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {kycData.length > 0 ? (
            kycData.map((kyc, index) => (
              <tr key={kyc?._id}>
                <td>{index + 1}</td>
                <td>{kyc?.country}</td>
                <td>{kyc?.state}</td>
                <td>{kyc?.kycPrice}</td>
                <td>{kyc?.DiscountType}</td>
                <td>{kyc?.discountAmount}</td>
                <td>{formatDate(kyc?.startDate)}</td>
                <td>{formatDate(kyc?.expiryDate)}</td>
                <td
                  className={styles.tableviewDetails}
                  onClick={() =>
                    navigate(
                      `/panditkycmanagement/viewkycpricedetails/${kyc._id}`
                    )
                  }
                >
                  View
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="10">No data found</td>
            </tr>
          )}
        </tbody>
      </table>
      </div>
      {kycData.length > 0 ? (
          <div className={styles.footer}>
          <div
            className={styles.action}
            style={{ visibility: totalPages === 1 ? "hidden" : "visible" }}
          >
            <button
              className={`${styles.actionButton} ${
                page === 1 ? styles.disabled : styles.activeButton
              }`}
              onClick={handlePrevious}
              disabled={page === 1}
            >
              Previous
            </button>
            <button
              className={`${styles.actionButton} ${
                page === totalPages ? styles.disabled : styles.activeButton
              }`}
              onClick={handleNext}
              disabled={page === totalPages}
            >
              Next
            </button>
          </div>
          <div className={styles.page}>
            <span>{currentPage}</span> <span>of</span>{" "}
            <span>{totalPages}</span>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default KYCAmount;

function formatDate(dateString) {
  // Convert dateString to Date object
  const date = new Date(dateString);

  // Format the date using toLocaleDateString method
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  return formattedDate;
}
