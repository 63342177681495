// import React, { useEffect, useState } from "react";
// import styles from "./setKYC.module.css";
// import SelectComponent from "../../../../components/SelectComponent";
// import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
// import { userRequest } from "../../../../requestMethod";
// import { toast } from "sonner";
// import { useParams } from "react-router-dom";

// function KYCPrice({ formData, setFormData, setEditKYC }) {
//   const [selectedDiscountType, setSelectedDiscountType] = useState(null);
//   const [selectedTaxType, setSelectedTaxType] = useState(null);
//   const [kycPrice, setKycPrice] = useState(formData.kycPrice || "");
//   const [discountType, setDiscountType] = useState(formData.DiscountType || "");
//   const [discountAmount, setDiscountAmount] = useState(formData.discountAmount || "");
//   const [discountPercent, setDiscountPercent] = useState(formData.discountPercent || "");
//   const [taxType, setTaxType] = useState(formData.taxType || "");
//   const [taxPercentage, setTaxPercentage] = useState(formData.taxPercentage || "");
  
//   const { id } = useParams();
//   const [kycPriceData, setkycPriceData] = useState(null);

//   useEffect(() => {
//     if (id) {
//       fetchData();
//       setEditKYC(true);
//     }
//   }, [id]);

//   useEffect(() => {
//     calculateFinalAmount();
//   }, [kycPrice, discountType, discountAmount, taxType, taxPercentage]);

//   const fetchData = async () => {
//     try {
//       const response = await userRequest.get(
//         `/admin/adminPanditKycRoutes/getSingleAdminKycPrice/${id}`
//       );
//       const data = response.data.data;
//       setkycPriceData(data);
//       setFormData({
//         country: data.country || "",
//         state: data.state || "",
//         kycPrice: data.kycPrice || "",
//         DiscountType: data.DiscountType || "",
//         discountPercent: data.discountPercent || "",
//         discountAmount: data.discountAmount || "",
//         finalAmount: data.finalAmount || "",
//         taxType: data.taxType || "",
//         taxPercentage: data.taxPercentage || "",
//         finalTaxAmount: data.finalTaxAmount || "",
//         startDate: data.startDate
//           ? new Date(data.startDate).toISOString().split("T")[0]
//           : "", // Convert to YYYY-MM-DD format
//         expiryDate: data.expiryDate
//           ? new Date(data.expiryDate).toISOString().split("T")[0]
//           : "", // Convert to YYYY-MM-DD format
//       });
//       setKycPrice(data.kycPrice || "");
//       setDiscountType(data.DiscountType || "");
//       setDiscountAmount(data.discountAmount || "");
//       setDiscountPercent(data.discountPercent || "");
//       setTaxType(data.taxType || "");
//       setTaxPercentage(data.taxPercentage || "");
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   const selectCountry = (val) => {
//     setFormData({
//       ...formData,
//       country: val,
//       state: "", // Reset state when country changes
//     });
//   };

//   const selectState = (val) => {
//     setFormData({
//       ...formData,
//       state: val,
//     });
//   };

//   const discountTypeOptions = [
//     { value: "percentage", label: "Percentage" },
//     { value: "fixed", label: "Fixed" },
//   ];

//   const taxTypeOptions = [
//     { value: "gst", label: "GST" },
//     { value: "vat", label: "VAT" },
//   ];

//   const handleKycPriceChange = (e) => {
//     const newKycPrice = e.target.value;
//     setKycPrice(newKycPrice);
//     setFormData({
//       ...formData,
//       kycPrice: newKycPrice,
//     });
//   };

//   const handleDiscountTypeChange = (selectedOption) => {
//     setSelectedDiscountType(selectedOption);
//     setDiscountType(selectedOption.value);
//     setFormData({
//       ...formData,
//       DiscountType: selectedOption.value,
//       discountAmount: "", // Reset discount amount when changing discount type
//     });
//   };

//   const handleDiscountAmountChange = (e) => {
//     const newDiscountAmount = e.target.value;
//     setDiscountAmount(newDiscountAmount);
//     setFormData({
//       ...formData,
//       discountAmount: newDiscountAmount,
//     });
//   };
//   const handleDiscountPercentChange = (e) => {
//     const newDiscountPercent = e.target.value;
//     setDiscountPercent(newDiscountPercent);
//     setFormData({
//       ...formData,
//       discountPercent: newDiscountPercent,
//     });
//   };

//   const handleTaxTypeChange = (selectedOption) => {
//     setSelectedTaxType(selectedOption);
//     setTaxType(selectedOption.value);
//     setFormData({
//       ...formData,
//       taxType: selectedOption.value,
//     });
//   };

//   const handleTaxPercentageChange = (e) => {
//     const newTaxPercentage = e.target.value;
//     setTaxPercentage(newTaxPercentage);
//     setFormData({
//       ...formData,
//       taxPercentage: newTaxPercentage,
//     });
//   };

//   const calculateFinalAmount = () => {
//     let finalAmount = parseFloat(kycPrice) || 0;
//     let finalTaxAmount = 0;

//     if (discountType === "percentage" && discountAmount) {
//       finalAmount -= (parseFloat(discountAmount) / 100) * finalAmount;
//     } else if (discountType === "fixed" && discountAmount) {
//       finalAmount -= parseFloat(discountAmount);
//     }

//     if (taxType && taxPercentage) {
//       finalTaxAmount = (parseFloat(taxPercentage) / 100) * finalAmount;
//     }

//     const totalAmountWithTax = finalAmount + finalTaxAmount;

//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       finalAmount: totalAmountWithTax.toFixed(2),
//       finalTaxAmount: finalTaxAmount.toFixed(2),
//     }));
//   };

//   return (
//     <div className={styles.tableContainer}>
//       <div className={styles.fieldContainer}>
//         <div className={styles.feildLeft}>
//           <div className={styles.fieldLabel}>
//             <p>Country</p>
//           </div>
//           <div className={styles.fieldInput}>
//             <CountryDropdown
//               value={formData.country}
//               onChange={(val) => selectCountry(val)}
//             />
//           </div>
//         </div>
//         <div className={styles.feildRight}>
//           <div className={styles.fieldLabel}>
//             <p>State</p>
//           </div>
//           <div className={styles.fieldInput}>
//             <RegionDropdown
//               country={formData.country}
//               value={formData.state}
//               onChange={(val) => selectState(val)}
//             />
//           </div>
//         </div>
//       </div>
//       <div className={styles.fieldContainer}>
//         <div className={styles.feildLeft}>
//           <div className={styles.fieldLabel}>
//             <p>KYC Price</p>
//           </div>
//           <div className={styles.fieldInput}>
//             <input
//               type="text"
//               value={kycPrice}
//               onChange={handleKycPriceChange}
//             />
//           </div>
//         </div>
//       </div>
//       <div className={styles.fieldContainer}>
//         <div className={styles.feildLeft}>
//           <div className={styles.fieldLabel}>
//             <p>Discount Type</p>
//           </div>
//           <div className={styles.fieldInput}>
//             <SelectComponent
//               options={discountTypeOptions}
//               onSelect={handleDiscountTypeChange}
//               initialSelectedOption={formData?.DiscountType}
//             />
//           </div>
//         </div>
//         <div className={styles.feildRight} style={{display:selectedDiscountType?.value === "percentage" ? "block" : "none" }}>
//           <div className={styles.fieldLabel}>
//             <p>Discount Type</p>
//           </div>
//           <div className={styles.fieldInput}>
//           <input
//               type="text"
//               value={discountAmount}
//               onChange={handleDiscountPercentChange}
//               placeholder="Percentage"
//             />
//           </div>
//         </div>
//       </div>
//       <div className={styles.fieldContainer}>
//         <div className={styles.feildLeft}>
//           <div className={styles.fieldLabel}>
//             <p>Discount Amount</p>
//           </div>
//           <div className={styles.fieldInput}>
//             <input
//               type="text"
//               value={discountAmount}
//               onChange={handleDiscountAmountChange}
//               placeholder={
//                 selectedDiscountType?.value === "percentage"
//                   ? "Percentage"
//                   : "Amount"
//               }
//             />
//           </div>
//         </div>
//         <div className={styles.feildRight}>
//           <div className={styles.fieldLabel}>
//             <p>Final Amount</p>
//           </div>
//           <div className={styles.fieldInput}>
//             <input type="text" value={formData.finalAmount} readOnly />
//           </div>
//         </div>
//       </div>
//       <div className={styles.fieldContainer}>
//         <div className={styles.feildLeft}>
//           <div className={styles.fieldLabel}>
//             <p>Tax Type</p>
//           </div>
//           <div className={styles.fieldInput}>
//             <SelectComponent
//               options={taxTypeOptions}
//               onSelect={handleTaxTypeChange}
//               initialSelectedOption={formData?.taxType}
//             />
//           </div>
//         </div>
//         <div className={styles.feildRight}>
//           <div className={styles.fieldLabel}>
//             <p>Tax Percentage</p>
//           </div>
//           <div className={styles.fieldInput}>
//             <input
//               type="text"
//               value={taxPercentage}
//               onChange={handleTaxPercentageChange}
//             />
//           </div>
//         </div>
//       </div>
//       <div className={styles.fieldContainer}>
//         <div className={styles.feildLeft}>
//           <div className={styles.fieldLabel}>
//             <p>Final Tax Amount</p>
//           </div>
//           <div className={styles.fieldInput}>
//             <input type="text" value={formData.finalTaxAmount} readOnly />
//           </div>
//         </div>
//       </div>
//       <div className={styles.fieldContainer}>
//         <div className={styles.feildLeft}>
//           <div className={styles.fieldLabel}>
//             <p>Duration</p>
//           </div>
//           <div className={styles.fieldDate}>
//             <input
//               type="date"
//               placeholder="YYYY-MM-DD"
//               value={formData.startDate}
//               onChange={(e) =>
//                 setFormData({ ...formData, startDate: e.target.value })
//               }
//             />
//             <input
//               type="date"
//               placeholder="YYYY-MM-DD"
//               value={formData.expiryDate}
//               onChange={(e) =>
//                 setFormData({ ...formData, expiryDate: e.target.value })
//               }
//             />
//           </div>
//         </div>
//       </div>
//       <div className={styles.footer}></div>
//     </div>
//   );
// }

// export default KYCPrice;




import React, { useEffect, useState } from "react";
import styles from "./setKYC.module.css";
import SelectComponent from "../../../../components/SelectComponent";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { userRequest } from "../../../../requestMethod";
import { toast } from "sonner";
import { useParams } from "react-router-dom";

function KYCPrice({ formData, setFormData, setEditKYC }) {
  const [selectedDiscountType, setSelectedDiscountType] = useState(null);
  const [selectedTaxType, setSelectedTaxType] = useState(null);
  const [kycPrice, setKycPrice] = useState(formData.kycPrice || "");
  const [discountType, setDiscountType] = useState(formData.DiscountType || "");
  const [discountAmount, setDiscountAmount] = useState(formData.discountAmount || "");
  const [discountPercent, setDiscountPercent] = useState(formData.discountPercent || "");
  const [taxType, setTaxType] = useState(formData.taxType || "");
  const [taxPercentage, setTaxPercentage] = useState(formData.taxPercentage || "");
  
  const { id } = useParams();
  const [kycPriceData, setkycPriceData] = useState(null);

  useEffect(() => {
    if (id) {
      fetchData();
      setEditKYC(true);
    } else {
      // Trigger calculation on initial load for new data
      calculateFinalAmount();
    }
  }, [id]);

  useEffect(() => {
    calculateFinalAmount();
  }, [kycPrice, discountType, discountAmount, discountPercent, taxType, taxPercentage]);

  const fetchData = async () => {
    try {
      const response = await userRequest.get(
        `/admin/adminPanditKycRoutes/getSingleAdminKycPrice/${id}`
      );
      const data = response.data.data;
      setkycPriceData(data);
      setFormData({
        country: data.country || "",
        state: data.state || "",
        kycPrice: data.kycPrice || "",
        DiscountType: data.DiscountType || "",
        discountPercent: data.discountPercent || "",
        discountAmount: data.discountAmount || "",
        finalAmount: data.finalAmount || "",
        taxType: data.taxType || "",
        taxPercentage: data.taxPercentage || "",
        finalTaxAmount: data.finalTaxAmount || "",
        startDate: data.startDate
          ? new Date(data.startDate).toISOString().split("T")[0]
          : "", // Convert to YYYY-MM-DD format
        expiryDate: data.expiryDate
          ? new Date(data.expiryDate).toISOString().split("T")[0]
          : "", // Convert to YYYY-MM-DD format
      });
      setKycPrice(data.kycPrice || "");
      setDiscountType(data.DiscountType || "");
      setDiscountAmount(data.discountAmount || "");
      setDiscountPercent(data.discountPercent || "");
      setTaxType(data.taxType || "");
      setTaxPercentage(data.taxPercentage || "");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const selectCountry = (val) => {
    setFormData({
      ...formData,
      country: val,
      state: "", // Reset state when country changes
    });
  };

  const selectState = (val) => {
    setFormData({
      ...formData,
      state: val,
    });
  };

  const discountTypeOptions = [
    { value: "percentage", label: "Percentage" },
    { value: "fixed", label: "Fixed" },
  ];

  const taxTypeOptions = [
    { value: "gst", label: "GST" },
    { value: "vat", label: "VAT" },
  ];

  const handleKycPriceChange = (e) => {
    const newKycPrice = e.target.value;
    setKycPrice(newKycPrice);
    setFormData({
      ...formData,
      kycPrice: newKycPrice,
    });
  };

  const handleDiscountTypeChange = (selectedOption) => {
    setSelectedDiscountType(selectedOption);
    setDiscountType(selectedOption.value);
    setFormData({
      ...formData,
      DiscountType: selectedOption.value,
      discountAmount: "", // Reset discount amount when changing discount type
      discountPercent: "" // Reset discount percent when changing discount type
    });
  };

  const handleDiscountAmountChange = (e) => {
    const newDiscountAmount = e.target.value;
    setDiscountAmount(newDiscountAmount);
    setFormData({
      ...formData,
      discountAmount: newDiscountAmount,
    });
  };
  
  const handleDiscountPercentChange = (e) => {
    const newDiscountPercent = e.target.value;
    setDiscountPercent(newDiscountPercent);
    if (kycPrice && newDiscountPercent) {
      const calculatedDiscountAmount = (parseFloat(newDiscountPercent) / 100) * parseFloat(kycPrice);
      setDiscountAmount(calculatedDiscountAmount.toFixed(2));
      setFormData({
        ...formData,
        discountPercent: newDiscountPercent,
        discountAmount: calculatedDiscountAmount.toFixed(2),
      });
    } else {
      setDiscountAmount("");
      setFormData({
        ...formData,
        discountPercent: newDiscountPercent,
        discountAmount: "",
      });
    }
  };

  const handleTaxTypeChange = (selectedOption) => {
    setSelectedTaxType(selectedOption);
    setTaxType(selectedOption.value);
    setFormData({
      ...formData,
      taxType: selectedOption.value,
    });
  };

  const handleTaxPercentageChange = (e) => {
    const newTaxPercentage = e.target.value;
    setTaxPercentage(newTaxPercentage);
    setFormData({
      ...formData,
      taxPercentage: newTaxPercentage,
    });
  };

  const calculateFinalAmount = () => {
    let finalAmount = parseFloat(kycPrice) || 0;
    let finalTaxAmount = 0;

    if (discountType === "percentage" && discountPercent) {
      const discountValue = (parseFloat(discountPercent) / 100) * kycPrice;
      finalAmount -= discountValue;
    } else if (discountType === "fixed" && discountAmount) {
      finalAmount -= parseFloat(discountAmount);
    }

    if (taxType && taxPercentage) {
      finalTaxAmount = (parseFloat(taxPercentage) / 100) * kycPrice;
    }

    const totalAmountWithTax = finalAmount + finalTaxAmount;

    setFormData((prevFormData) => ({
      ...prevFormData,
      finalAmount: totalAmountWithTax.toFixed(2),
      finalTaxAmount: finalTaxAmount.toFixed(2),
    }));
  };

  return (
    <div className={styles.tableContainer}>
      <div className={styles.fieldContainer}>
        <div className={styles.feildLeft}>
          <div className={styles.fieldLabel}>
            <p>Country</p>
          </div>
          <div className={styles.fieldInput}>
            <CountryDropdown
              value={formData.country}
              onChange={(val) => selectCountry(val)}
            />
          </div>
        </div>
        <div className={styles.feildRight}>
          <div className={styles.fieldLabel}>
            <p>State</p>
          </div>
          <div className={styles.fieldInput}>
            <RegionDropdown
              country={formData.country}
              value={formData.state}
              onChange={(val) => selectState(val)}
            />
          </div>
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.feildLeft}>
          <div className={styles.fieldLabel}>
            <p>KYC Price</p>
          </div>
          <div className={styles.fieldInput}>
            <input
              type="text"
              value={kycPrice}
              onChange={handleKycPriceChange}
            />
          </div>
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.feildLeft}>
          <div className={styles.fieldLabel}>
            <p>Discount Type</p>
          </div>
          <div className={styles.fieldInput}>
            <SelectComponent
              options={discountTypeOptions}
              onSelect={handleDiscountTypeChange}
              initialSelectedOption={formData?.DiscountType}
            />
          </div>
        </div>
        {selectedDiscountType?.value === "percentage" && (
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Discount Percentage</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={discountPercent}
                onChange={handleDiscountPercentChange}
                placeholder="Percentage"
              />
            </div>
          </div>
        )}
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.feildLeft}>
          <div className={styles.fieldLabel}>
            <p>Discount Amount</p>
          </div>
          <div className={styles.fieldInput}>
            <input
              type="text"
              value={discountAmount}
              onChange={handleDiscountAmountChange}
              placeholder={
                selectedDiscountType?.value === "percentage"
                  ? "Auto-calculated"
                  : "Amount"
              }
              readOnly={selectedDiscountType?.value === "percentage"}
            />
          </div>
        </div>
        <div className={styles.feildRight}>
          <div className={styles.fieldLabel}>
            <p>Final Amount</p>
          </div>
          <div className={styles.fieldInput}>
            <input type="text" value={formData.finalAmount} readOnly />
          </div>
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.feildLeft}>
          <div className={styles.fieldLabel}>
            <p>Tax Type</p>
          </div>
          <div className={styles.fieldInput}>
            <SelectComponent
              options={taxTypeOptions}
              onSelect={handleTaxTypeChange}
              initialSelectedOption={formData?.taxType}
            />
          </div>
        </div>
        <div className={styles.feildRight}>
          <div className={styles.fieldLabel}>
            <p>Tax Percentage</p>
          </div>
          <div className={styles.fieldInput}>
            <input
              type="text"
              value={taxPercentage}
              onChange={handleTaxPercentageChange}
            />
          </div>
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.feildLeft}>
          <div className={styles.fieldLabel}>
            <p>Final Tax Amount</p>
          </div>
          <div className={styles.fieldInput}>
            <input type="text" value={formData.finalTaxAmount} readOnly />
          </div>
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.feildLeft}>
          <div className={styles.fieldLabel}>
            <p>Duration</p>
          </div>
          <div className={styles.fieldDate}>
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              value={formData.startDate}
              onChange={(e) =>
                setFormData({ ...formData, startDate: e.target.value })
              }
            />
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              value={formData.expiryDate}
              onChange={(e) =>
                setFormData({ ...formData, expiryDate: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className={styles.footer}></div>
    </div>
  );
}

export default KYCPrice;
