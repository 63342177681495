import { useLocation } from "react-router-dom";
import Navbar from "./pages/Home/Navbar";
import RouterPage from "./components/RouterPage";
import { useState } from "react";
import { useSelector } from "react-redux";

function App() {

  const location = useLocation();
  // const iLoginPage = location.pathname === "/login";
  const isResetPasswordPage = location.pathname.startsWith("/resetPassword/");
  const iLoginPage = location.pathname.startsWith("/login");
  const isForgotPasswordPage = location.pathname.startsWith("/forgotPassword");
  // const isForgotPasswordPage = location.pathname === "/forgotPassword";
  if (iLoginPage || isForgotPasswordPage || isResetPasswordPage) {
    return (
      <div className="App">

        <RouterPage />
      </div>
    );
  }
  return (
    <div className="App">
    
      <Navbar />
    </div>
  )
}

export default App;
