import React from "react";
import PrivateRoute from "./PrivateRoute";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/auth/login/Login";

import PanditProfileManagement from "../pages/PanditProfileManagement/PanditProfileManagement";
import PanditKYCManagement from "../pages/PanditKYCManagement/PanditKYCManagement";
import Dashboard from "../pages/Dashboard/Dashboard";
import ViewKYCPrice from "../pages/PanditKYCManagement/KYCPrice/ViewKYCPrice/ViewKYCPrice";
import SetKYCPrice from "../pages/PanditKYCManagement/KYCPrice/SetKYCPrice/SetKYCPrice";
import ViewKYCDetails from "../pages/PanditKYCManagement/ViewKYCDetails/View/ViewKYCDetails";
import SvarnaManagement from "../pages/SwarnaManagement/SvarnaManagement";
import CreateCampaign from "../pages/SwarnaManagement/Campaign/CreateCampaign/CreateCampaign";
import PayoutManagement from "../pages/PayoutManagement/PayoutManagement";
import PayoutDetails from "../pages/PayoutManagement/PayoutDetails/PayoutDetails";
import ForgotPassword from "../pages/auth/forgotPassword/ForgotPassword";
import ResetPassword from "../pages/auth/resetPassword/ResetPassword";
import ViewKYCPriceDetail from "../pages/PanditKYCManagement/KYCPrice/ViewKYCPrice/ViewKYCPriceDetail/ViewKYCPriceDetail";
import ViewCampaign from "../pages/SwarnaManagement/Campaign/ViewCampaign/ViewCampaign";
import EditCampaign from "../pages/SwarnaManagement/Campaign/EditCampaign/EditCampaign";
import SamhitaManagement from "../pages/SamhitaManagement/SamhitaManagement";
import CreateSamhita from "../pages/SamhitaManagement/CreateSamhita/CreateSamhita";
import ViewSamhita from "../pages/SamhitaManagement/ViewSamhita/ViewSamhita";
import NotFoundPage from "./404Page/PageNotFound";
import BannerManagement from "../pages/BannerManagement/BannerManagement";
import CreateBanner from "../pages/BannerManagement/CreateBanner/CreateBanner";
import ViewBanner from "../pages/BannerManagement/ViewBanner/ViewBanner";
import UserManagement from "../pages/UserManagement/UserManagement";
import UserDetails from "../pages/UserManagement/Details/UserDetails";
import NotificationManagement from "../pages/NotificationManagement/NotificationManagement";
import CreateNotification from "../pages/NotificationManagement/CreateNotification/CreateNotification";
import EditBanner from "../pages/BannerManagement/EditBanner/EditBanner";
import TaskManagement from "../pages/Master/TaskManagement/TaskManagement";
import DevalayaManagement from "../pages/Master/devalayaManagement/DevalayaManagement";

import CreateTejPoints from "../pages/SwarnaManagement/TejPoints/CreateTejPoints/CreateTejPoints";
import HowTaskCenterWorks from "../pages/HowTaskCenterWorks/HowTaskCenterWorks";
import RolesAndPermissionsManagement from "../pages/RolesAndPermissionsManagement/RolesAndPermissionsManagement";
import CreateRoles from "../pages/RolesAndPermissionsManagement/Roles/CreateRoles/CreateRoles";
import ViewTejPoints from "../pages/SwarnaManagement/TejPoints/ViewTejPoints/ViewTejPoints";
import EditTejPoints from "../pages/SwarnaManagement/TejPoints/EditTejPoints/EditTejPoints";
import ViewRoles from "../pages/RolesAndPermissionsManagement/Roles/ViewRoles/ViewRoles";
import EditRoles from "../pages/RolesAndPermissionsManagement/Roles/EditRoles/EditRoles";
import UserActvityManagement from "../pages/UserActvityManagement/UserActvityManagement";
import UtsavManagement from "../pages/Master/UtsavManagement/UtsavManagement";




const RouterPage = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <PanditKYCManagement />
          </PrivateRoute>
        }
      />
        <Route
        path="/rolesandpermissions"
        element={
          <PrivateRoute>
            <RolesAndPermissionsManagement />
          </PrivateRoute>
        }
      />
          <Route
        path="/rolesandpermissions/createroles"
        element={
          <PrivateRoute>
            <CreateRoles />
          </PrivateRoute>
        }
      />
        <Route
        path="/rolesandpermissions/viewroles/:id"
        element={
          <PrivateRoute>
            <ViewRoles />
          </PrivateRoute>
        }
      />
       <Route
        path="/rolesandpermissions/editroles/:id"
        element={
          <PrivateRoute>
            <EditRoles />
          </PrivateRoute>
        }
      />
      <Route
        path="/panditprofilemanagement"
        element={
          <PrivateRoute>
            <PanditProfileManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/panditkycmanagement"
        element={
          <PrivateRoute>
            <PanditKYCManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/panditkycmanagement/viewkycprice"
        element={
          <PrivateRoute>
            <ViewKYCPrice />
          </PrivateRoute>
        }
      />
      <Route
        path="/panditkycmanagement/viewkycpricedetails/:id"
        element={
          <PrivateRoute>
            <ViewKYCPriceDetail />
          </PrivateRoute>
        }
      />

      <Route
        path="/panditkycmanagement/setprice"
        element={
          <PrivateRoute>
            <SetKYCPrice />
          </PrivateRoute>
        }
      />
      <Route
        path="/panditkycmanagement/setprice/:id"
        element={
          <PrivateRoute>
            <SetKYCPrice />
          </PrivateRoute>
        }
      />
      <Route
        path="/panditkycmanagement/viewkycdetails/:id"
        element={
          <PrivateRoute>
            <ViewKYCDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/svarnamanagement"
        element={
          <PrivateRoute>
            <SvarnaManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/svarnamanagement/createcampaign"
        element={
          <PrivateRoute>
            <CreateCampaign />
          </PrivateRoute>
        }
      />
      <Route
        path="/svarnamanagement/viewcampaign/:id"
        element={
          <PrivateRoute>
            <ViewCampaign />
          </PrivateRoute>
        }
      />
      <Route
        path="/svarnamanagement/editcampaign/:id"
        element={
          <PrivateRoute>
            <EditCampaign />
          </PrivateRoute>
        }
      />
      <Route
        path="/svarnamanagement/createTejPoints"
        element={
          <PrivateRoute>
            <CreateTejPoints />
          </PrivateRoute>
        }
      />
      <Route
        path="/svarnamanagement/viewTejPoints"
        element={
          <PrivateRoute>
            <ViewTejPoints />
          </PrivateRoute>
        }
      />
      <Route
        path="/svarnamanagement/editTejPoints"
        element={
          <PrivateRoute>
            <EditTejPoints />
          </PrivateRoute>
        }
      />
      <Route
        path="/howtaskcenterworks"
        element={
          <PrivateRoute>
            <HowTaskCenterWorks />
          </PrivateRoute>
        }
      />
      <Route
        path="/useractivitylog"
        element={
          <PrivateRoute>
            <UserActvityManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/payoutmanagement"
        element={
          <PrivateRoute>
            <PayoutManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/payoutmanagement/payoutdetails/:id"
        element={
          <PrivateRoute>
            <PayoutDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/samhitamanagement"
        element={
          <PrivateRoute>
            <SamhitaManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/samhitamanagement/createsamhita"
        element={
          <PrivateRoute>
            <CreateSamhita />
          </PrivateRoute>
        }
      />
      <Route
        path="/samhitamanagement/viewsamhita"
        element={
          <PrivateRoute>
            <ViewSamhita />
          </PrivateRoute>
        }
      />
      <Route
        path="/bannermanagement"
        element={
          <PrivateRoute>
            <BannerManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/bannermanagement/create"
        element={
          <PrivateRoute>
            <CreateBanner />
          </PrivateRoute>
        }
      />
      <Route
        path="/bannermanagement/view"
        element={
          <PrivateRoute>
            <ViewBanner />
          </PrivateRoute>
        }
      />
      <Route
        path="/bannermanagement/edit"
        element={
          <PrivateRoute>
            <EditBanner />
          </PrivateRoute>
        }
      />
      <Route
        path="/usermanagement"
        element={
          <PrivateRoute>
            <UserManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/usermanagement/details/:userType/:userId"
        element={
          <PrivateRoute>
            <UserDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/notificationmanagement"
        element={
          <PrivateRoute>
            <NotificationManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/notificationmanagement/createnotification"
        element={
          <PrivateRoute>
            <CreateNotification />
          </PrivateRoute>
        }
      />
      <Route
        path="/master/taskmanagement"
        element={
          <PrivateRoute>
            <TaskManagement />
          </PrivateRoute>
        }
      />
        <Route
        path="/master/devalayamanagement"
        element={
          <PrivateRoute>
            <DevalayaManagement />
          </PrivateRoute>
        }
      />
        <Route
        path="/master/utsavmanagement"
        element={
          <PrivateRoute>
            <UtsavManagement />
          </PrivateRoute>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route path="/resetPassword/:token" element={<ResetPassword />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default RouterPage;
