import {createSlice} from '@reduxjs/toolkit'
import { adminForgotPassword, adminLogin, adminResetPassword } from '../actions/adminAction';
import { Toaster, toast } from "sonner";
const initialState = {
    isLoading : false,
    adminLoginData : [],
      adminForgotData : [],
    adminResetPasswordData : [],
    isSuccess: false,
    error: ""
}
const adminSlice =  createSlice({
 name : "adminSlice",
 initialState ,
 reducers: {
    resetAdminStatus: (state, action) => {
      state.isSuccess = action?.payload;  
      state.isLoading = false;
    //   state.isSuccessUpdated = action?.payload;
    },
  }, 
 extraReducers : (builder)=>{
    builder 
    // admin Login Slice
    .addCase(adminLogin.pending , (state , action)=>{
        state.isLoading = true;
        state.isSuccess= false
    
    })
    .addCase(adminLogin.fulfilled , (state , action)=>{
      state.isLoading = false;
      state.adminLoginData = action.payload
      state.isSuccess= true
      toast.success("Login successfully", {
        position: "top-right",
      });

    })
    .addCase(adminLogin.rejected , (state , action)=>{
        state.isLoading = false;
        state.error = action.payload
        state.isSuccess= false
        toast.error(JSON.stringify(state?.error), {
            position: "top-right",
          });

    })
    //admin Forgot Password Slice 
    .addCase(adminForgotPassword.pending , (state , action)=>{
        state.isLoading = true;
        state.isSuccess= false
    
    })
    .addCase(adminForgotPassword.fulfilled , (state , action)=>{
      state.isLoading = false;
      state.adminForgotData = action.payload
      state.isSuccess= true
      toast.success("Email is sent successfully", {
        position: "top-right",
      });

    })
    .addCase(adminForgotPassword.rejected , (state , action)=>{
        state.isLoading = false;
        state.error = action.payload
        state.isSuccess= false
        toast.error(JSON.stringify(state?.error), {
            position: "top-right",
          });

    })

    //admin Reset Password Slice 
    .addCase(adminResetPassword.pending , (state , action)=>{
        state.isLoading = true;
        state.isSuccess= false
    
    })
    .addCase(adminResetPassword.fulfilled , (state , action)=>{
      state.isLoading = false;
      state.adminResetPasswordData = action.payload
      state.isSuccess= true
      toast.success("Password  is updated successfully", {
        position: "top-right",
      });

    })
    .addCase(adminResetPassword.rejected , (state , action)=>{
        state.isLoading = false;
        state.error = action.payload
        state.isSuccess= false
        toast.error(JSON.stringify(state?.error), {
            position: "top-right",
          });

    });

 },
})
export const  {resetAdminStatus} = adminSlice.actions
export default adminSlice.reducer