import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { ReactReader } from 'react-reader';

const FileViewer = ({ fileType, fileUrl }) => {  
  const googleViewerUrl = `https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(fileUrl)}`;
  const [currentLocation, setCurrentLocation] = useState(null);

  const handleLocationChanged = (epubcifi) => {
    console.log('Location changed:', epubcifi);
    setCurrentLocation(epubcifi);
  };

  const goToLocation = (location) => {
    setCurrentLocation(location);
  };

  if (fileType === 'PDF') {
    return (
      <iframe src={googleViewerUrl} frameborder="0" height="800" width="100%"></iframe>
    );
  }  else if (fileType === 'EPUB') {
    // return     <iframe src={fileUrl} width={1080} height={1000} title="epub" frameborder="0"></iframe>
    return (
      <div style={{ position: 'relative', height: '100dvh' }}>
        <ReactReader
          url={fileUrl} // URL to your EPUB file
          // title="Epub Reader"
          locationChanged={handleLocationChanged}
          location={currentLocation}
          onLocationChange={goToLocation}
        />
      </div>
    );
  } else if (fileType === 'MOBI')
  {
    return     <iframe src={googleViewerUrl} width={1080} height={1000} title="epub" frameborder="0"></iframe>
    // <ePub url={fileUrl} />;
  } else{
    return <div>Unsupported file type</div>;
  }
};

export default FileViewer;




// import React, { useEffect, useState } from 'react';
// import { Document, Page } from 'react-pdf';
// import { ePub } from 'react-reader';

// const FileViewer = ({ fileUrl, samhitatype }) => {
//   const [fileType, setFileType] = useState('');
//   const [fileContent, setFileContent] = useState('');
//   const [error, setError] = useState(null);

//   console.log(fileUrl)
//   console.log(samhitatype)

//   useEffect(() => {
//     const readFile = async () => {
//       try {
//         let content;
//         if (samhitatype === 'PDF') {
//           // Fetch PDF file content directly from the URL
//           const response = await fetch(fileUrl);
//           console.log(response)
//           const data = await response.arrayBuffer();
//           console.log(data)
//           content = new Uint8Array(data);
//           setFileType('pdf');
//           setFileContent(content);
//         } else if (samhitatype === 'EPUB') {
//           setFileType('epub');
//           setFileContent(fileUrl);
//         } else if (samhitatype === 'MOBI') {
//           // Handle MOBI files if needed
//           throw new Error('MOBI file format is not supported in web app.');
//         } else {
//           throw new Error('Unsupported file type');
//         }
//       } catch (error) {
//         setError(error.message);
//       }
//     };

//     readFile();
//   }, [fileUrl, samhitatype]);

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   switch (fileType) {
//     case 'pdf':
//       return (
//         <div>
//           <Document file={fileContent}>
//             <Page pageNumber={1} />
//           </Document>
//         </div>
//       );
//     case 'epub':
//       return <ePub url={fileContent} />;
//     default:
//       return <div>Loading...</div>;
//   }
// };

// export default FileViewer;
