import React, { useState } from "react";
import { Modal } from "antd";
import styles from "./samhitaFilter.module.css";
import SelectComponent from "../../components/SelectComponent";

const SamhitaFilter = ({ isModalOpen, handleOk, handleCancel }) => {
  const [formData, setFormData] = useState({
    category: "",
    subCategory: "",
    languages: "",
  });

  const categoryOptions = [
    { value: "Aarti", label: "Aarti" },
    { value: "Chalisa", label: "Chalisa" },
    { value: "Mantra", label: "Mantra" },
  ];

  const subCategoryOption = [
    { value: "Puran", label: "Puran" },
    { value: "Katha", label: "Katha" },
  ];

  const languageOption = [
    { value: "Assamese", label: "Assamese" },
    { value: "Bengali", label: "Bengali" },
    { value: "Bodo", label: "Bodo" },
    { value: "Dogri", label: "Dogri" },
    { value: "English", label: "English" },
    { value: "Gujarati", label: "Gujarati" },
    { value: "Hindi", label: "Hindi" },
    { value: "Kannada", label: "Kannada" },
    { value: "Kashmiri", label: "Kashmiri" },
    { value: "Konkani", label: "Konkani" },
    { value: "Maithili", label: "Maithili" },
    { value: "Malayalam", label: "Malayalam" },
    { value: "Manipuri", label: "Manipuri" },
    { value: "Marathi", label: "Marathi" },
    { value: "Nepali", label: "Nepali" },
    { value: "Odia", label: "Odia" },
    { value: "Punjabi", label: "Punjabi" },
    { value: "Sanskrit", label: "Sanskrit" },
    { value: "Santali", label: "Santali" },
    { value: "Sindhi", label: "Sindhi" },
    { value: "Tamil", label: "Tamil" },
    { value: "Telugu", label: "Telugu" },
  ];

  const handleCategorySelect = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      category: selectedOption.value,
    }));
  };
  const handleSubCategorySelect = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      subCategory: selectedOption.value,
    }));
  };
  const handleLanguageSelect = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      languages: selectedOption.value,
    }));
  };


  const handleFilter = () => {
    
    handleOk(formData); // Pass formData back to parent component
  };

  return (
    <Modal
      visible={isModalOpen}
      onCancel={handleCancel}
      onOk={handleFilter}
      width={"29vw"}
      className={styles.modal}
    >
      <div className={styles.modalTitle}>
        <p>Filter Samhitas</p>
      </div>
      <div className={styles.contentMain}>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>Category</p>
          </div>
          <div className={styles.fieldInput}>
            <SelectComponent
              options={categoryOptions}
              onSelect={handleCategorySelect}
              initialSelectedOption={formData.category}
            />
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>Subcategory</p>
          </div>
          <div className={styles.fieldInput}>
            <SelectComponent
              options={subCategoryOption}
              onSelect={handleSubCategorySelect}
              initialSelectedOption={formData.subCategory}
            />
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>Languages</p>
          </div>
          <div className={styles.fieldInput}>
            <SelectComponent
              options={languageOption}
              onSelect={handleLanguageSelect}
              initialSelectedOption={formData.languages}
            />
          </div>
        </div>
        <div className={styles.modalButton}>
          <button onClick={handleCancel}>Cancel</button>
          <button onClick={handleFilter}>Filter</button>
        </div>
      </div>
    </Modal>
  );
};

export default SamhitaFilter;
