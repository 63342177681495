import React, { useState } from "react";
import styles from "./payoutDetails.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import History from "./History/History";
import Details from "./Details/Details";



function PayoutDetails() {
  const location = useLocation();
  const item = location.state && location.state.item;
  console.log(item)
  const menuItems = [
    { label: "Details", state: "Details" },
    { label: "History", state: "History" },
  ];

  const navigate = useNavigate();
  const [activeMenuItem, setActiveMenuItem] = useState("Details");

  const handlePoliciesMenu = (menu) => {
    setActiveMenuItem(menu);
  };
  return (
    <div className={styles.main}>
      <div className={styles.pageHeader}>
        <p>
          <span onClick={() => navigate("/payoutmanagement")}>Payout Management</span>{" "}
          <span className={styles.activePageStyle}>&gt; Payout Details</span>
        </p>
        <button onClick={() => navigate("/payoutmanagement")}>
          Back
        </button>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.menuContainer}>
          {menuItems.map((item) => (
            <div
              key={item.state}
              className={activeMenuItem === item.state ? `${styles.singleMenu} ${styles.activeMenu }`: styles.singleMenu}
              onClick={() => handlePoliciesMenu(item.state)}
            >
              <p>{item.label}</p>
            </div>
          ))}
        </div>
        <div className={styles.activePage}>
        {activeMenuItem === "Details" && <Details />}
        {activeMenuItem === "History" && <History payId={item?.payId} />}
        </div>
      </div>
    </div>
  );
}

export default PayoutDetails;
