import React from 'react'
import RichTextEditor from '../../components/RichTextEditor/RichTextEditor'

function PanditProfileManagement() {
  return (
    <div>
      <h1>Pandit Profile Management</h1>
      <RichTextEditor/>
    </div>
  )
}

export default PanditProfileManagement
