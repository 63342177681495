import React, { useState } from "react";
import { Modal } from "antd";
import styles from "./EditIsthaDhaiva.module.css";
import { toast } from "sonner";
import FileUpload from "../../../../../components/FileUpload/FileUpload";
import editIcon from "../../../../../assets/images/edit.png";
import { userRequest } from "../../../../../requestMethod";

const EditIshtDhaiva = ({fetchData,task}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    daivaName: task?.daivaName,
    daivaPic: task?.daivaPic,
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleOk = async () => {
    setIsModalOpen(false);

    try {
      await userRequest.put(`/daiva/updateDaiva/${task?._id}`, formData);
      
      toast.success("Dhaiva updated successfully");
      await fetchData()
    } catch (error) {
      console.log("Error updating Dhaiva:", error);
      // Show error message
      toast.error("Failed to update Dhaiva");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <img src={editIcon} alt="" onClick={showModal} />

      <Modal
        visible={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
     
        className={styles.modal}
      >
        <div className={styles.modalTitle}>
          <p>Edit Istha Dhaiva</p>
        </div>
        <div className={styles.contentMain}>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Name</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="daivaName"
                value={formData.daivaName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Dhaiva Pic</p>
            </div>
            <div className={styles.fieldInput}>
              <FileUpload
                label="Upload Media"
                onChange={(fieldName, file) =>
                  setFormData({ ...formData, daivaPic: file })
                }
                fieldName="uploadMedia"
                name={formData.daivaPic}
              />
            </div>
          </div>
          <div className={styles.modalButton}>
            <button onClick={handleCancel}>Cancel</button>
            <button onClick={handleOk}>Update</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditIshtDhaiva;

