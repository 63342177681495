import React, { useEffect, useState } from "react";
import styles from "./notificationMgmt.module.css";
import { useNavigate } from "react-router-dom";
import { BASE_URL, userRequest } from "../../requestMethod";
import { Spin, Switch } from "antd";
import NotificationFilter from "./NotificationFilter";
import useDebounce from "../../components/UseDebounce";

function NotificationManagement() {
  const [notificationData, setNotificationData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [loading, setLoading] = useState(true)

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  

  useEffect(() => {
    fetchData();
  }, [debouncedSearchQuery, page, filterData]);

  const fetchData = async () => {
    try {
      const filterQuery = filterData
      ? `&date=${filterData.date}&userType=${filterData.userType}`
      : "";
      const response = await userRequest.get(
        `/pushNotification/getNotificationList?search=${debouncedSearchQuery}${filterQuery}&page=${page}`
      );
      setNotificationData(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
      setCurrentPage(response.data.pagination.page);
    } catch (error) {
      console.error("Error fetching data:", error);
    }finally{
      setLoading(false)
    }
  };
  const handleFilterClick = () => {
    setIsModalOpen(true);
  };

  const handleModalOk = (data) => {
    setFilterData(data);
    setIsModalOpen(false);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div className={styles.main}>
      <div className={styles.pageHeader}>
        <p className={styles.notifiHeader}>
          <span onClick={() => navigate("/notificationmanagement")}>Notification Management</span>
        </p>
        <button className = {styles.addNewBtn} onClick={() => navigate("/notificationmanagement/createnotification")}>
          Add New
        </button>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.activePage}>
          <div className={styles.filterExport}>
            {/* <p>All</p> */}
            <div  className={styles.search}>
              <input
                type="text"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className={styles.export}>
              {/* <p><a href={`${BASE_URL}admin/samhita/exportSamhitaDataToExcel?search=${searchQuery}&page=${page}`} target="_parent">Export as CSV, PDF, Excel doc</a></p> */}
              <p><a href={`${BASE_URL}/pushNotification/exportPushNotificationDataToExcel?search=${searchQuery}&page=${page}`} target="_parent">Export as CSV, PDF, Excel doc</a></p>
            </div>
            <div className={styles.filterDiv}>
              <p onClick={handleFilterClick}>Filter</p>
            </div>
            
          </div>
          <Spin spinning={loading} size="small">
          <div className={styles.tableContainerNoti}>
          <table>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Date</th>
                  <th>Message</th>
                  <th>User Type</th>
                  <th>Send To</th>
                </tr>
              </thead>
              <tbody>
                {notificationData.length > 0 ? (
                  notificationData.map((notification, index) => (
                    <tr key={notification._id}>
                      <td>{notification.title}</td>
                      <td>{formatDate(notification.createdAt)}</td>
                      <td>{notification.message}</td>
                      <td>{notification.userType}</td>
                      <td>
                        {notification.sendTo.length > 0 &&
                          `${notification.sendTo[0].firstName} + ${notification.sendTo.length}`}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8">No data found</td>
                  </tr>
                )}
              </tbody>
            </table>
            {notificationData.length > 0 && (
              <div className={styles.footer}>
                <div
                  className={styles.action}
                  style={{
                    visibility: totalPages === 1 ? "hidden" : "visible",
                  }}
                >
                  <button
                    className={`${styles.actionButton} ${
                      page === 1 ? styles.disabled : styles.activeButton
                    }`}
                    onClick={handlePrevious}
                    disabled={page === 1}
                  >
                    Previous
                  </button>
                  <button
                    className={`${styles.actionButton} ${
                      page === totalPages
                        ? styles.disabled
                        : styles.activeButton
                    }`}
                    onClick={handleNext}
                    disabled={page === totalPages}
                  >
                    Next
                  </button>
                </div>
                <div className={styles.page}>
                  <span>{currentPage}</span> <span>of</span>{" "}
                  <span>{totalPages}</span>
                </div>
              </div>
            )}
          </div>
          </Spin>
        </div>
      </div>
      <NotificationFilter isModalOpen={isModalOpen} handleOk={handleModalOk} handleCancel={handleModalCancel} />

    </div>
  );
}

export default NotificationManagement
;

function formatDate(dateString) {
  // Convert dateString to Date object
  const date = new Date(dateString);

  // Format the date using toLocaleDateString method
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  return formattedDate;
}
