// import React, { useState } from "react";
// import styles from "./tejPoints.module.css";
// import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
// import { userRequest } from "../../../requestMethod";
// import { toast } from "sonner";

// function TejPoints() {
//   const initialFormData = {
//     country: "India",
//     state: "",
//     loginPoint: "",
//     templePoint: "",
//     referPoint: "",
//     utsavPoint: "",
//     bookingPoint: "",
//     kycPoint: "",
//   };

//   const [formData, setFormData] = useState(initialFormData);

//   const selectCountry = (val) => {
//     setFormData({
//       ...formData,
//       country: val,
//       state: "", // Reset state when country changes
//     });
//   };

//   const selectState = (val) => {
//     setFormData({
//       ...formData,
//       state: val,
//     });
//   };

//   const saveData = async () => {
//     try {
//       await userRequest.post("/tejPoints/createTejPoints", formData);
//       setFormData(initialFormData);
//       toast.success("Saved successfully!");
//     } catch (error) {
//       console.error("Error saving data:", error.message);
//       alert("Failed to save data. Please try again later.");
//     }
//   };

//   return (
//     <div className={styles.main}>
//       <div className={styles.fieldContainer}>
//         <div className={styles.feildLeft}>
//           <div className={styles.fieldLabel}>
//             <p>Country</p>
//           </div>
//           <div className={styles.fieldInput}>
//             <CountryDropdown
//               value={formData.country}
//               onChange={(val) => selectCountry(val)}
//             />
//           </div>
//         </div>
//         <div className={styles.feildRight}>
//           <div className={styles.fieldLabel}>
//             <p>State</p>
//           </div>
//           <div className={styles.fieldInput}>
//             <RegionDropdown
//               country={formData.country}
//               value={formData.state}
//               onChange={(val) => selectState(val)}
//             />
//           </div>
//         </div>
//       </div>
//       <div className={styles.fieldContainer}>
//         <div className={styles.feildLeft}>
//           <div className={styles.fieldLabel}>
//             <p>Login Point</p>
//           </div>
//           <div className={styles.fieldInput}>
//             <input
//               type="text"
//               value={formData.loginPoint}
//               onChange={(e) =>
//                 setFormData({ ...formData, loginPoint: e.target.value })
//               }
//             />
//           </div>
//         </div>
//         <div className={styles.feildRight}>
//           <div className={styles.fieldLabel}>
//             <p>Add Temple Point</p>
//           </div>
//           <div className={styles.fieldInput}>
//             <input
//               type="text"
//               value={formData.templePoint}
//               onChange={(e) =>
//                 setFormData({ ...formData, templePoint: e.target.value })
//               }
//             />
//           </div>
//         </div>
//       </div>
//       <div className={styles.fieldContainer}>
//         <div className={styles.feildLeft}>
//           <div className={styles.fieldLabel}>
//             <p>Refer Point</p>
//           </div>
//           <div className={styles.fieldInput}>
//             <input
//               type="text"
//               value={formData.referPoint}
//               onChange={(e) =>
//                 setFormData({ ...formData, referPoint: e.target.value })
//               }
//             />
//           </div>
//         </div>
//         <div className={styles.feildRight}>
//           <div className={styles.fieldLabel}>
//             <p>Create Utsav Point</p>
//           </div>
//           <div className={styles.fieldInput}>
//             <input
//               type="text"
//               value={formData.utsavPoint}
//               onChange={(e) =>
//                 setFormData({ ...formData, utsavPoint: e.target.value })
//               }
//             />
//           </div>
//         </div>
//       </div>
//       <div className={styles.fieldContainer}>
//         <div className={styles.feildLeft}>
//           <div className={styles.fieldLabel}>
//             <p>Booking Point</p>
//           </div>
//           <div className={styles.fieldInput}>
//             <input
//               type="text"
//               value={formData.bookingPoint}
//               onChange={(e) =>
//                 setFormData({ ...formData, bookingPoint: e.target.value })
//               }
//             />
//           </div>
//         </div>
//         <div className={styles.feildRight}>
//           <div className={styles.fieldLabel}>
//             <p>Complete KYC Point</p>
//           </div>
//           <div className={styles.fieldInput}>
//             <input
//               type="text"
//               value={formData.kycPoint}
//               onChange={(e) =>
//                 setFormData({ ...formData, kycPoint: e.target.value })
//               }
//             />
//           </div>
//         </div>
//       </div>
//       {/* <div className={styles.fieldContainer}>
//         <div className={styles.feildLeft}>
//           <div className={styles.fieldLabel}>
//             <p>KYC Price</p>
//           </div>
//           <div className={styles.fieldInput}>
//             <input type="text" />
//           </div>
//         </div>
//         <div className={styles.feildRight}>
//           <div className={styles.fieldLabel}>
//             <p>Final Amount</p>
//           </div>
//           <div className={styles.fieldInput}>
//             <input type="text" />
//           </div>
//         </div>
//       </div> */}
//       <div className={styles.footer}>
//         <button>Discard</button>
//         <button onClick={saveData}>Save</button>
//       </div>
//     </div>
//   );
// }

// export default TejPoints;

import React, { useEffect, useState } from "react";
import styles from "./tejPoints.module.css";
import { useNavigate } from "react-router-dom";
import { BASE_URL, userRequest } from "../../../requestMethod";
import editIcon from "../../../assets/images/edit.png";
import { Switch } from "antd";
import useDebounce from "../../../components/UseDebounce";
function TejPoints() {
  const navigate = useNavigate();
  const [tejPointsData, setTejPointsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    fetchData();
  }, [debouncedSearchQuery, page]);

  const fetchData = async () => {
    try {
      const response = await userRequest.get(
        `/tejPoints/getTejPoints?search=${debouncedSearchQuery}&page=${page}`
      );
      setTejPointsData(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
      setCurrentPage(response.data.pagination.page);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleViewClick = (tejPoint) => { 
    navigate(`/svarnamanagement/viewtejpoints`, { state: { tejPoint } }); 
  };
  const handleEditClick = (tejPoint) => {
    navigate(`/svarnamanagement/edittejpoints`, { state: { tejPoint } }); 
  };

  return (
    <div className={styles.tableMain}>
      <div className={styles.filterExport}>
        {/* <p>All</p> */}
        <div className={styles.search}>
          <input type="text" onChange={(e) => setSearchQuery(e.target.value)} />
        </div>
        {/* <div className={styles.export}>
          <p>
            <a
              href={`${BASE_URL}/campaign/exportCampaignDataToExcel?search=${searchQuery}&page=${page}`}
              target="_parent"
            >
              Export as CSV, PDF, Excel doc
            </a>
          </p>
        </div> */}
        <div className={styles.filterDiv}>
          <p>Filter</p>
        </div>
      </div>
      <div className={styles.campaignTableMain}>
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Country</th>
              <th>State</th>
              <th>Login</th>
              <th>Add Temple</th>
              <th>Refer</th>
              <th>Create Utsav</th>
              <th>Booking</th>
              <th>Complete KYC</th>
              <th>Action & Status</th>
            </tr>
          </thead>
          <tbody>
            {tejPointsData.length > 0 ? (
              tejPointsData.map((tejPoint, index) => (
                <tr key={tejPoint._id}>
                  <td>{(currentPage - 1) * 10 + (index + 1)}</td>
                  <td>{tejPoint.country}</td>
                  <td>{tejPoint.state}</td>
                  <td>{tejPoint.loginPoint}</td>
                  <td>{tejPoint.templePoint}</td>
                  <td>{tejPoint.referPoint}</td>
                  <td>{tejPoint.utsavPoint}</td>
                  <td>{tejPoint.bookingPoint}</td>
                  <td>{tejPoint.completeKycPoint}</td>
                  <td className={styles.actionTd}>
                    <span
                      className={styles.viewDetails}
                      onClick={() => handleViewClick(tejPoint)}
                    >
                      View
                    </span>                 

                    <img
                      src={editIcon}
                      alt=""
                      onClick={() => handleEditClick(tejPoint)}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="11">No data found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {tejPointsData.length > 0 ? (
        <div className={styles.footer}>
          <div
            className={styles.action}
            style={{ visibility: totalPages === 1 ? "hidden" : "visible" }}
          >
            <button
              className={`${styles.actionButton} ${
                page === 1 ? styles.disabled : styles.activeButton
              }`}
              onClick={handlePrevious}
              disabled={page === 1}
            >
              Previous
            </button>
            <button
              className={`${styles.actionButton} ${
                page === totalPages ? styles.disabled : styles.activeButton
              }`}
              onClick={handleNext}
              disabled={page === totalPages}
            >
              Next
            </button>
          </div>
          <div className={styles.page}>
            <span>{currentPage}</span> <span>of</span> <span>{totalPages}</span>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default TejPoints;
