import React from "react";
import styles from "./viewBanner.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import image from "../../../assets/images/logo.png";
import copiedImgIcon from "../../../assets/images/copiedImgIcon.png";
import { toast } from "sonner";

import { userRequest } from "../../../requestMethod";
import { Popconfirm } from "antd";

function ViewBanner() {
  const navigate = useNavigate();
  const location = useLocation();
  const banner = location.state && location.state.banner;

  const handleEditClick = (banner) => {
    navigate(`/bannermanagement/edit`, { state: { banner } }); 
  };

  if (!banner) {
    // If banner data is not available, you can redirect to a different page or display an error message
    return <div>No banner data available</div>;
  }

  const handleDelete =async (id) =>{
    
    try {
      // Send a request to update the status
      await userRequest.put(
        "/master/updateMasterBanner",
        {
        id,
        deleted:true
        }
      );
      // Handle success or navigate to a different page
      navigate("/bannermanagement"); // Redirect to the management page after saving
      // toast.success("Updated Successfully");
    } catch (error) {
      console.error("Error updating status:", error);
    }
  }
  const copyToClipboardForImg = () => {
    if (banner?.imageUrl) {
      navigator.clipboard.writeText(banner?.imageUrl)
        .then(() => {
          // console.log('Image URL copied to clipboard');
      toast.success('Image URL copied');

        })
        .catch(err => {
          console.error('Failed to copy: ', err);
        });
    }
  };
  const copyToClipboardForLink= () => {
    if (banner?.linkUrl) {
      navigator.clipboard.writeText(banner?.linkUrl)
        .then(() => {
          // console.log('Image URL copied to clipboard');
      toast.success('Link URL copied');

        })
        .catch(err => {
          console.error('Failed to copy: ', err);
        });
    }
  };
  return (
    <div className={styles.main}>
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderLeft}>
          <p className={styles.editBannerPara}>
            <span onClick={() => navigate("/bannermanagement")}>
              Banner Management
            </span>{" "}
            <span className={styles.activePageStyle}>&gt; Details</span>
          </p>
        </div>
        <div className={styles.pageHeaderRight}>
          {/* <button className={styles.btnSaveDiscard} onClick={() => handleDelete(banner._id)}>Delete</button> */}
          
          <Popconfirm
            title={`Are you sure to delete ${banner.title}?`}
            onConfirm={() => handleDelete(banner._id)}
            okText="Yes"
            cancelText="No"
            overlayClassName={styles.largePopconfirm}
          >
            <button className={styles.btnSaveDiscard}>Delete</button>
          </Popconfirm>
          <button className={styles.btnSaveDiscard}onClick={() => handleEditClick(banner)}>
            Edit
          </button>
        </div>
      </div>

      <div className={styles.contentContainer}>
        <div className={styles.activePage}>
          <div className={styles.bannerImageSection}>
          <img src={banner.imageUrl} alt={banner.title} />
          </div>
          <div className={styles.bannerTitle}>
            <p>{banner.title}</p>
          


          </div>
          <div className={styles.bannerLink}>
            <p>Image URL</p>
            <p>{banner?.imageUrl} 
            {/* <img src={copiedImgIcon} className={styles.copiedImgIcon}/> */}
            <img
          src={copiedImgIcon}
          className={styles.copiedImgIcon}
          onClick={copyToClipboardForImg}
          alt="Copy to clipboard"
          style={{ cursor: 'pointer' }} // Optional: to show a pointer cursor on hover
        />
            </p>
            
          </div>
          <div className={styles.bannerLink}>
            <p>Link URL</p>
            <p>{banner?.linkUrl} 
             {/* <img src={copiedImgIcon} className={styles.copiedImgIcon}/> */}
             <img
          src={copiedImgIcon}
          className={styles.copiedImgIcon}
          onClick={copyToClipboardForLink}
          alt="Copy to clipboard"
          style={{ cursor: 'pointer' }} // Optional: to show a pointer cursor on hover
        />
            </p>

          </div>
          <div className={styles.bannerContent}>
            <p>Content</p>
            <p>{banner?.content}</p>
          </div>
          <div className={styles.bannerDetails}>
            <p>Venue Details</p>
            <div className={styles.singleDetails}>
              <div className={styles.details}>
                <p>Start Date</p>
                <p>{formatDate(banner.startDate)}</p>
              </div>
              <div className={styles.details}>
                <p>End Date</p>
                <p>{formatDate(banner.endDate)}</p>
              </div>
            </div>
            <div className={styles.singleDetails}>
              <div className={styles.details}>
                <p>Target Audience</p>
                <p>{banner?.targetAudience}</p>
              </div>
              <div className={styles.details}>
                <p>Geographical Scope</p>
                <p>{banner?.geographicalScope}</p>

              </div>
            </div>
            <div className={styles.singleDetails}>
              <div className={styles.details}>
                <p>Language</p>
                <p>{banner?.language}</p>

              </div>
            
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewBanner;







// import React from "react";
// import styles from "./viewBanner.module.css";
// import { useLocation, useNavigate } from "react-router-dom";

// function ViewBanner() {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const banner = location.state && location.state.banner;
//   console.log(banner)

//   if (!banner) {
//     // If banner data is not available, you can redirect to a different page or display an error message
//     return <div>No banner data available</div>;
//   }

//   return (
//     <div className={styles.main}>
//       <div className={styles.pageHeader}>
//         <div className={styles.pageHeaderLeft}>
//           <p>
//             <span onClick={() => navigate("/bannermanagement")}>
//               Banner Management
//             </span>{" "}
//             <span className={styles.activePageStyle}>&gt; Details</span>
//           </p>
//         </div>
//         <div className={styles.pageHeaderRight}>
//           <button onClick={() => navigate("/bannermanagement")}>Delete</button>
//           <button onClick={() => navigate(`/bannermanagement/edit/${banner._id}`)}>
//             Edit
//           </button>
//         </div>
//       </div>

//       <div className={styles.contentContainer}>
//         <div className={styles.activePage}>
//           <div className={styles.bannerImageSection}>
//             <img src={banner.imageUrl} alt={banner.title} />
//           </div>
//           <div className={styles.bannerTitle}>
//             <p>{banner.title}</p>
//           </div>
//           <div className={styles.bannerDetails}>
//             <p>Start Date</p>
//             <p>{formatDate(banner.startDate)}</p>
//           </div>
//           <div className={styles.bannerDetails}>
//             <p>End Date</p>
//             <p>{formatDate(banner.endDate)}</p>
//           </div>
//           {/* Add more details as needed */}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ViewBanner;

function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
}
