import React, { useEffect, useState } from "react";
import styles from "./CreateRoles.module.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { userRequest } from "../../../../requestMethod";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function CreateRoles() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const initialFormData = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    userType: "SUB-ADMIN",
    designation: "",
    permissions: [],
    mobile: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [permissions, setPermissions] = useState([]);
  const [viewStatus, setViewStatus] = useState(false);
  const [editStatus, setEditStatus] = useState(false);

  // Fetch permissions on component mount
  useEffect(() => {
    async function fetchPermissions() {
      try {
        const response = await userRequest.get(
          "/permissions/getAllPermissions"
        );
        // const response = await axios.get(
        //   "http://localhost:8080/api/permissions/getAllPermissions"
        // );
        setPermissions(response.data.data);
      } catch (error) {
        console.error("Error fetching permissions:", error.message);
        alert("Failed to fetch permissions. Please try again later.");
      }
    }
    fetchPermissions();
  }, []);

  const handleCheckboxChange = (permissionsId) => {
    const isPermissionChecked = formData.permissions.some(
      (p) => p.permissionsId === permissionsId
    );
    let updatedPermissions;

    if (isPermissionChecked) {
      // If permission is checked, remove it from the permissions list
      updatedPermissions = formData.permissions.filter(
        (p) => p.permissionsId !== permissionsId
      );
    } else {
      // If permission is not checked, add it to the permissions list
      updatedPermissions = [...formData.permissions, { permissionsId }];
    }

    setFormData({ ...formData, permissions: updatedPermissions });
  };

  // const handleCheckboxChange = (permissionsId) => {
  //   console.log("formData.permissions",formData.permissions)
  //   const updatedPermissions = formData.permissions.map(permission => {
  //     if (permission.permissionsId === permissionsId) {
  //       // Toggle the edit and view statuses
  //       console.log("permission",permission)
  //       return { ...permission };
  //     }
  //     return permission;
  //   });

  //   // If the permission doesn't exist, add it to the array
  //   if (!updatedPermissions.find(permission => permission.permissionsId === permissionsId)) {
  //     updatedPermissions.push({ permissionsId });
  //   }
  // console.log("updatedPermissions",updatedPermissions)
  //   setFormData({ ...formData, permissions: updatedPermissions });
  // };

  const saveData = async () => {
    try {
      console.log("formData", formData);
      // await axios.post("http://localhost:8080/api/roles/createRoles", formData);
       await userRequest.post(
        "/roles/createRoles",formData
      );

      setFormData(initialFormData);
      // setData(initialFormData);
      toast.success("Saved successfully!");
    } catch (error) {
      console.log("Error saving data:", error?.response?.data?.message);
      toast.error(error?.response?.data?.message);

      // alert("Failed to save data. Please try again later.");
    }
  };
  console.log("permissions", permissions);
  return (
    <div className={styles.main}>
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderLeft}>
          <p className={styles.createRolesPara}>
            <span onClick={() => navigate("/rolesandpermissions")}>
              Roles and Permissions Management
            </span>{" "}
            <span className={styles.activePageStyle}>
              &gt; Add Role and Permissions
            </span>
          </p>
        </div>
        <div className={styles.pageHeaderRight}>
          <button className={styles.btnSaveDiscard} onClick={() => navigate("/rolesandpermissions")}>
            Discard
          </button>
          <button className={styles.btnSaveDiscard} onClick={saveData}>Save</button>
        </div>
      </div>
      <div className={styles.contentContainer}>
      <h4>Basics Details</h4>

        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Name</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                placeholder="Enter"
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Email Id</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                placeholder="Enter"
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Password</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                placeholder="Enter"
              />
            </div>
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>UserType</p>
            </div>
            <div className={styles.fieldInput}>
              {/* <input
                type="text"
                value={formData.userType}
                onChange={(e) =>
                  setFormData({ ...formData, userType: e.target.value })
                }
                placeholder="Enter"
              /> */}
              <select
                value={formData.userType}
                onChange={(e) =>
                  setFormData({ ...formData, userType: e.target.value })
                }
              >
                {/* Define dropdown options */}
                <option value="SUB-ADMIN">SUB-ADMIN</option>
                {/* <option value="PANDIT">PANDIT</option> */}
                {/* Add more options if needed */}
              </select>
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Designation</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={formData.designation}
                onChange={(e) =>
                  setFormData({ ...formData, designation: e.target.value })
                }
                placeholder="Enter"
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Confirm Password</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={formData.confirmPassword}
                // value={
                //   formData.mobile.startsWith("+91")
                //     ? formData.mobile
                //     : "+91" + formData.mobile
                // }
                onChange={(e) =>
                  setFormData({ ...formData, confirmPassword: e.target.value })
                }
                placeholder="Enter"
              />
            </div>
          </div>
        </div>

        <div className={styles.permissionDiv}>
          <h5>Pemissions</h5>
          <div className={styles.rolesTable}>
            <table>
              <tbody>
                {permissions.map((permission) => (
                  <tr className={styles.firstRow} key={permission._id}>
                    <td>
                      <div className={styles.permissionName}>
                        <input
                          type="checkbox"
                          checked={formData.permissions.some(
                            (p) => p.permissionsId === permission._id
                          )}
                          onChange={() => handleCheckboxChange(permission._id)}
                        />
                        <p className={styles.permissionNamePara}>
                          {permission.permissionName}
                        </p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateRoles;
