// import React, { useState, useRef, useEffect } from 'react';
// import { ContentState, Editor, EditorState, RichUtils, convertFromHTML, getDefaultKeyBinding } from 'draft-js';
// import 'draft-js/dist/Draft.css';
// import './RichEditor.css';
// import { stateToHTML } from 'draft-js-export-html';

// const RichTextEditor = ({ value, onChange,disabled }) => {
//   const [editorState, setEditorState] = useState(() => {
//     if (value) {
//       const blocksFromHTML = convertFromHTML(value);
//       const contentState = ContentState.createFromBlockArray(
//         blocksFromHTML.contentBlocks,
//         blocksFromHTML.entityMap
//       );
//       return EditorState.createWithContent(contentState);
//     } else {
//       return EditorState.createEmpty();
//     }
//   });

  
//   const editorRef = useRef(null);

//   useEffect(() => {
//     if (editorRef.current && !editorState.getCurrentContent().hasText()) {
//       editorRef.current.focus();
//     }
//   }, []);

//   useEffect(() => {
//     // Update editorState when the value prop changes
//     if (value) {
//       const blocksFromHTML = convertFromHTML(value);
//       const contentState = ContentState.createFromBlockArray(
//         blocksFromHTML.contentBlocks,
//         blocksFromHTML.entityMap
//       );
//       setEditorState(EditorState.createWithContent(contentState));
//     } else {
//       setEditorState(EditorState.createEmpty());
//     }
//   }, [value]);

//   const handleChange = (newEditorState) => {
//     setEditorState(newEditorState);
//     if (onChange) {
//       const contentState = newEditorState.getCurrentContent();
//       const html = stateToHTML(contentState);
//       onChange(html);
//     }
//   };


//   const focusEditor = () => {
//     if (editorRef.current) {
//       editorRef.current.focus();
//     }
//   };

//   const handleKeyCommand = (command) => {
//     const newEditorState = RichUtils.handleKeyCommand(editorState, command);
//     if (newEditorState) {
//       handleChange(newEditorState);
//       return 'handled';
//     }
//     return 'not-handled';
//   };

//   const mapKeyToEditorCommand = (e) => {
//     if (e.keyCode === 9 /* TAB */) {
//       const newEditorState = RichUtils.onTab(
//         e,
//         editorState,
//         4 /* maxDepth */
//       );
//       if (newEditorState !== editorState) {
//         handleChange(newEditorState);
//       }
//       return;
//     }
//     return getDefaultKeyBinding(e);
//   };

//   const toggleBlockType = (blockType) => {
//     handleChange(
//       RichUtils.toggleBlockType(
//         editorState,
//         blockType
//       )
//     );
//   };

//   const toggleInlineStyle = (inlineStyle) => {
//     handleChange(
//       RichUtils.toggleInlineStyle(
//         editorState,
//         inlineStyle
//       )
//     );
//   };

//   const blockStyleFn = (block) => {
//     switch (block.getType()) {
//       case 'blockquote':
//         return 'RichEditor-blockquote';
//       default:
//         return null;
//     }
//   };

//   return (
//     <div className="RichEditor-root">
//       <BlockStyleControls
//         editorState={editorState}
//         onToggle={toggleBlockType}
//       />
//       <InlineStyleControls
//         editorState={editorState}
//         onToggle={toggleInlineStyle}
//       />
//       <div className="RichEditor-editor" onClick={focusEditor}>
//         <Editor
//           blockStyleFn={blockStyleFn}
//           editorState={editorState}
//           handleKeyCommand={handleKeyCommand}
//           keyBindingFn={mapKeyToEditorCommand}
//           onChange={handleChange}
//           ref={editorRef}
//           spellCheck={true}
//           readOnly={disabled}
//         />
//       </div>
//     </div>
//   );
// };

// const BlockStyleControls = (props) => {
//   const { editorState } = props;
//   const selection = editorState.getSelection();
//   const blockType = editorState
//     .getCurrentContent()
//     .getBlockForKey(selection.getStartKey())
//     .getType();

//   return (
//     <div className="RichEditor-controls">
//       {BLOCK_TYPES.map((type) => (
//         <StyleButton
//           key={type.label}
//           active={type.style === blockType}
//           label={type.label}
//           onToggle={props.onToggle}
//           style={type.style}
//         />
//       ))}
//     </div>
//   );
// };

// const INLINE_STYLES = [
//   { label: 'Bold', style: 'BOLD' },
//   { label: 'Italic', style: 'ITALIC' },
//   { label: 'Underline', style: 'UNDERLINE' },
//   { label: 'Monospace', style: 'CODE' },
// ];

// const InlineStyleControls = (props) => {
//   const currentStyle = props.editorState.getCurrentInlineStyle();

//   return (
//     <div className="RichEditor-controls">
//       {INLINE_STYLES.map((type) => (
//         <StyleButton
//           key={type.label}
//           active={currentStyle.has(type.style)}
//           label={type.label}
//           onToggle={props.onToggle}
//           style={type.style}
//         />
//       ))}
//     </div>
//   );
// };

// const StyleButton = (props) => {
//   const onToggle = (e) => {
//     e.preventDefault();
//     props.onToggle(props.style);
//   };

//   let className = 'RichEditor-styleButton';
//   if (props.active) {
//     className += ' RichEditor-activeButton';
//   }

//   return (
//     <span className={className} onMouseDown={onToggle}>
//       {props.label}
//     </span>
//   );
// };

// const BLOCK_TYPES = [
//   { label: 'H1', style: 'header-one' },
//   { label: 'H2', style: 'header-two' },
//   { label: 'H3', style: 'header-three' },
//   { label: 'H4', style: 'header-four' },
//   { label: 'H5', style: 'header-five' },
//   { label: 'H6', style: 'header-six' },
//   { label: 'UL', style: 'unordered-list-item' },
//   { label: 'OL', style: 'ordered-list-item' },
// ];

// export default RichTextEditor;



import React, { useState, useRef, useEffect } from 'react';
import { ContentState, Editor, EditorState, RichUtils, convertFromHTML, getDefaultKeyBinding } from 'draft-js';
import 'draft-js/dist/Draft.css';
import './RichEditor.css';
import { stateToHTML } from 'draft-js-export-html';

const RichTextEditor = ({ value, onChange,disabled }) => {
  const [editorState, setEditorState] = useState(() => {
    if (value) {
      const blocksFromHTML = convertFromHTML(value);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      return EditorState.createWithContent(contentState);
    } else {
      return EditorState.createEmpty();
    }
  });

  
  const editorRef = useRef(null);

  useEffect(() => {
    if (editorRef.current && !editorState.getCurrentContent().hasText()) {
      editorRef.current.focus();
    }
  }, []);

  const handleChange = (newEditorState) => {
    setEditorState(newEditorState);
    if (onChange) {
      const contentState = newEditorState.getCurrentContent();
      const html = stateToHTML(contentState);
      onChange(html);
    }
  };


  const focusEditor = () => {
    if (editorRef.current) {
      editorRef.current.focus();
    }
  };

  const handleKeyCommand = (command) => {
    const newEditorState = RichUtils.handleKeyCommand(editorState, command);
    if (newEditorState) {
      handleChange(newEditorState);
      return 'handled';
    }
    return 'not-handled';
  };

  const mapKeyToEditorCommand = (e) => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(
        e,
        editorState,
        4 /* maxDepth */
      );
      if (newEditorState !== editorState) {
        handleChange(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  };

  const toggleBlockType = (blockType) => {
    handleChange(
      RichUtils.toggleBlockType(
        editorState,
        blockType
      )
    );
  };

  const toggleInlineStyle = (inlineStyle) => {
    handleChange(
      RichUtils.toggleInlineStyle(
        editorState,
        inlineStyle
      )
    );
  };

  const blockStyleFn = (block) => {
    switch (block.getType()) {
      case 'blockquote':
        return 'RichEditor-blockquote';
      default:
        return null;
    }
  };

  return (
    <div className="RichEditor-root">
      <BlockStyleControls
        editorState={editorState}
        onToggle={toggleBlockType}
      />
      <InlineStyleControls
        editorState={editorState}
        onToggle={toggleInlineStyle}
      />
      <div className="RichEditor-editor" onClick={focusEditor}>
        <Editor
          blockStyleFn={blockStyleFn}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={mapKeyToEditorCommand}
          onChange={handleChange}
          ref={editorRef}
          spellCheck={true}
          readOnly={disabled}
        />
      </div>
    </div>
  );
};

const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Underline', style: 'UNDERLINE' },
  { label: 'Monospace', style: 'CODE' },
];

const InlineStyleControls = (props) => {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

const StyleButton = (props) => {
  const onToggle = (e) => {
    e.preventDefault();
    props.onToggle(props.style);
  };

  let className = 'RichEditor-styleButton';
  if (props.active) {
    className += ' RichEditor-activeButton';
  }

  return (
    <span className={className} onMouseDown={onToggle}>
      {props.label}
    </span>
  );
};

const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'H5', style: 'header-five' },
  { label: 'H6', style: 'header-six' },
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
];

export default RichTextEditor;