import axios from "axios";
import {jwtDecode} from "jwt-decode"

// export const BASE_URL = "https://techugoserver.info/tejapi/api";
export const BASE_URL = "https://tej.social/live/api";
// export const BASE_URL = "http://localhost:8080/api";
const token = localStorage.getItem("token")


const decodeToken = (token) => {
  try {
    let decodedToken
    if (token){
     decodedToken = jwtDecode(token);
    }
    return decodedToken;
  } catch (error) {
    console.error('Invalid token', error);
    return null;
  }
};

export const decodedToken = decodeToken(token);


export const publicRequest = axios.create({
  baseURL: BASE_URL,
});
export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: token,
  },
});

export const fileRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
    "Authorization": `Basic ${btoa("TEZPANDIT:TSF121YXPO")}`,
  },
});

