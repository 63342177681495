import React, { useEffect, useState } from "react";
import styles from "./samhitaManagement.module.css";
import { useNavigate } from "react-router-dom";
import { BASE_URL, userRequest } from "../../requestMethod";
import { Popconfirm, Spin, Switch } from "antd";
import SamhitaFilter from "./SamhitaFilter";
import useDebounce from "../../components/UseDebounce";

function SamhitaManagement() {
  const [samhitaData, setSamhitaData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [exportUrl, setExportUrl] = useState("");
  const navigate = useNavigate();
  const [selectedSamhita, setSelectedSamhita] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [debouncedSearchQuery, page, filterData]);

  useEffect(() => {
    updateExportUrl();
  }, [searchQuery, filterData]);

  const fetchData = async () => {
    try {
      const filterQuery = filterData
        ? `&category=${filterData.category}&subCategory=${filterData.subCategory}&languages=${filterData.languages}`
        : "";
      const response = await userRequest.get(
        `/admin/samhita/getAdminSamhita?search=${debouncedSearchQuery}${filterQuery}&page=${page}`
      );
      setSamhitaData(response.data.data);
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.currentPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const updateExportUrl = () => {
    const filterQuery = filterData
      ? `&category=${filterData.category}&subCategory=${filterData.subCategory}&languages=${filterData.languages}`
      : "";
    const url = `${BASE_URL}/admin/samhita/exportSamhitaDataToExcel?search=${searchQuery}${filterQuery}`;
    setExportUrl(url);
  };

  const handleViewClick = (samhita) => {
    console.log(samhita);
    setSelectedSamhita(samhita);
    navigate(`/samhitamanagement/viewsamhita`, { state: { samhita } });
  };

  const handleChange = async (id, activeStatus) => {
    try {
      const newActiveStatus = !activeStatus;
      await userRequest.patch(`/admin/samhita/updateSamhitaStatus`, {
        samhitaId: id,
        activeStatus: newActiveStatus,
      });
      fetchData();
    } catch (error) {
      console.error("Error updating user status:", error);
    }
  };

  const handleFilterClick = () => {
    setIsModalOpen(true);
  };

  const handleModalOk = (data) => {
    setFilterData(data);
    setIsModalOpen(false);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={styles.main}>
      <div className={styles.pageHeader}>
        <p className={styles.samhitaHeader}>
          <span onClick={() => navigate("/samhitamanagement")}>Samhita Management </span>{" "}
        </p>
        <button
          className={styles.addNewBtn}
          onClick={() => navigate("/samhitamanagement/createsamhita")}
        >
          Add New
        </button>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.activePage}>
          <div className={styles.filterExport}>
            <div className={styles.search}>
              <input
                type="text"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className={styles.export}>
              <p>
                <a href={exportUrl} target="_parent">
                  Export as CSV, PDF, Excel doc
                </a>
              </p>
            </div>
            <div className={styles.filterDiv}>
              <p onClick={handleFilterClick}>Filter</p>
            </div>
          </div>
          <Spin spinning={loading} size="small">
            <div className={styles.samhitaTable}>
              <table>
                <thead>
                  <tr>
                    <th>S.no.</th>
                    <th>Samhita Name</th>
                    <th>Type</th>
                    <th>Category</th>
                    <th>Subcategory</th>
                    <th>Language</th>
                    <th>Author</th>
                    <th>PDF</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {samhitaData.length > 0 ? (
                    samhitaData.map((samhita, index) => (
                      <tr key={samhita._id}>
                        <td>{index + 1}</td>
                        <td>{samhita.samhitaName}</td>
                        <td>{samhita.samhitaType}</td>
                        <td>{samhita.category}</td>
                        <td>{samhita.subCategory.join(", ")}</td>
                        <td>{samhita.languages}</td>
                        <td>{samhita.author}</td>
                        <td
                          className={styles.viewDetails}
                          onClick={() => handleViewClick(samhita)}
                        >
                          View
                        </td>
                        <td>
                          <Popconfirm
                                  // title={`Are you sure to ${samhita.activeStatus ? 'deactivate' : 'activate'} ${samhita.samhitaName}?`}
                                  title={`Are you sure to ${samhita.activeStatus ? 'deactivate' : 'activate'} ${samhita.samhitaName}?`}
                            onConfirm={() =>
                              handleChange(samhita._id, samhita.activeStatus)
                            }
                            okText="Yes"
                            cancelText="No"
                          >
                            <Switch
                              size="small"
                              checked={samhita.activeStatus}
                            />
                          </Popconfirm>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="9">No data found</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {samhitaData.length > 0 && (
                <div className={styles.footer}>
                  <div
                    className={styles.action}
                    style={{
                      visibility: totalPages === 1 ? "hidden" : "visible",
                    }}
                  >
                    <button
                      className={`${styles.actionButton} ${
                        page === 1 ? styles.disabled : styles.activeButton
                      }`}
                      onClick={handlePrevious}
                      disabled={page === 1}
                    >
                      Previous
                    </button>
                    <button
                      className={`${styles.actionButton} ${
                        page === totalPages
                          ? styles.disabled
                          : styles.activeButton
                      }`}
                      onClick={handleNext}
                      disabled={page === totalPages}
                    >
                      Next
                    </button>
                  </div>
                  <div className={styles.page}>
                    <span>{currentPage}</span> <span>of</span>{" "}
                    <span>{totalPages}</span>
                  </div>
                </div>
              )}
            </div>
          </Spin>
        </div>
      </div>
      <SamhitaFilter
        isModalOpen={isModalOpen}
        handleOk={handleModalOk}
        handleCancel={handleModalCancel}
      />
    </div>
  );
}

export default SamhitaManagement;

function formatDate(dateString) {
  // Convert dateString to Date object
  const date = new Date(dateString);

  // Format the date using toLocaleDateString method
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  return formattedDate;
}
