import React, { useState } from "react";
import { Modal } from "antd";
import styles from "./CampaignFilter.module.css";
import { toast } from "sonner";
import SelectComponent from "../../../components/SelectComponent";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

const CampaignFilter = ({ isModalOpen, handleOk, handleCancel }) => {
  const [formData, setFormData] = useState({
    country: "",
    state: "",
    NoOfLevels: "",
  });

  const handleFilter = () => {
    ;
    handleOk(formData); // Pass formData back to parent component
  };

  const selectCountry = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      country: val,
      state: "", // Reset state when country changes
    }));
  };

  const selectState = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      state: val,
    }));
  };

  const levels = Array.from({ length: 20 }, (_, index) => ({
    value: index + 1,
    label: `${index + 1}`,
  }));

  const handleLevelSelect = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      NoOfLevels: selectedOption.value,
    }));
  };

  return (
    <Modal
      visible={isModalOpen}
      onCancel={handleCancel}
      onOk={handleFilter}
      width={"29vw"}
      height={"100dvh"}
      className={styles.modal}
    >
      <div className={styles.modalTitle}>
        <p>Filter</p>
      </div>
      <div className={styles.contentMain}>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>Country</p>
          </div>
          <div className={styles.fieldInput}>
            <CountryDropdown
              value={formData.country}
              onChange={(val) => selectCountry(val)}
            />
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>State</p>
          </div>
          <div className={styles.fieldInput}>
            <RegionDropdown
              country={formData.country}
              value={formData.state}
              onChange={(val) => selectState(val)}
            />
          </div>
        </div>

        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>No of Levels</p>
          </div>
          <div className={styles.fieldInput}>
            <SelectComponent
              options={levels}
              onSelect={handleLevelSelect}
              initialSelectedOption={formData.NoOfLevels}
            />
          </div>
        </div>
        <div className={styles.modalButton}>
          <button onClick={handleCancel}>Cancel</button>
          <button onClick={handleFilter}>Filter</button>
        </div>
      </div>
    </Modal>
  );
};

export default CampaignFilter;
