// import React from "react";
// import styles from "./taskCenter.module.css";
// import { useNavigate } from "react-router-dom";
// import { useState, useEffect } from "react";
// import axios from "axios";
// import RichTextEditor from "../../components/RichTextEditor/RichTextEditor";
// import { userRequest } from "../../requestMethod";
// import { toast } from "sonner";
// import { Spin } from "antd";

// function HowTaskCenterWorks() {
//   const [taskCenterData, setTaskCenterData] = useState("");
//   const navigate = useNavigate();
//   const [isLoading, setIsLoading] = useState(false)

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchData = async () => {
//     try {
//       setIsLoading(true);
//       const response = await userRequest.get(`/campaign/getHowTaskCenterWorks`);
//       setTaskCenterData(response.data.data[0]?.howTaskCenterWorks);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const saveData = async () => {
//     try {
//       await userRequest.post("/campaign/createHowTaskCenterWorks", {"howTaskCenterWorks":taskCenterData});
//       toast.success("Saved successfully!");
//     } catch (error) {
//       console.error("Error saving data:", error.message);
//       toast.error("Failed to save data. Please try again later.");
//     }
//   };
//   const handleChange = (value) => {
//     setTaskCenterData(value);

//   };

//   return (
//     <Spin spinning={isLoading} size="small">
//     <div className={styles.main}>
//       <div className={styles.pageHeader}>
//         <p onClick={() => navigate("/howtaskcenterworks")}>
//           How Task Center Works
//         </p>
//         <button className={styles.taskCenterButton} onClick={saveData}>Save</button>
//       </div>

//       <div className={styles.contentContainerTask}>
//         <RichTextEditor
//           value={taskCenterData}
//           // onChange={(value) => setTaskCenterData(value)}
//           onChange={handleChange}
//         />
//       </div>
   
//     </div>
//     </Spin>
//   );
// }

// export default HowTaskCenterWorks;



import React from "react";
import styles from "./taskCenter.module.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { userRequest } from "../../requestMethod";
import { toast } from "sonner";
import { Spin } from "antd";
import RichTextEditor from "../../components/RichTextEditor/RichTextEditor";

function HowTaskCenterWorks() {
  const [taskCenterData, setTaskCenterData] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await userRequest.get(`/admin/svarna/getHowTaskCenterWorksAdmin`);
      setTaskCenterData(response.data.data[0]?.howTaskCenterWorks || "");
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const saveData = async () => {
    try {
      await userRequest.post("/admin/svarna/createHowTaskCenterWorks", { "howTaskCenterWorks": taskCenterData });
      toast.success("Saved successfully!");
    } catch (error) {
      console.error("Error saving data:", error.message);
      toast.error("Failed to save data. Please try again later.");
    }
  };

  const handleChange = (value) => {
    setTaskCenterData(value);
  };

  return (
    <Spin spinning={isLoading} size="small">
      <div className={styles.main}>
        <div className={styles.pageHeader}>
          <p onClick={() => navigate("/howtaskcenterworks")}>
            How Task Center Works
          </p>
          <button className={styles.taskCenterButton} onClick={saveData}>Save</button>
        </div>

        <div className={styles.contentContainerTask}>
          {taskCenterData !== null && (
            <RichTextEditor
              value={taskCenterData}
              onChange={handleChange}
            />
          )}
        </div>
      </div>
    </Spin>
  );
}

export default HowTaskCenterWorks;
