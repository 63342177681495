import React, { useState } from "react";
import styles from "./SvarnaMgmt.module.css";
import { useNavigate } from "react-router-dom";
import Campaign from "./Campaign/Campaign";
import TejPoints from "./TejPoints/TejPoints";

function SvarnaManagement() {
  const menuItems = [
    { label: "Campaign", state: "Campaign" },
    { label: "Tej Points", state: "Tej Points" },
  ];

  const navigate = useNavigate();
  const [activeMenuItem, setActiveMenuItem] = useState("Campaign");

  const handlePoliciesMenu = (menu) => {
    setActiveMenuItem(menu);
  };
  return (
    <div className={styles.main}>
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderLeft}>
          <p className={styles.campaignHeader}>
            <span onClick={() => navigate("/svarnamanagement")}>
              Svarna Management
            </span>
          </p>
        </div>
        <div className={styles.pageHeaderRight}>
          {activeMenuItem === "Campaign" ? (
            <button className={styles.addNewBtn}
              onClick={() => navigate("/svarnamanagement/createcampaign")}
            >
              Create Campaign
            </button>
          ) :             <button className={styles.addNewBtn}
          onClick={() => navigate("/svarnamanagement/createTejPoints")}
        >
          Create Tej Points
        </button>}
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.menuContainer}>
          {menuItems.map((item) => (
            <div
              key={item.state}
              className={
                activeMenuItem === item.state
                  ? `${styles.singleMenu} ${styles.activeMenu}`
                  : styles.singleMenu
              }
              onClick={() => handlePoliciesMenu(item.state)}
            >
              <p>{item.label}</p>
            </div>
          ))}
        </div>
        <div className={styles.activePage}>
          {activeMenuItem === "Campaign" && <Campaign />}
          {activeMenuItem === "Tej Points" && <TejPoints />}
        </div>
      </div>
    </div>
  );
}

export default SvarnaManagement;
