import React, { useEffect, useState } from "react";
import styles from "./user.module.css";
import { useNavigate } from "react-router-dom";
import { BASE_URL, userRequest } from "../../../requestMethod";
import { Popconfirm, Spin, Switch } from "antd";
import Filters from "./Filters";
import useDebounce from "../../../components/UseDebounce";
import SelectDeactivateReason from "../Reason/SelectDeactivateReason";

function User() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [loading, setLoading] = useState(true)
  const [selectedReason, setSelectedReason] = useState(""); 
  const [id, setId] = useState(""); 
  const [userType, setUserType] = useState(""); 
const [reasonModalVisible, setReasonModalVisible] = useState(false); 

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [debouncedSearchQuery, page, filterData]);

  const fetchData = async () => {
    try {
      const filterQuery = filterData
        ? `&userType=${filterData?.userType}&city=${filterData.city}`
        : "";
      let response;

      if (filterQuery) {
        const response = await userRequest.get(
          `/userManagement/getUserManagementList?search=${debouncedSearchQuery}${filterQuery}&page=${page}`
        );
      } else {
        response = await userRequest.get(
          `/userManagement/getUserManagementList?userType=ALL&search=${debouncedSearchQuery}${filterQuery}&page=${page}`
        );
      }

      setUserData(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
      setCurrentPage(response.data.pagination.page);
    } catch (error) {
      console.error("Error fetching data:", error);
    }finally{
      setLoading(false)
    }
  };

  // const handleChange = async (id, activeStatus, userType) => {
  //   try {
  //     const newActiveStatus = !activeStatus;
  //     await userRequest.patch(`/userManagement/updatePanditOrDevoteeStatus`, {
  //       userId: id,
  //       userType: userType,
  //       activeStatus: newActiveStatus,
  //     });
  //     fetchData();
  //   } catch (error) {
  //     console.error("Error updating user status:", error);
  //   }
  // };

  const handleChange = async (id, activeStatus, userType) => {
    try {
      setId(id)
      setUserType(userType)
      const newActiveStatus = !activeStatus;
      if (!newActiveStatus) {
        // If deactivating, show reason modal
        setSelectedReason("");
        setReasonModalVisible(true);
      } else {
        await userRequest.patch(`/userManagement/updatePanditOrDevoteeStatus`, {
          userId: id,
          userType: userType,
          activeStatus: newActiveStatus,
          deactivationReason:""
        });
        fetchData();
      }
    } catch (error) {
      console.error("Error updating user status:", error);
    }
  };

  
  
  const handleFilterClick = () => {
    setIsModalOpen(true);
  };

  const handleModalOk = (data) => {
    setFilterData(data);
    setIsModalOpen(false);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Spin spinning={loading} size="small">
    <div className={styles.tableMain}>
      <div className={styles.filterExport}>
        <p>All</p>
        <div className={styles.search}>
          <input
            type="text"
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search..."
          />
        </div>
        <div className={styles.export}>
          <p>
            <a
              href={`${BASE_URL}/userManagement/exportUserManagementToExcel?userType=Pandit&search=${searchQuery}&page=${page}`}
              target="_parent"
            >
              Export as CSV, PDF, Excel doc
            </a>
          </p>
        </div>
        <div className={styles.filterDiv}>
          <p onClick={handleFilterClick}>Filter</p>
        </div>
      </div>
      <div className={styles.userTableMain}>
        <table>
          <thead>
            <tr>
              <th>S. No.</th>
              <th>User Name</th>
              <th>User Type</th>
              <th>Email Id</th>
              <th>Nationality</th>
              <th>City</th>
              <th>Profile Completed</th>
              <th>Action</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {userData.length > 0 ? (
              userData.map((user, index) => (
                <tr key={user._id}>
                  <td>{(page - 1) * 10 + index + 1}</td>
                  <td>
                    {user.firstName} {user.lastName}
                  </td>
                  <td>{user.role}</td>
                  <td>{user.email}</td>
                  <td>{user.nationality}</td>
                  <td>{user?.placesLived?.currentCity}</td>
                  <td>{user?.profileCompletionPercentage}%</td>
                  <td
                    className={styles.viewDetails}
                    onClick={() =>
                      navigate(
                        `/usermanagement/details/${user.role}/${user._id}`
                      )
                    }
                  >
                    Details
                  </td>
                  <td className={styles.actionTd}>
                    <Popconfirm
                    placement="left"
                     title={`${user.activeStatus ? 'Deactivate' : 'Activate'} ${user.firstName} ${user.lastName} from tej`}
                      description={`Are you sure to ${user.activeStatus ? 'deactivate' : 'activate'} ${user.firstName} ${user.lastName} from Tej`}
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() =>
                        handleChange(user._id, user.activeStatus, user.role)
                      }
                    >
                      <Switch
                        size="small"
                        checked={user.activeStatus}
                      />
                    </Popconfirm>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8">No data found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {userData.length > 0 && (
        <div className={styles.footer}>
          <div
            className={styles.action}
            style={{ visibility: totalPages === 1 ? "hidden" : "visible" }}
          >
            <button
              className={`${styles.actionButton} ${
                page === 1 ? styles.disabled : styles.activeButton
              }`}
              onClick={handlePrevious}
              disabled={page === 1}
            >
              Previous
            </button>
            <button
              className={`${styles.actionButton} ${
                page === totalPages ? styles.disabled : styles.activeButton
              }`}
              onClick={handleNext}
              disabled={page === totalPages}
            >
              Next
            </button>
          </div>
          <div className={styles.page}>
            <span>{currentPage}</span> <span>of</span> <span>{totalPages}</span>
          </div>
        </div>
      )}
      <Filters
        isModalOpen={isModalOpen}
        handleOk={handleModalOk}
        handleCancel={handleModalCancel}
      />

<SelectDeactivateReason
  selectedReason={selectedReason}
  setSelectedReason={setSelectedReason}
  isModalOpen={reasonModalVisible}
  setIsModalOpen={setReasonModalVisible}
  fetchData={fetchData}
  id={id}
  userType={userType}
/>
    </div>
    </Spin>
  );
}

export default User;
